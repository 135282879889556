/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Feature_FeatureAssignmentRead } from './Feature_FeatureAssignmentRead';
/**
 * Get feature assignments
 */
export type Feature_FeatureAssignmentOutput_FeatureAssignmentRead = {
    /**
     * The ID of the feature assignment
     */
    id?: string;
    /**
     * The object to which the feature is assigned
     */
    object?: Feature_FeatureAssignmentOutput_FeatureAssignmentRead.object;
    /**
     * The ID of the object to which the feature is assigned
     */
    objectId?: string;
    /**
     * The feature that is assigned
     */
    feature?: Feature_FeatureAssignmentRead;
    /**
     * The full readable name of the feature assignment
     */
    name?: string;
    /**
     * The value of the feature assignment
     */
    value?: string | null;
    /**
     * The date from which the feature assignment is valid. In the given time range it will be applied to **new** subscriptions.
     */
    validFrom?: string | null;
    /**
     * The date until which the feature assignment is valid. In the given time range it will be applied to **new** subscriptions.
     */
    validUntil?: string | null;
};
export namespace Feature_FeatureAssignmentOutput_FeatureAssignmentRead {
    /**
     * The object to which the feature is assigned
     */
    export enum object {
        PRODUCT_PRICE = 'product-price',
        PRODUCT = 'product',
    }
}

