/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type Product_CheckoutLinkItemDetail = {
    /**
     * The unique identifier of the product.
     */
    readonly id?: string;
    /**
     * The name of the product based on the current tenant language. This will be displayed on customer communication.
     */
    name?: string;
    /**
     * The description of the product based on the current tenant language.
     */
    description?: string | null;
    /**
     * The type of the product.
     * - `product`: recurring billed product
     * - `charge`: one-time billing
     * - `plan`: plan specific product, which cannot be used as a normal product. Will be filtered out in any product lists.
     *
     */
    type: Product_CheckoutLinkItemDetail.type;
    /**
     * Defines when the product should be displayed in the invoice.
     * - `always`: The product is always displayed in the invoice.
     * - `only_if_charged`: The product is only displayed in the invoice if it is charged.
     *
     */
    invoiceVisibility?: Product_CheckoutLinkItemDetail.invoiceVisibility;
    /**
     * Custom fields for the entity. The keys are the field names and the values are the field values. They need to be configured under "/custom-fields" in the API documentation.
     */
    customFields?: Array<string>;
};
export namespace Product_CheckoutLinkItemDetail {
    /**
     * The type of the product.
     * - `product`: recurring billed product
     * - `charge`: one-time billing
     * - `plan`: plan specific product, which cannot be used as a normal product. Will be filtered out in any product lists.
     *
     */
    export enum type {
        PRODUCT = 'product',
        CHARGE = 'charge',
        PLAN = 'plan',
    }
    /**
     * Defines when the product should be displayed in the invoice.
     * - `always`: The product is always displayed in the invoice.
     * - `only_if_charged`: The product is only displayed in the invoice if it is charged.
     *
     */
    export enum invoiceVisibility {
        ALWAYS = 'always',
        ONLY_IF_CHARGED = 'only_if_charged',
    }
}

