/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateGoCardlessResource } from './CreateGoCardlessResource';
import type { CreateSepaMandateResource } from './CreateSepaMandateResource';
import type { CreateStripeResource } from './CreateStripeResource';
export type PaymentMethod_SetupPaymentMethod = {
    readonly isMigration?: boolean;
    /**
     * The payment gateway to use to process the given payment method type
     *
     * Possible values:
     * * `paypal`: PayPal (supports "paypal")
     * * `stripe`: Stripe (supports "card")
     * * `testing`: Testing (supports all)
     * * `go_cardless`: GoCardless (supports "sepa_debit")
     * * `bank_transfer`: Bank Transfer (supports "bank_transfer")
     * * `sepa_debit`: SEPA Credit and Debit with xml file (supports "sepa_debit")
     * * `wallet`: Credit Balance from Wallet (supports "wallet")
     */
    gateway: PaymentMethod_SetupPaymentMethod.gateway | null;
    /**
     * The payment method type to use for this payment method
     *
     * Possible values:
     * * `sepa_debit`: SEPA Debit
     * * `card`: Credit Card
     * * `paypal`: PayPal
     * * `bank_transfer`: Bank Transfer
     * * `wallet`: Wallet
     */
    type: PaymentMethod_SetupPaymentMethod.type | null;
    /**
     * The customer that owns this payment method. If not provided, the customer will be determined by the authenticated user.
     */
    customerId: string | null;
    /**
     * Determines if this payment method should be used as default payment method for the customer
     */
    isDefault?: boolean;
    /**
     * Determines if this payment method is allowed to be used for future payments. Otherwise the payment method is only available for this payment.
     */
    futureUsageAllowed?: boolean;
    /**
     * Required when gateway is "stripe". Holds all necessary data for the payment gateway to create the payment method.
     */
    stripe?: CreateStripeResource | null;
    /**
     * Required when gateway is "sepa_debit". Holds all necessary data for the payment gateway to create the payment method.
     */
    sepaMandate?: CreateSepaMandateResource | null;
    /**
     * Optional for gateway "go_cardless". It is only required when you want to migrate, or assign an existing GoCardless customer or mandate to fynn.
     */
    goCardless?: CreateGoCardlessResource | null;
    /**
     * The redirect url to redirect the customer to after the payment method was created. This is only required, when you create a payment method with your own flow, independent from provided Hosted-Checkout, Hosted-Customerfront or Fynn.JS. Otherwise this has no effect.
     */
    redirectUrl?: string | null;
    readonly migration?: boolean;
};
export namespace PaymentMethod_SetupPaymentMethod {
    /**
     * The payment gateway to use to process the given payment method type
     *
     * Possible values:
     * * `paypal`: PayPal (supports "paypal")
     * * `stripe`: Stripe (supports "card")
     * * `testing`: Testing (supports all)
     * * `go_cardless`: GoCardless (supports "sepa_debit")
     * * `bank_transfer`: Bank Transfer (supports "bank_transfer")
     * * `sepa_debit`: SEPA Credit and Debit with xml file (supports "sepa_debit")
     * * `wallet`: Credit Balance from Wallet (supports "wallet")
     */
    export enum gateway {
        WALLET = 'wallet',
        SEPA_DEBIT = 'sepa_debit',
        STRIPE = 'stripe',
        FAKE_PROVIDER = 'fake_provider',
        PAYPAL = 'paypal',
        BANK_TRANSFER = 'bank_transfer',
        GO_CARDLESS = 'go_cardless',
        TESTING = 'testing',
    }
    /**
     * The payment method type to use for this payment method
     *
     * Possible values:
     * * `sepa_debit`: SEPA Debit
     * * `card`: Credit Card
     * * `paypal`: PayPal
     * * `bank_transfer`: Bank Transfer
     * * `wallet`: Wallet
     */
    export enum type {
        SEPA_DEBIT = 'sepa_debit',
        CARD = 'card',
        PAYPAL = 'paypal',
        BANK_TRANSFER = 'bank_transfer',
        WALLET = 'wallet',
        FAKE_PROVIDER = 'fake_provider',
    }
}

