/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type DatevFieldMapping_DatevFieldMappingRead = {
    readonly id?: string;
    /**
     *
     *
     * Possible values:
     * * `accounting`: The field mapping is used for the DATEV Buchungsstapel file.
     * * `debitor`: The field mapping is used for the DATEV Kred_Stamm file.
     */
    type?: DatevFieldMapping_DatevFieldMappingRead.type;
    /**
     * The destination field in the DATEV export file.
     *
     * Possible values:
     * * `beleginfo_1`: Beleginfo 1
     * * `beleginfo_2`: Beleginfo 2
     * * `beleginfo_3`: Beleginfo 3
     * * `beleginfo_4`: Beleginfo 4
     * * `beleginfo_5`: Beleginfo 5
     * * `beleginfo_6`: Beleginfo 6
     * * `beleginfo_7`: Beleginfo 7
     * * `beleginfo_8`: Beleginfo 8
     * * `zusatzinformation_1`: Zusatzinformation 1
     * * `zusatzinformation_2`: Zusatzinformation 2
     * * `zusatzinformation_3`: Zusatzinformation 3
     * * `zusatzinformation_4`: Zusatzinformation 4
     * * `zusatzinformation_5`: Zusatzinformation 5
     * * `zusatzinformation_6`: Zusatzinformation 6
     * * `zusatzinformation_7`: Zusatzinformation 7
     * * `zusatzinformation_8`: Zusatzinformation 8
     * * `zusatzinformation_9`: Zusatzinformation 9
     * * `zusatzinformation_10`: Zusatzinformation 10
     * * `zusatzinformation_11`: Zusatzinformation 11
     * * `zusatzinformation_12`: Zusatzinformation 12
     * * `zusatzinformation_13`: Zusatzinformation 13
     * * `zusatzinformation_14`: Zusatzinformation 14
     * * `zusatzinformation_15`: Zusatzinformation 15
     * * `zusatzinformation_16`: Zusatzinformation 16
     * * `zusatzinformation_17`: Zusatzinformation 17
     * * `zusatzinformation_18`: Zusatzinformation 18
     * * `zusatzinformation_19`: Zusatzinformation 19
     * * `zusatzinformation_20`: Zusatzinformation 20
     * * `kost_1`: Kost 1
     * * `kost_2`: Kost 2
     */
    targetField?: DatevFieldMapping_DatevFieldMappingRead.targetField;
    /**
     * The source in Fynn for the target.
     */
    sourceField?: string;
    /**
     * Internal label for the source field.
     */
    sourceFieldLabel?: string | null;
    readonly createdAt?: string;
};
export namespace DatevFieldMapping_DatevFieldMappingRead {
    /**
     *
     *
     * Possible values:
     * * `accounting`: The field mapping is used for the DATEV Buchungsstapel file.
     * * `debitor`: The field mapping is used for the DATEV Kred_Stamm file.
     */
    export enum type {
        ACCOUNTING = 'accounting',
        DEBITOR = 'debitor',
    }
    /**
     * The destination field in the DATEV export file.
     *
     * Possible values:
     * * `beleginfo_1`: Beleginfo 1
     * * `beleginfo_2`: Beleginfo 2
     * * `beleginfo_3`: Beleginfo 3
     * * `beleginfo_4`: Beleginfo 4
     * * `beleginfo_5`: Beleginfo 5
     * * `beleginfo_6`: Beleginfo 6
     * * `beleginfo_7`: Beleginfo 7
     * * `beleginfo_8`: Beleginfo 8
     * * `zusatzinformation_1`: Zusatzinformation 1
     * * `zusatzinformation_2`: Zusatzinformation 2
     * * `zusatzinformation_3`: Zusatzinformation 3
     * * `zusatzinformation_4`: Zusatzinformation 4
     * * `zusatzinformation_5`: Zusatzinformation 5
     * * `zusatzinformation_6`: Zusatzinformation 6
     * * `zusatzinformation_7`: Zusatzinformation 7
     * * `zusatzinformation_8`: Zusatzinformation 8
     * * `zusatzinformation_9`: Zusatzinformation 9
     * * `zusatzinformation_10`: Zusatzinformation 10
     * * `zusatzinformation_11`: Zusatzinformation 11
     * * `zusatzinformation_12`: Zusatzinformation 12
     * * `zusatzinformation_13`: Zusatzinformation 13
     * * `zusatzinformation_14`: Zusatzinformation 14
     * * `zusatzinformation_15`: Zusatzinformation 15
     * * `zusatzinformation_16`: Zusatzinformation 16
     * * `zusatzinformation_17`: Zusatzinformation 17
     * * `zusatzinformation_18`: Zusatzinformation 18
     * * `zusatzinformation_19`: Zusatzinformation 19
     * * `zusatzinformation_20`: Zusatzinformation 20
     * * `kost_1`: Kost 1
     * * `kost_2`: Kost 2
     */
    export enum targetField {
        BELEGINFO_1 = 'beleginfo_1',
        BELEGINFO_2 = 'beleginfo_2',
        BELEGINFO_3 = 'beleginfo_3',
        BELEGINFO_4 = 'beleginfo_4',
        BELEGINFO_5 = 'beleginfo_5',
        BELEGINFO_6 = 'beleginfo_6',
        BELEGINFO_7 = 'beleginfo_7',
        BELEGINFO_8 = 'beleginfo_8',
        ZUSATZINFORMATION_1 = 'zusatzinformation_1',
        ZUSATZINFORMATION_2 = 'zusatzinformation_2',
        ZUSATZINFORMATION_3 = 'zusatzinformation_3',
        ZUSATZINFORMATION_4 = 'zusatzinformation_4',
        ZUSATZINFORMATION_5 = 'zusatzinformation_5',
        ZUSATZINFORMATION_6 = 'zusatzinformation_6',
        ZUSATZINFORMATION_7 = 'zusatzinformation_7',
        ZUSATZINFORMATION_8 = 'zusatzinformation_8',
        ZUSATZINFORMATION_9 = 'zusatzinformation_9',
        ZUSATZINFORMATION_10 = 'zusatzinformation_10',
        ZUSATZINFORMATION_11 = 'zusatzinformation_11',
        ZUSATZINFORMATION_12 = 'zusatzinformation_12',
        ZUSATZINFORMATION_13 = 'zusatzinformation_13',
        ZUSATZINFORMATION_14 = 'zusatzinformation_14',
        ZUSATZINFORMATION_15 = 'zusatzinformation_15',
        ZUSATZINFORMATION_16 = 'zusatzinformation_16',
        ZUSATZINFORMATION_17 = 'zusatzinformation_17',
        ZUSATZINFORMATION_18 = 'zusatzinformation_18',
        ZUSATZINFORMATION_19 = 'zusatzinformation_19',
        ZUSATZINFORMATION_20 = 'zusatzinformation_20',
        KOST_1 = 'kost_1',
        KOST_2 = 'kost_2',
        INDIV_FELD_1 = 'indivFeld_1',
        INDIV_FELD_2 = 'indivFeld_2',
        INDIV_FELD_3 = 'indivFeld_3',
        INDIV_FELD_4 = 'indivFeld_4',
        INDIV_FELD_5 = 'indivFeld_5',
        INDIV_FELD_6 = 'indivFeld_6',
        INDIV_FELD_7 = 'indivFeld_7',
        INDIV_FELD_8 = 'indivFeld_8',
        INDIV_FELD_9 = 'indivFeld_9',
        INDIV_FELD_10 = 'indivFeld_10',
    }
}

