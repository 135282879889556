/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Plan_PlanResource_CheckoutLinkItemDetail } from './Plan_PlanResource_CheckoutLinkItemDetail';
import type { PricePlan_CheckoutLinkItemDetail } from './PricePlan_CheckoutLinkItemDetail';
import type { Product_CheckoutLinkItemDetail } from './Product_CheckoutLinkItemDetail';
import type { Quantity_CheckoutLinkItemDetail } from './Quantity_CheckoutLinkItemDetail';
import type { TermPeriod_CheckoutLinkItemDetail } from './TermPeriod_CheckoutLinkItemDetail';
export type CheckoutLinkItem_CheckoutLinkItemDetail = {
    /**
     * The type of the checkout link item.
     *
     * Possible values:
     * * `product`: A product will be added to the cart, with the given quantity.
     * * `plan`: A plan will be added to the cart, and can be configured by the customer.
     */
    readonly type?: CheckoutLinkItem_CheckoutLinkItemDetail.type;
    /**
     * This is required when the type is "product".
     */
    readonly product?: Product_CheckoutLinkItemDetail | null;
    /**
     * This is required when the type is "product".
     */
    readonly quantity?: Quantity_CheckoutLinkItemDetail | null;
    /**
     * This is required when the type is "plan".
     */
    readonly plan?: Plan_PlanResource_CheckoutLinkItemDetail | null;
    /**
     * This is required when the type is "product", "plan".
     */
    readonly price?: PricePlan_CheckoutLinkItemDetail | null;
    /**
     * The contract period terms of the product item. If none provided, the billing period will be used as contract period, the cancellation period is set to 1 day.
     */
    readonly periods?: Array<TermPeriod_CheckoutLinkItemDetail>;
};
export namespace CheckoutLinkItem_CheckoutLinkItemDetail {
    /**
     * The type of the checkout link item.
     *
     * Possible values:
     * * `product`: A product will be added to the cart, with the given quantity.
     * * `plan`: A plan will be added to the cart, and can be configured by the customer.
     */
    export enum type {
        PRODUCT = 'product',
        PLAN = 'plan',
        OFFER = 'offer',
    }
}

