/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CustomField_CustomFieldRead = {
    /**
     * The identifier of the custom field.
     */
    readonly id?: string;
    /**
     * The name of the custom field.
     */
    name: string;
    /**
     * The unique slug of the custom field.
     */
    slug: string;
    /**
     * The type of the custom field.
     *
     * Possible values:
     * * `text`: A text field.
     * * `number`: A number field.
     * * `datetime`: A date and time field.
     * * `boolean`: A boolean field.
     * * `select`: A select field.
     * * `multiSelect`: A multi select field.
     * * `list`: A list field, allows multiple strings.
     */
    type?: CustomField_CustomFieldRead.type;
    /**
     * The entities the custom field is assigned to.
     *
     * Possible values:
     * * `customer`: Customer
     * * `product`: Product
     * * `subscription`: Subscription
     * * `invoice`: Invoice
     */
    entities: CustomField_CustomFieldRead.entities;
    /**
     * The possible choice options of the custom field. This is mandatory on type "select". Can be a key, value pair, or only a value array.
     */
    choices?: Array<string> | null;
    /**
     * Whether the custom field is required or not.
     */
    required?: boolean;
    /**
     * Whether the custom field is shown in the UI or not.
     */
    visible?: boolean;
};
export namespace CustomField_CustomFieldRead {
    /**
     * The type of the custom field.
     *
     * Possible values:
     * * `text`: A text field.
     * * `number`: A number field.
     * * `datetime`: A date and time field.
     * * `boolean`: A boolean field.
     * * `select`: A select field.
     * * `multiSelect`: A multi select field.
     * * `list`: A list field, allows multiple strings.
     */
    export enum type {
        TEXT = 'text',
        NUMBER = 'number',
        DATETIME = 'datetime',
        BOOLEAN = 'boolean',
        SELECT = 'select',
        MULTI_SELECT = 'multiSelect',
        LIST = 'list',
    }
    /**
     * The entities the custom field is assigned to.
     *
     * Possible values:
     * * `customer`: Customer
     * * `product`: Product
     * * `subscription`: Subscription
     * * `invoice`: Invoice
     */
    export enum entities {
        CUSTOMER = 'customer',
        PRODUCT = 'product',
        SUBSCRIPTION = 'subscription',
        INVOICE = 'invoice',
    }
}

