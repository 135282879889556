/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Discount } from './Discount';
export type SubscriptionItemAppliedDiscount = {
    readonly id?: string;
    readonly subscriptionItem?: string;
    readonly coupon?: string | null;
    /**
     * The status of this applied discount.
     *
     * Possible values:
     * * `active`: The discount is active and will be applied to the subscription item.
     * * `completed`: The discount is not active anymore, but it was applied to the subscription item.
     * * `removed`: The discount was removed from the subscription item.
     */
    readonly status?: SubscriptionItemAppliedDiscount.status;
    /**
     * The discount that is applied to the subscription item.
     */
    readonly discount?: Discount;
    /**
     * The number of intervals this discount was applied to the subscription item.
     */
    readonly appliedIntervals?: number | null;
    readonly createdAt?: string;
    readonly updatedAt?: string;
    readonly removed?: boolean;
    readonly completed?: boolean;
    readonly active?: boolean;
    readonly tenantId?: string;
};
export namespace SubscriptionItemAppliedDiscount {
    /**
     * The status of this applied discount.
     *
     * Possible values:
     * * `active`: The discount is active and will be applied to the subscription item.
     * * `completed`: The discount is not active anymore, but it was applied to the subscription item.
     * * `removed`: The discount was removed from the subscription item.
     */
    export enum status {
        ACTIVE = 'active',
        COMPLETED = 'completed',
        REMOVED = 'removed',
    }
}

