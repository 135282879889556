/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccountId_AccountingAccountRead } from './AccountId_AccountingAccountRead';
export type AccountingAccount_AccountingAccountRead = {
    readonly id?: AccountId_AccountingAccountRead;
    /**
     * Account name used for internal labeling.
     */
    name?: string | null;
    /**
     * Account number from your used skr 03 or skr 04 chart of accounts.
     */
    number?: string;
    /**
     * Type of account which references to the chart of accounts.
     */
    readonly type?: AccountingAccount_AccountingAccountRead.type;
};
export namespace AccountingAccount_AccountingAccountRead {
    /**
     * Type of account which references to the chart of accounts.
     */
    export enum type {
        BANK_ACCOUNT = 'bankAccount',
        LEDGER_ACCOUNT = 'ledgerAccount',
        REVENUE_GERMANY = 'revenueGermany',
        REVENUE_GERMANY_REDUCED = 'revenueGermanyReduced',
        SALES_DEDUCTION_ACCOUNT = 'salesDeductionAccount',
        REVENUE_EUROPE = 'revenueEurope',
        REVENUE_TAX_FREE = 'revenueTaxFree',
        BANK_FEES = 'bankFees',
        PASSIVE_DEFERRAL = 'passiveDeferral',
        DEBTOR = 'debtor',
        PAYPAL = 'paypal',
        STRIPE = 'stripe',
        SEPA_DEBIT = 'sepaDebit',
        GO_CARDLESS = 'goCardless',
    }
}

