/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type Transaction_BankAccountTransactionDetail = {
    readonly id?: string;
    readonly type?: Transaction_BankAccountTransactionDetail.type;
    /**
     * The status of the transaction.
     *
     * Possible values:
     * * `new`: The transaction is new and not yet processed. A new transaction is created when a payment is initiated.
     * * `authorized`: The transaction is authorized and can be captured.
     * * `authorizing`: The transaction is authorizing. No actions can be performed on the transaction.
     * * `waiting`: The transaction is waiting for a response from the payment provider, or a customer input. No actions can be performed on the transaction.
     * * `offsite_authorization_required`: The transaction requires an offsite authorization, e.g. 3D Secure. The customer is informed about the required authorization and redirected to the payment provider.
     * * `booked`: The transaction is captured and booked on the account.
     * * `captured`: The transaction is captured and will be booked on the account.
     * * `dispute`: The transaction is disputed.
     * * `manual`: Legacy status. Do not use.
     * * `canceled`: The transaction is canceled, e.g. by the customer during the payment process, or by the merchant.
     * * `pending`: The transaction is pending, and will be processed soon by the payment provider.
     * * `expired`: The transaction is expired, e.g. because the customer did not complete the payment process in time.
     * * `processing`: The transaction is processing, e.g. because the payment provider is currently processing the payment.
     * * `failed`: The transaction failed, e.g. because the payment provider could not process the payment.
     * * `unknown`: The transaction status is unknown.
     * * `refunded`: The transaction is fully refunded.
     * * `payedout`: The transaction was a payout.
     * * `partial_refunded`: The transaction is partially refunded.
     * * `refund_failed`: The transaction refund failed.
     */
    readonly status?: Transaction_BankAccountTransactionDetail.status;
    /**
     * The description of the transaction.
     */
    readonly description?: string;
    /**
     * The internal reference number of the transaction.
     */
    readonly documentNumber?: string;
    /**
     * The currency code of the transaction.
     */
    readonly currencyCode?: string;
    /**
     * The original amount which was requested.
     */
    readonly amount?: {
        /**
         * The amount as big integer, e.g. 1000 = 10.00 (precision = 2), 10000 = 10.000 (precision = 3)
         */
        readonly amount?: number;
        /**
         * The precision of the amount, which is the number of digits after the decimal separator, e.g. 2 = 10.00, 3 = 10.000
         */
        readonly precision?: number;
        /**
         * The currency code, e.g. EUR, USD, CHF
         */
        currency: string;
        /**
         * The formatted amount, e.g. 10.00 €, 10.00 $, 10.00 CHF
         */
        readonly i18n?: string;
        /**
         * Amount which can be used as input value, e.g. 10.00, 10.000.
         */
        inputValue: string;
    };
    /**
     * The amount which was refunded.
     */
    readonly refundedAmount?: number;
    /**
     * The amount which was charged to the payment method.
     */
    readonly chargedAmount?: number;
    /**
     * The amount of the transaction fee.
     */
    readonly feeAmount?: number | null;
    /**
     * The date when the transaction was created.
     */
    readonly createdAt?: string;
    /**
     * The date when the transaction was charged.
     */
    readonly paidAt?: string | null;
};
export namespace Transaction_BankAccountTransactionDetail {
    export enum type {
        PAYMENT = 'payment',
        REFUND = 'refund',
    }
    /**
     * The status of the transaction.
     *
     * Possible values:
     * * `new`: The transaction is new and not yet processed. A new transaction is created when a payment is initiated.
     * * `authorized`: The transaction is authorized and can be captured.
     * * `authorizing`: The transaction is authorizing. No actions can be performed on the transaction.
     * * `waiting`: The transaction is waiting for a response from the payment provider, or a customer input. No actions can be performed on the transaction.
     * * `offsite_authorization_required`: The transaction requires an offsite authorization, e.g. 3D Secure. The customer is informed about the required authorization and redirected to the payment provider.
     * * `booked`: The transaction is captured and booked on the account.
     * * `captured`: The transaction is captured and will be booked on the account.
     * * `dispute`: The transaction is disputed.
     * * `manual`: Legacy status. Do not use.
     * * `canceled`: The transaction is canceled, e.g. by the customer during the payment process, or by the merchant.
     * * `pending`: The transaction is pending, and will be processed soon by the payment provider.
     * * `expired`: The transaction is expired, e.g. because the customer did not complete the payment process in time.
     * * `processing`: The transaction is processing, e.g. because the payment provider is currently processing the payment.
     * * `failed`: The transaction failed, e.g. because the payment provider could not process the payment.
     * * `unknown`: The transaction status is unknown.
     * * `refunded`: The transaction is fully refunded.
     * * `payedout`: The transaction was a payout.
     * * `partial_refunded`: The transaction is partially refunded.
     * * `refund_failed`: The transaction refund failed.
     */
    export enum status {
        NEW = 'new',
        AUTHORIZED = 'authorized',
        AUTHORIZING = 'authorizing',
        WAITING = 'waiting',
        OFFSITE_AUTHORIZATION_REQUIRED = 'offsite_authorization_required',
        BOOKED = 'booked',
        CAPTURED = 'captured',
        DISPUTE = 'dispute',
        MANUAL = 'manual',
        CANCELED = 'canceled',
        PENDING = 'pending',
        EXPIRED = 'expired',
        PROCESSING = 'processing',
        FAILED = 'failed',
        UNKNOWN = 'unknown',
        REFUNDED = 'refunded',
        PAYEDOUT = 'payedout',
        PARTIAL_REFUNDED = 'partial_refunded',
        REFUND_FAILED = 'refund_failed',
    }
}

