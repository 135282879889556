/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Invoice_SepaXmlStatementDetail } from './Invoice_SepaXmlStatementDetail';
import type { SepaMandate_SepaXmlStatementDetail } from './SepaMandate_SepaXmlStatementDetail';
import type { Transaction_SepaXmlStatementDetail } from './Transaction_SepaXmlStatementDetail';
export type SepaXmlPayment_SepaXmlStatementDetail = {
    readonly id?: string;
    /**
     *
     *
     * Possible values:
     * * `credit`: Credit
     * * `debit`: Debit
     */
    type?: SepaXmlPayment_SepaXmlStatementDetail.type;
    createdAt?: string;
    dueDate?: string | null;
    remittanceInformation?: string | null;
    endToEndId?: string | null;
    amount?: number;
    readonly sepaMandate?: SepaMandate_SepaXmlStatementDetail;
    /**
     * The invoice of the payment. In case of a cart payment or manual payment intent, the invoice is null.
     */
    invoice?: Invoice_SepaXmlStatementDetail | null;
    /**
     * The transaction of the payment.
     */
    transaction?: Transaction_SepaXmlStatementDetail;
    readonly autoCaptureAt?: string | null;
};
export namespace SepaXmlPayment_SepaXmlStatementDetail {
    /**
     *
     *
     * Possible values:
     * * `credit`: Credit
     * * `debit`: Debit
     */
    export enum type {
        CREDIT = 'credit',
        DEBIT = 'debit',
    }
}

