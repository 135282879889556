/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CostCentre } from './CostCentre';
import type { Measurement } from './Measurement';
import type { PricePlan } from './PricePlan';
import type { ProductFamily } from './ProductFamily';
import type { ProductTranslation } from './ProductTranslation';
import type { TaxGroup } from './TaxGroup';
export type Product = {
    /**
     * The unique identifier of the product.
     */
    readonly id?: string;
    /**
     * A product family groups multiple products together.
     */
    productFamilies: Array<ProductFamily>;
    /**
     * The name of the product based on the current tenant language. This will be displayed on customer communication.
     */
    name?: string;
    /**
     * The description of the product based on the current tenant language.
     */
    description?: string | null;
    /**
     * Internal name of the product, to differentiate between products with the same name. This will shown in web-app lists, selections and reports.
     */
    internalName?: string | null;
    /**
     * Internal product number
     */
    number: string | null;
    /**
     * The translations of the product. The locale is used as key.
     */
    translations: Record<string, ProductTranslation>;
    /**
     * The type of the product.
     * - `product`: recurring billed product
     * - `charge`: one-time billing
     * - `plan`: plan specific product, which cannot be used as a normal product. Will be filtered out in any product lists.
     *
     */
    type: Product.type;
    /**
     * The measurement that is used for the price plan. This could define the quantity or a metered usage.
     */
    measurement: Measurement;
    /**
     * The price plans of the product. Describes multiple prices which could be selled by this product.
     */
    pricePlans?: Array<PricePlan>;
    /**
     * Defines when the product should be displayed in the invoice.
     * - `always`: The product is always displayed in the invoice.
     * - `only_if_charged`: The product is only displayed in the invoice if it is charged.
     *
     */
    invoiceVisibility?: Product.invoiceVisibility;
    /**
     * The cost centre is used for accounting exports.
     */
    costCentre?: CostCentre | null;
    readonly createdAt?: string;
    readonly updatedAt?: string;
    /**
     * The tax group that is used for the product.
     */
    taxGroup: TaxGroup;
    /**
     * Defines if the product is archived and should not be used anymore.
     */
    isArchived?: boolean;
    /**
     * Custom fields for the entity. The keys are the field names and the values are the field values. They need to be configured under "/custom-fields" in the API documentation.
     */
    customFields?: Array<string>;
};
export namespace Product {
    /**
     * The type of the product.
     * - `product`: recurring billed product
     * - `charge`: one-time billing
     * - `plan`: plan specific product, which cannot be used as a normal product. Will be filtered out in any product lists.
     *
     */
    export enum type {
        PRODUCT = 'product',
        CHARGE = 'charge',
        PLAN = 'plan',
    }
    /**
     * Defines when the product should be displayed in the invoice.
     * - `always`: The product is always displayed in the invoice.
     * - `only_if_charged`: The product is only displayed in the invoice if it is charged.
     *
     */
    export enum invoiceVisibility {
        ALWAYS = 'always',
        ONLY_IF_CHARGED = 'only_if_charged',
    }
}

