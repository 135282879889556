import { computed, type Ref, ref, watch } from 'vue';
import type { DataTablePageEvent, DataTableSortMeta as PrimeVueDataTableSortMeta } from 'primevue/datatable';
import { debounce } from 'perfect-debounce';
import { useRoute } from 'vue-router';
import type { ParsedQs } from 'qs';
import type { ApiResult } from '~/types/datatable';
import useMessage from '~/composables/useMessage';
import {
  convertDataTableSortToApiOptions,
  convertParamsToDateFilterParams,
  convertParamsToFilterParams,
  getDateFiltersFromRoute,
  getFiltersFromRoute,
  getItemsPerPageQueryFromRoute,
  getPageQueryFromRoute,
  getQueryParamsByTableKey,
  getSearchQueryFromRoute,
  getSortQueryFromRoute,
  updateQueryParams,
} from '~/utils/datatable';
import type { CancelablePromise } from '~/api-generated/core/CancelablePromise';
import {useLazyAsyncData} from "#imports";

export type DataTableSearchEvent = {
  search: string;
};

type ApiCallbackResult<ApiCallbackResponseItem> = {
  data?: Array<ApiCallbackResponseItem>;
  meta?: {
    pagination?: {
      totalItems?: number;
      itemsPerPage?: number;
      currentPage?: number;
      lastPage?: number;
      pageTotalItems?: number;
    };
  };
};

export type ExtractedDataTableResultItem<ApiCallback> = ApiCallback extends (params: any) => CancelablePromise<ApiCallbackResult<infer ApiCallbackResponseItem>>
  ? ApiCallbackResponseItem
  : never;
export type ApiCallbackParameters<ApiCallback extends (...args: any) => any> = Parameters<ApiCallback>[0];

/** prefiltered parameters based on api callback parameters **/

type SortPrefix = `order${string}`;

type ApiCallbackOrderParams<ApiCallbackParams> = {
  [Parameter in keyof ApiCallbackParams as Parameter extends SortPrefix ? Parameter : never]: ApiCallbackParams[Parameter];
};

type DataTableDateFilterParams<ApiCallbackParams> = {
  [Parameter in keyof ApiCallbackParams as Parameter extends `${string}Before` | `${string}StrictlyBefore` | `${string}After` | `${string}StrictlyAfter`
    ? Parameter
    : never]: ApiCallbackParams[Parameter];
};

type DataTableFilterParams<ApiCallbackParams> = Omit<ApiCallbackParams, keyof ApiCallbackOrderParams<ApiCallbackParams> | 'search' | 'page' | 'itemsPerPage'>;

export type DataTableSortMeta<ApiCallbackParams> = PrimeVueDataTableSortMeta & {
  field: keyof ApiCallbackOrderParams<ApiCallbackParams>;
};

/** events **/
export type DataTableFilterItemEvent<ApiCallbackParams> = {
  key: keyof DataTableFilterParams<ApiCallbackParams>;
  value: DataTableFilterParams<ApiCallbackParams>[keyof DataTableFilterParams<ApiCallbackParams>];
};

export type DataTableFilterDateRangeEvent<ApiCallbackParams> = {
  key: keyof DataTableDateFilterParams<ApiCallbackParams>;
  value: Date;
};

export async function useTable<ApiCallbackParams, ApiCallbackResponseItem>(
  key: string,
  apiCallable: Function,
  defaultSortData?: DataTableSortMeta<ApiCallbackParams>[],
  defaultFilters?: DataTableFilterItemEvent<ApiCallbackParams>[],
  defaultDateFilters?: DataTableFilterDateRangeEvent<ApiCallbackParams>[],
  customParams?: { [key: string]: unknown },
  hideFiltersInQuery: boolean = false, // if true, filters are not shown as query parameters e.g. because they are already set in the path
  defaultItemsPerPage: number = 10,
  primevueFiltersDefault: Record<string, {value: any }> = {}
): Promise<{
  reload: () => Promise<void>;
  loading: Ref<boolean>;
  rows: Ref<number>;
  page: Ref<number>;
  totalRecords: Ref<number>;
  sortData: Ref<DataTableSortMeta<ApiCallbackParams>[]>;
  value: Ref<Array<ApiCallbackResponseItem>>;
  search: Ref<string>;
  dateFilters: Ref<DataTableFilterDateRangeEvent<ApiCallbackParams>[]>;
  filters: Ref<DataTableFilterItemEvent<ApiCallbackParams>[]>;
  primevueFilters: Ref<Record<string, {value: any }>>;
  onSort: (sortData?: DataTableSortMeta<ApiCallbackParams>[]) => void;
  onPage: (event: DataTablePageEvent) => void;
  onUpdateFilters: (newFilters: DataTableFilterItemEvent<ApiCallbackParams>[]) => void;
  onUpdateDateFilters: (newDateFilters: DataTableFilterDateRangeEvent<ApiCallbackParams>[]) => void;
  onSearch: (event: DataTableSearchEvent) => void;
  onSearchDebounced: (event: DataTableSearchEvent) => void;
}> {
  const message = useMessage();

  // we are using QS to parse the query (see app/app/router.options.ts),
  // but nuxt does not know that the query type has changed, so we are enforcing the correct type here.
  // if this causes more problems in the future, we should check if we can fix the typing or maybe create wrapper functions for navigateTo and useRoute
  const route = useRoute() as unknown as { query: ParsedQs };

  const tableQuery = ref<ParsedQs | null>(null);

  watch(
    () => route.query,
    () => {
      tableQuery.value = getQueryParamsByTableKey(key, route.query);
    },
    { immediate: true }
  );

  const dateFilters = ref(getDateFiltersFromRoute<ApiCallbackParams>(tableQuery.value) ?? defaultDateFilters ?? []) as Ref<
    DataTableFilterDateRangeEvent<ApiCallbackParams>[]
  >;
  const filters = ref(getFiltersFromRoute<ApiCallbackParams>(tableQuery.value) ?? defaultFilters ?? []) as Ref<DataTableFilterItemEvent<ApiCallbackParams>[]>;
  const sortData = ref(getSortQueryFromRoute<ApiCallbackParams>(tableQuery.value) ?? defaultSortData ?? []) as Ref<DataTableSortMeta<ApiCallbackParams>[]>;

  const primevueFilters = ref<Record<string, {value: any }>>(
    {
      ...primevueFiltersDefault,
      ...Object.fromEntries(
        filters.value.map((filter) => [filter.key, { value: filter.value }])
      ),
    }
  );

  watch(
    () => tableQuery.value,
    () => {
      dateFilters.value = getDateFiltersFromRoute(tableQuery.value) ?? [];

      if (!hideFiltersInQuery) {
        filters.value = getFiltersFromRoute(tableQuery.value) ?? [];
      }

      sortData.value = getSortQueryFromRoute(tableQuery.value) ?? [];
    }
  );
  const rows = computed(() => getItemsPerPageQueryFromRoute(tableQuery.value, defaultItemsPerPage));
  const page = computed(() => getPageQueryFromRoute(tableQuery.value));
  const search = computed(() => getSearchQueryFromRoute(tableQuery.value) ?? '');

  const totalRecords = computed<number>(() => result.value?.meta?.pagination?.totalItems ?? 0);
  const value = computed<Array<any>>(() => result.value?.data ?? []);

  const { data: result, status, refresh, clear, error } = await useLazyAsyncData<ApiResult | null>(
    key,
    () => apiCallable({
      ...(customParams && { ...customParams }),
      ...convertDataTableSortToApiOptions<ApiCallbackParams>(sortData.value),
      ...convertParamsToFilterParams<ApiCallbackParams>(filters.value),
      ...convertParamsToDateFilterParams<ApiCallbackParams>(dateFilters.value),
      limit: rows.value,
      search: search.value?.trim() === '' ? undefined : search.value,
      page: page.value,
    }),
  );

  const loading = computed(() => {
    return status.value === 'pending';
  });

  const load = async () => {
    try {
      await refresh();
    } catch (e) {
      message.error('Beim Laden der Daten ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.');
    }
  };

  const handleQueryParamsChange = (params: {
    currentPage?: number;
    currentRows?: number;
    currentSortData?: DataTableSortMeta<ApiCallbackParams>[];
    currentFilters?: DataTableFilterItemEvent<ApiCallbackParams>[];
    currentDateFilters?: DataTableFilterDateRangeEvent<ApiCallbackParams>[];
    currentSearch?: string | null;
  }) => {
    const {
      currentPage = page.value,
      currentRows = rows.value,
      currentSortData = sortData.value,
      currentFilters = filters.value,
      currentDateFilters = dateFilters.value,
      currentSearch = search.value?.trim() === '' ? undefined : search.value,
    } = params;

    updateQueryParams<ApiCallbackParams>(
      key,
      route.query,
      hideFiltersInQuery,
      currentSortData,
      currentFilters,
      currentDateFilters,
      currentPage,
      currentRows,
      currentSearch ?? undefined
    );
  };

  const onPage = (event: DataTablePageEvent) => {
    console.log('onPage', event.page, event.rows);
    handleQueryParamsChange({
      currentPage: event.page + 1,
      currentRows: event.rows,
    });
  };

  const onUpdateFilters = (newFilters: DataTableFilterItemEvent<ApiCallbackParams>[]) => {
    handleQueryParamsChange({
      currentFilters: newFilters,
      currentPage: 1,
    });
  };

  const onUpdateDateFilters = (newDateFilters: DataTableFilterDateRangeEvent<ApiCallbackParams>[]) => {
    handleQueryParamsChange({
      currentDateFilters: newDateFilters,
    });
  };

  const onSort = (sortData?: DataTableSortMeta<ApiCallbackParams>[]) => {
    handleQueryParamsChange({
      currentSortData: sortData ?? [],
    });
  };

  const onSearch = (event: DataTableSearchEvent) => {
    handleQueryParamsChange({
      currentPage: 1,
      currentSearch: event.search?.trim() === '' ? null : event.search,
    });
  };

  const onSearchDebounced = debounce(onSearch, 500);

  const reload = async () => {
    await load();
  };

  watch(
    () => tableQuery.value,
    () => load(),
    { immediate: true, flush: 'post' }
  );

  return {
    reload,
    loading,
    rows,
    page,
    totalRecords,
    sortData,
    value,
    search,
    dateFilters,
    filters,
    primevueFilters,
    onSort,
    onPage,
    onUpdateFilters,
    onUpdateDateFilters,
    onSearch,
    onSearchDebounced,
  };
}
