/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { User_UserMeResponse_UserInvitationDetail } from './User_UserMeResponse_UserInvitationDetail';
export type UserInvitation_UserInvitationDetail = {
    readonly id?: string;
    readonly user?: User_UserMeResponse_UserInvitationDetail | null;
    email?: string;
    expiresAt?: string;
    readonly acceptedAt?: string | null;
    role?: UserInvitation_UserInvitationDetail.role;
};
export namespace UserInvitation_UserInvitationDetail {
    export enum role {
        ADMINISTRATOR = 'administrator',
        ACCOUNT = 'account',
        READ_ONLY = 'read-only',
    }
}

