/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Unit_ProductRead } from './Unit_ProductRead';
/**
 * Measurements are used to define the *amount of a unit that is used* for the price calculation.
 * *SaaS Product Examples*
 * * User: the amount of users that are allowed to use the software.
 * * Member: the amount of members that are allowed to use the software.
 * * Environment: the amount of environments that are allowed to use the software.
 *
 * *Hosting Examples*
 * * CPU: the amount of CPU cores that are used.
 * * RAM: the amount of RAM that is used.
 * * Storage: the amount of storage in GB that is used.
 * * Traffic: the amount of traffic in GB that is used.
 *
 * * The aggregation type defines *how the amount of units is calculated* during a billing interval.
 * * The unit defines *what is measured*.
 *
 * The aggregation types are useful to be more flexible in the billing process.
 * Imagine you sell a product with user seats, but you want to bill the customer for the maximum amount of users that were active during the billing interval.
 * In this case you would use the aggregation type "max".
 * If you want to bill the customer for the sum of all users that were active during the billing interval, you would use the aggregation type "sum".
 * If you want to bill the customer for the average amount of users that were active during the billing interval, you would use the aggregation type "avg".
 *
 * The aggregation type also depends how and when you push the measurements to the API. Feel free to contact us if you need help.
 *
 */
export type Measurement_ProductRead = {
    /**
     * A unique identifier for the measurement.
     */
    readonly id?: string;
    /**
     * The unit of the measurement.
     */
    unit: Unit_ProductRead | null;
    /**
     * A unique code which can be used to identify the measurement.
     */
    code: string;
    /**
     * A description of the measurement, which is shown in the summary of the usage data in the invoice.
     */
    description?: string | null;
    /**
     * The aggregation type of the measurement. Describes how the quantity is calculated. This cannot be changed after creation, otherwise it would change the whole calculation for existing subscriptions.
     *
     * Possible values:
     * * `count`: The number of sent measurements in the billing interval. Metered usage, which resets to 0 after each billing interval.
     * * `count_unique`: The number of unique sent measurements in the billing interval, identified by the id given on event creation.
     * * `max`: The maximum value of all sent measurements in the billing interval. Metered usage, which resets to 0 after each billing interval.
     * * `sum`: The sum of all sent measurements in the billing interval.
     * * `last_value`: The last sent measurement.
     * * `average`: The average of all sent measurements in the billing interval.
     */
    aggregationType?: Measurement_ProductRead.aggregationType;
    /**
     * If set to false, the measurement will be billed for the whole billing interval, even if the quantity changes, or the item is cancelled / terminated during the billing interval.
     */
    fairBilling?: boolean;
    /**
     * The type of the measurement. This cannot be changed after creation, otherwise it would change the whole calculation for existing subscriptions.
     *
     * Possible values:
     * * `instant_metered`: The measurement value is reset to 0 after each push. The measurement gets billed immediately and an invoice is created. The aggregation type must be "last_value".
     * * `metered`: The measurement value is reset to 0 after each billing interval.
     * * `recurring`: The measurement value is not reset to 0 after each billing interval and the last value is used for all following billing intervals. The aggregation type must be "last_value".
     */
    type?: Measurement_ProductRead.type;
};
export namespace Measurement_ProductRead {
    /**
     * The aggregation type of the measurement. Describes how the quantity is calculated. This cannot be changed after creation, otherwise it would change the whole calculation for existing subscriptions.
     *
     * Possible values:
     * * `count`: The number of sent measurements in the billing interval. Metered usage, which resets to 0 after each billing interval.
     * * `count_unique`: The number of unique sent measurements in the billing interval, identified by the id given on event creation.
     * * `max`: The maximum value of all sent measurements in the billing interval. Metered usage, which resets to 0 after each billing interval.
     * * `sum`: The sum of all sent measurements in the billing interval.
     * * `last_value`: The last sent measurement.
     * * `average`: The average of all sent measurements in the billing interval.
     */
    export enum aggregationType {
        COUNT = 'count',
        COUNT_UNIQUE = 'count_unique',
        MAX = 'max',
        SUM = 'sum',
        AVERAGE = 'average',
        LAST_VALUE = 'last_value',
    }
    /**
     * The type of the measurement. This cannot be changed after creation, otherwise it would change the whole calculation for existing subscriptions.
     *
     * Possible values:
     * * `instant_metered`: The measurement value is reset to 0 after each push. The measurement gets billed immediately and an invoice is created. The aggregation type must be "last_value".
     * * `metered`: The measurement value is reset to 0 after each billing interval.
     * * `recurring`: The measurement value is not reset to 0 after each billing interval and the last value is used for all following billing intervals. The aggregation type must be "last_value".
     */
    export enum type {
        RECURRING = 'recurring',
        METERED = 'metered',
        INSTANT_METERED = 'instant_metered',
    }
}

