/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type NumberSetting = {
    readonly id?: string;
    /**
     * Format for the generated number. Available placeholders:
     * * {year} - current year (short)
     * * {yearLong} - current year (long)
     * * {month} - current month
     * * {day} - current day
     * * {number} - current increasing number, or generated string
     * * {customerNumber} - customer number, only available for types: "TYPE_INVOICE", "INVOICE_CREDIT", "INVOICE_REMINDER"
     *
     */
    format?: string;
    currentNumber?: number | null;
    numberLength?: number;
    type?: NumberSetting.type;
    strategy?: NumberSetting.strategy;
    readonly tenantId?: string;
};
export namespace NumberSetting {
    export enum type {
        TYPE_INVOICE = 'TYPE_INVOICE',
        TYPE_DEBITOR = 'TYPE_DEBITOR',
        TYPE_SUBSCRIPTION = 'TYPE_SUBSCRIPTION',
        INVOICE_CREDIT = 'INVOICE_CREDIT',
        INVOICE_REMINDER = 'INVOICE_REMINDER',
        DEBITOR_DATEV_ID = 'DEBITOR_DATEV_ID',
        MANDATE_REFERENCE = 'mandate-reference',
        TRANSACTION_DOCUMENT_NUMBER = 'TRANSACTION_DOCUMENT_NUMBER',
    }
    export enum strategy {
        STRATEGY_RANDOM_STRING = 'STRATEGY_RANDOM_STRING',
        STRATEGY_INCREASING_NUMBER = 'STRATEGY_INCREASING_NUMBER',
    }
}

