/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BillingGroup_Cart } from './BillingGroup_Cart';
import type { ContractDetails_Cart } from './ContractDetails_Cart';
import type { PartnerResource_Cart } from './PartnerResource_Cart';
import type { TermPeriod_Cart } from './TermPeriod_Cart';
export type Subscription_Cart = {
    readonly id?: string;
    externalId?: string | null;
    /**
     * The number of the subscription.
     */
    number?: string;
    /**
     * The name of the subscription. Defaults to the name of the plan. This will be used in the invoice for the grouping headers.
     */
    name?: string | null;
    /**
     * The billing group defines the billing cycle of the subscription.
     */
    billingGroup?: BillingGroup_Cart | null;
    periods?: Array<TermPeriod_Cart>;
    /**
     * The status of the subscription.
     *
     * Possible values:
     * * `draft`: The subscription is in draft mode and not active yet.
     * * `active`: The subscription is active and will be billed.
     * * `paused`: The subscription is paused and will not be billed.
     * * `cancelled`: The subscription is cancelled and will not be billed anymore.
     * * `terminated`: The subscription is terminated and will not be billed anymore.
     * * `offer`: The subscription is an offer and waiting for acceptance.
     */
    readonly status?: Subscription_Cart.status;
    /**
     * The date the subscription was activated.
     */
    readonly activatedAt?: string | null;
    /**
     * The po number of the subscription.
     */
    poNumber?: string | null;
    /**
     * The contract details of the subscription.
     */
    contractDetails?: ContractDetails_Cart;
    /**
     * The date the trial period ends.
     */
    readonly trialEndsOn?: string | null;
    /**
     * Custom fields for the entity. The keys are the field names and the values are the field values. They need to be configured under "/custom-fields" in the API documentation.
     */
    customFields?: Array<string> | null;
    /**
     * The next billing date of the subscription.
     */
    readonly nextBillingDate?: string | null;
    /**
     * The last billing date of the subscription.
     */
    readonly lastBillingAt?: string | null;
    /**
     * The partner of the customer.
     */
    readonly partner?: PartnerResource_Cart | null;
};
export namespace Subscription_Cart {
    /**
     * The status of the subscription.
     *
     * Possible values:
     * * `draft`: The subscription is in draft mode and not active yet.
     * * `active`: The subscription is active and will be billed.
     * * `paused`: The subscription is paused and will not be billed.
     * * `cancelled`: The subscription is cancelled and will not be billed anymore.
     * * `terminated`: The subscription is terminated and will not be billed anymore.
     * * `offer`: The subscription is an offer and waiting for acceptance.
     */
    export enum status {
        DRAFT = 'draft',
        ACTIVE = 'active',
        PAUSED = 'paused',
        OFFER = 'offer',
        CANCELLED = 'cancelled',
        TERMINATED = 'terminated',
    }
}

