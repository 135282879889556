/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CostCentre_SubscriptionDetail } from './CostCentre_SubscriptionDetail';
import type { Measurement_SubscriptionDetail } from './Measurement_SubscriptionDetail';
import type { TaxGroup_SubscriptionDetail } from './TaxGroup_SubscriptionDetail';
export type Product_SubscriptionDetail = {
    /**
     * The unique identifier of the product.
     */
    readonly id?: string;
    /**
     * The name of the product based on the current tenant language. This will be displayed on customer communication.
     */
    name?: string;
    /**
     * The description of the product based on the current tenant language.
     */
    description?: string | null;
    /**
     * Internal name of the product, to differentiate between products with the same name. This will shown in web-app lists, selections and reports.
     */
    internalName?: string | null;
    /**
     * The type of the product.
     * - `product`: recurring billed product
     * - `charge`: one-time billing
     * - `plan`: plan specific product, which cannot be used as a normal product. Will be filtered out in any product lists.
     *
     */
    type: Product_SubscriptionDetail.type;
    /**
     * The measurement that is used for the price plan. This could define the quantity or a metered usage.
     */
    measurement: Measurement_SubscriptionDetail;
    /**
     * Defines when the product should be displayed in the invoice.
     * - `always`: The product is always displayed in the invoice.
     * - `only_if_charged`: The product is only displayed in the invoice if it is charged.
     *
     */
    invoiceVisibility?: Product_SubscriptionDetail.invoiceVisibility;
    /**
     * The cost centre is used for accounting exports.
     */
    costCentre?: CostCentre_SubscriptionDetail | null;
    /**
     * The tax group that is used for the product.
     */
    taxGroup: TaxGroup_SubscriptionDetail;
    /**
     * Custom fields for the entity. The keys are the field names and the values are the field values. They need to be configured under "/custom-fields" in the API documentation.
     */
    customFields?: Array<string>;
};
export namespace Product_SubscriptionDetail {
    /**
     * The type of the product.
     * - `product`: recurring billed product
     * - `charge`: one-time billing
     * - `plan`: plan specific product, which cannot be used as a normal product. Will be filtered out in any product lists.
     *
     */
    export enum type {
        PRODUCT = 'product',
        CHARGE = 'charge',
        PLAN = 'plan',
    }
    /**
     * Defines when the product should be displayed in the invoice.
     * - `always`: The product is always displayed in the invoice.
     * - `only_if_charged`: The product is only displayed in the invoice if it is charged.
     *
     */
    export enum invoiceVisibility {
        ALWAYS = 'always',
        ONLY_IF_CHARGED = 'only_if_charged',
    }
}

