/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CustomerAddress_CustomerAddressDetail = {
    readonly id?: string;
    /**
     * VAT ID, which will be used to bill to. If not provided, we will use the customer vat id.
     */
    vatId?: string | null;
    type?: CustomerAddress_CustomerAddressDetail.type;
    default?: boolean;
    readonly status?: CustomerAddress_CustomerAddressDetail.status;
    city?: string;
    zip?: string;
    street?: string;
    houseNumber?: string;
    countryCode?: string;
    /**
     * Company name, which will be used to bill to.
     */
    companyName?: string | null;
    salutation?: CustomerAddress_CustomerAddressDetail.salutation | null;
    /**
     * Additional address information, which will be shown on generated documents like invoices, reminders, etc.
     */
    addition?: string | null;
    /**
     * Cost centre, which will be shown on generated documents like invoices, reminders, etc.
     */
    costCentre?: string | null;
    /**
     * First name, which will be shown on generated documents like invoices, reminders, etc.
     */
    firstName?: string | null;
    /**
     * Last name, which will be shown on generated documents like invoices, reminders, etc.
     */
    lastName?: string | null;
};
export namespace CustomerAddress_CustomerAddressDetail {
    export enum type {
        TYPE_DEFAULT = 'TYPE_DEFAULT',
        TYPE_INVOICE = 'TYPE_INVOICE',
    }
    export enum status {
        ACTIVE = 'active',
        ARCHIVED = 'archived',
    }
    export enum salutation {
        FIRMA = 'Firma',
        HERR = 'Herr',
        FRAU = 'Frau',
    }
}

