/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Account_ActivityRead } from './Account_ActivityRead';
export type Customer_Activity_ActivityRead = {
    readonly id?: string;
    readonly dateTime?: string;
    readonly type?: Customer_Activity_ActivityRead.type;
    /**
     * Nulled users are system activities
     */
    readonly user?: Account_ActivityRead | null;
    readonly metadata?: Record<string, string>;
    readonly pinned?: boolean;
};
export namespace Customer_Activity_ActivityRead {
    export enum type {
        COMMENTED = 'commented',
        CUSTOMER_CREATED = 'customer.created',
        CUSTOMER_EMAIL_ADDED = 'customer.email.added',
        CUSTOMER_EMAIL_DEFAULT_CHANGED = 'customer.email.default_changed',
        CUSTOMER_ADDRESS_ADDED = 'customer.address.added',
        SUBSCRIPTION_REMOVED = 'subscription.removed',
        CUSTOM_FIELDS_UPDATED = 'custom_fields.updated',
        CUSTOMER_ADDRESS_DEFAULT_CHANGED = 'customer.address.default_changed',
        CUSTOMER_UPDATED = 'customer.updated',
        STRIPE_CUSTOMER_ATTACHED = 'stripe.customer.attached',
        CUSTOMER_ARCHIVED = 'customer.archived',
        CUSTOMER_UNARCHIVED = 'customer.unarchived',
        CUSTOMER_GROUP_ASSIGNED = 'customer.group.assigned',
        CUSTOMER_CONTACT_PERSON_ASSIGNED = 'customer.contact_person.assigned',
        CUSTOMER_CONTACT_PERSON_REMOVED = 'customer.contact_person.removed',
        CUSTOMER_PARTNER_ASSIGNED = 'customer.partner.assigned',
        CUSTOMER_PARTNER_REMOVED = 'customer.partner.removed',
        CUSTOMER_VAT_ID_VALIDATED = 'customer.vat_id.validated',
        CUSTOMER_VAT_ID_INVALIDATED = 'customer.vat_id.invalidated',
        SUBSCRIPTION_TRIAL_EXTENDED = 'subscription.trial.extended',
        SUBSCRIPTION_TRIAL_REVOKED = 'subscription.trial.revoked',
        SUBSCRIPTION_PAYMENT_METHOD_UPDATED = 'subscription.payment_method.updated',
        SUBSCRIPTION_BILLING_GROUP_UPDATED = 'subscription.billing_group.updated',
        SUBSCRIPTION_ACTIVATED = 'subscription.activated',
        SUBSCRIPTION_CANCELLED = 'subscription.cancelled',
        SUBSCRIPTION_TERMINATED = 'subscription.terminated',
        SUBSCRIPTION_UPDATED = 'subscription.updated',
        SUBSCRIPTION_INVOICE_ADDRESS_UPDATED = 'subscription.invoice_address.updated',
        SUBSCRIPTION_PAUSED = 'subscription.paused',
        SUBSCRIPTION_RESUMED = 'subscription.resumed',
        SUBSCRIPTION_CANCELLATION_REVOKED = 'subscription.cancellation.revoked',
        SUBSCRIPTION_ITEM_ADDED = 'subscription.item.added',
        SUBSCRIPTION_OFFER_CREATED = 'subscription.offer.created',
        PRODUCT_CREATED = 'product.created',
        PRODUCT_UPDATED = 'product.updated',
        PRODUCT_ARCHIVED = 'product.archived',
        PRODUCT_UNARCHIVED = 'product.unarchived',
        SUBSCRIPTION_CREATED = 'subscription.created',
        API_KEY_CREATED = 'api_key.created',
        PAYMENT_GATEWAY_CONFIGURED = 'payment_gateway.configured',
        TENANT_COMPLETED = 'tenant.completed',
        CHECKOUT_LINK_CREATED = 'checkout_link.created',
        PRICE_CREATED = 'price.created',
        USER_INVITATION_SENT = 'user.invitation.sent',
        TENANT_UPDATED = 'tenant.updated',
        TENANT_CREATED = 'tenant.created',
        TENANT_ACCOUNT_ADDED = 'tenant.account.added',
        USER_INVITATION_ACCEPTED = 'user.invitation.accepted',
        USER_INVITATION_REVOKED = 'user.invitation.revoked',
        WEBHOOK_CREATED = 'webhook.created',
        GET_STARTED_FINISHED = 'get_started.finished',
        CUSTOMER_REMOVED = 'customer.removed',
        INVOICE_REMOVED = 'invoice.removed',
        INVOICE_CREATED = 'invoice.created',
        INVOICE_FINALIZED = 'invoice.finalized',
        INVOICE_CREDITED = 'invoice.credited',
        INVOICE_IMPORTED = 'invoice.imported',
        INVOICE_PAYMENT_INITIATED = 'invoice.payment.initiated',
        INVOICE_SENT = 'invoice.sent',
        INVOICE_PAID = 'invoice.paid',
        INVOICE_PAYMENT_METHOD_UPDATED = 'invoice.payment_method.updated',
        INVOICE_CANCELED = 'invoice.canceled',
        INVOICE_CLOSED = 'invoice.closed',
        INVOICE_REFUNDED = 'invoice.refunded',
        INVOICE_UNPAID = 'invoice.unpaid',
        INVOICE_REQUIRES_APPROVAL = 'invoice.requires_approval',
        INVOICE_DUNNING_APPLIED = 'invoice.dunning.applied',
        PAYMENT_RETRY = 'payment.retry',
        PAYMENT_PAYMENT_METHOD_UPDATED = 'payment.payment_method.updated',
        PAYMENT_FAILED = 'payment.failed',
        INVOICE_DUNNING_DISABLED = 'invoice.dunning.disabled',
        INVOICE_DUNNING_ENABLED = 'invoice.dunning.enabled',
        INVOICE_UPDATED = 'invoice.updated',
        IMPORT_COMPLETED = 'import.completed',
    }
}

