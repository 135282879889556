/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CustomerEmail = {
    readonly id?: string;
    customer?: string;
    email: string;
    receiverName: string;
    type: CustomerEmail.type;
    default?: boolean;
    readonly tenantId?: string;
};
export namespace CustomerEmail {
    export enum type {
        TYPE_COMPANY = 'TYPE_COMPANY',
        TYPE_INVOICE = 'TYPE_INVOICE',
    }
}

