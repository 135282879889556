/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ContactPerson_OfferDetail } from './ContactPerson_OfferDetail';
import type { Customer_OfferDetail } from './Customer_OfferDetail';
import type { Media_OfferDetail } from './Media_OfferDetail';
import type { OfferRecipient_OfferDetail } from './OfferRecipient_OfferDetail';
import type { Subscription_OfferDetail } from './Subscription_OfferDetail';
export type Offer_OfferDetail = {
    readonly id?: string;
    subscription?: Subscription_OfferDetail | null;
    readonly customer?: Customer_OfferDetail | null;
    number?: string;
    name?: string | null;
    readonly status?: Offer_OfferDetail.status;
    description?: string | null;
    sections?: Array<string>;
    readonly locale?: string;
    readonly recipients?: Array<OfferRecipient_OfferDetail>;
    readonly auditLogDocument?: Media_OfferDetail | null;
    readonly signedDocument?: Media_OfferDetail | null;
    readonly signedAt?: string | null;
    autoActivateSubscription?: boolean;
    readonly crmDealId?: string | null;
    /**
     * The date and time when the resource was created.
     */
    readonly createdAt?: string;
    /**
     * The date and time when the resource was last updated.
     */
    readonly updatedAt?: string;
    /**
     * The contact person of the offer
     */
    readonly contactPerson?: ContactPerson_OfferDetail | null;
};
export namespace Offer_OfferDetail {
    export enum status {
        OPEN = 'open',
        SIGNED = 'signed',
        SIGNING = 'signing',
        ARCHIVED = 'archived',
    }
}

