/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CartItemResource_CartPublic } from './CartItemResource_CartPublic';
import type { CartPriceResource_CartPublic } from './CartPriceResource_CartPublic';
import type { CartSettingsResource_CartPublic } from './CartSettingsResource_CartPublic';
import type { CustomerAddress_CartPublic } from './CustomerAddress_CartPublic';
import type { CustomerPrefill_CartPublic } from './CustomerPrefill_CartPublic';
import type { PaymentMethod_CartPublic } from './PaymentMethod_CartPublic';
export type Cart_CartResource_CartPublic = {
    price?: CartPriceResource_CartPublic;
    id?: string;
    /**
     * The status of the cart.
     *
     * Possible values:
     * * `open`: The cart is open and can be edited.
     * * `confirming`: The cart is confirming and cannot be edited.
     */
    status?: Cart_CartResource_CartPublic.status;
    /**
     * The customer who owns the cart.
     */
    customer?: string | null;
    email?: string | null;
    invoiceAddress?: CustomerAddress_CartPublic | null;
    /**
     * The customer prefill data which should be used to prefill the checkout form.
     */
    customerPrefill?: CustomerPrefill_CartPublic | null;
    /**
     * The payment method which should be used for the cart.
     */
    paymentMethod?: PaymentMethod_CartPublic | null;
    /**
     * The items of the cart.
     */
    items?: Array<CartItemResource_CartPublic>;
    /**
     * Details shown to the customer when the cart is completed.
     */
    completionDetails?: Array<string> | null;
    /**
     * The settings of the cart.
     */
    settings?: CartSettingsResource_CartPublic;
};
export namespace Cart_CartResource_CartPublic {
    /**
     * The status of the cart.
     *
     * Possible values:
     * * `open`: The cart is open and can be edited.
     * * `confirming`: The cart is confirming and cannot be edited.
     */
    export enum status {
        OPEN = 'open',
        CONFIRMING = 'confirming',
        REDIRECT_TO_PAYMENT = 'redirect_to_payment',
        EXPIRED = 'expired',
        COMPLETED = 'completed',
        PAID = 'paid',
    }
}

