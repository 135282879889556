/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CheckoutSettings_TogglePaymentMethodRequest = {
    paymentMethod: CheckoutSettings_TogglePaymentMethodRequest.paymentMethod;
};
export namespace CheckoutSettings_TogglePaymentMethodRequest {
    export enum paymentMethod {
        SEPA_DEBIT = 'sepa_debit',
        CARD = 'card',
        PAYPAL = 'paypal',
        BANK_TRANSFER = 'bank_transfer',
        WALLET = 'wallet',
    }
}

