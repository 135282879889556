/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PaymentMethod_BankAccountTransactionDetail = {
    readonly id?: string;
    gatewayName?: PaymentMethod_BankAccountTransactionDetail.gatewayName;
    type?: PaymentMethod_BankAccountTransactionDetail.type | null;
    default?: boolean;
    status?: PaymentMethod_BankAccountTransactionDetail.status;
    enabled?: boolean;
    name?: string | null;
    readonly creationDate?: string;
    expirationDate?: string | null;
    /**
     * The card data of the payment method, if the type is "card"
     */
    readonly card?: {
        /**
         * The brand of the card
         */
        brand?: string;
        /**
         * The last 4 digits of the card
         */
        last4?: string;
        /**
         * The expiration month of the card
         */
        expMonth?: number;
        /**
         * The expiration year of the card
         */
        expYear?: number;
        /**
         * The card holder name
         */
        cardHolder?: string;
    } | null;
    /**
     * The sepa mandate data of the payment method, if the type is "sepa_debit"
     */
    readonly sepaDebit?: {
        /**
         * The iban of the sepa mandate
         */
        iban?: string;
        /**
         * The bic of the sepa mandate
         */
        bic?: string;
        /**
         * The mandate reference of the sepa mandate
         */
        mandateReference?: string;
        /**
         * The signing date of the sepa mandate
         */
        signingDate?: string;
    } | null;
    /**
     * The paypal data of the payment method, if the type is "paypal"
     */
    readonly paypal?: {
        /**
         * The email of the paypal account
         */
        email?: string;
        /**
         * The billing agreement id of the paypal account
         */
        billingAgreementId?: string;
    } | null;
};
export namespace PaymentMethod_BankAccountTransactionDetail {
    export enum gatewayName {
        WALLET = 'wallet',
        SEPA_DEBIT = 'sepa_debit',
        STRIPE = 'stripe',
        FAKE_PROVIDER = 'fake_provider',
        PAYPAL = 'paypal',
        BANK_TRANSFER = 'bank_transfer',
        GO_CARDLESS = 'go_cardless',
        TESTING = 'testing',
    }
    export enum type {
        SEPA_DEBIT = 'sepa_debit',
        CARD = 'card',
        PAYPAL = 'paypal',
        BANK_TRANSFER = 'bank_transfer',
        WALLET = 'wallet',
        FAKE_PROVIDER = 'fake_provider',
    }
    export enum status {
        ACTIVE = 'active',
        ACTION_REQUIRED = 'action_required',
        EXPIRED = 'expired',
        REVOKED = 'revoked',
        GATEWAY_UNCONFIGURED = 'gateway_unconfigured',
    }
}

