/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PaymentMethod_DunningDocumentList = {
    readonly id?: string;
    gatewayName?: PaymentMethod_DunningDocumentList.gatewayName;
    type?: PaymentMethod_DunningDocumentList.type | null;
    default?: boolean;
    status?: PaymentMethod_DunningDocumentList.status;
    enabled?: boolean;
    name?: string | null;
    readonly creationDate?: string;
    expirationDate?: string | null;
};
export namespace PaymentMethod_DunningDocumentList {
    export enum gatewayName {
        WALLET = 'wallet',
        SEPA_DEBIT = 'sepa_debit',
        STRIPE = 'stripe',
        FAKE_PROVIDER = 'fake_provider',
        PAYPAL = 'paypal',
        BANK_TRANSFER = 'bank_transfer',
        GO_CARDLESS = 'go_cardless',
        TESTING = 'testing',
    }
    export enum type {
        SEPA_DEBIT = 'sepa_debit',
        CARD = 'card',
        PAYPAL = 'paypal',
        BANK_TRANSFER = 'bank_transfer',
        WALLET = 'wallet',
        FAKE_PROVIDER = 'fake_provider',
    }
    export enum status {
        ACTIVE = 'active',
        ACTION_REQUIRED = 'action_required',
        EXPIRED = 'expired',
        REVOKED = 'revoked',
        GATEWAY_UNCONFIGURED = 'gateway_unconfigured',
    }
}

