<script setup lang="ts">
import type { DynamicDialogInstance } from 'primevue/dynamicdialogoptions';
import type { FormKitNode } from '@formkit/core';

const dialogRef = inject<Ref<DynamicDialogInstance | null>>('dialogRef');
const message = useMessage();
const { submitHandler, nodeHandler } = useForm();

const objectId = dialogRef.value?.data?.objectId;
const objectType = dialogRef.value?.data?.objectType as 'product' | 'product-price';
const assignedFeatureIds = dialogRef.value?.data?.assignedFeatureIds as string[];

if (!objectId || !objectType) {
  throw new Error('objectId and objectType are required');
}

const onSubmit = async (form: any, node: any) => {
  await submitHandler(
    {
      route: '/api/v1/entitlement/feature-assignments',
      method: 'POST',
      successHandler(response) {
        message.success('Feature wurde erfolgreich hinzugefügt');
        if (dialogRef?.value) {
          dialogRef.value.close(response.data);
        }
      },
      customErrorHandler() {
        message.error('Fehler beim Hinzufügen des Features');
      },
    },
    {
      ...form,
      // make any int, float or boolean values a string
      value: JSON.stringify(form.value),
      objectId,
      object: objectType,
    },
    node
  );
};

const featureValueOptions = (config: { label: string; value: string }, unit?: string) => {
  return config.map((config) => ({
    label: config.label + (unit ? ` (${unit})` : ''),
    value: config.value,
  }));
};

const notAssigned = (node: FormKitNode) => {
  return !assignedFeatureIds.includes(node.value);
};
</script>

<template>
  <FormKit v-slot="{ value }" type="form" :actions="false" @submit="onSubmit" @node="nodeHandler">
    <FormKit
      type="featureDropdown"
      name="feature"
      label="Feature"
      validation="required|notAssigned"
      placeholder="Feature auswählen"
      feature-type-field="type"
      feature-config-field="config"
      feature-unit-field="unit"
      :validation-rules="{ notAssigned }"
      :validation-messages="{
        notAssigned: 'Das Feature wurde bereits verwendet und kann nicht erneut hinzugefügt werden.',
      }"
    />

    <FormKit type="hidden" name="type" />
    <FormKit type="hidden" name="config" />
    <FormKit type="hidden" name="unit" />

    <div v-if="value.type === 'quantity' || value.type === 'custom'" key="quantityOrCustom">
      <FormKit
        type="primeDropdown"
        :options="featureValueOptions(value.config)"
        validation="required"
        name="value"
        :preserve="false"
        label="Wert"
        placeholder="Wert auswählen"
      />
    </div>

    <div v-if="value.type === 'range'" key="range">
      <FormKit
        type="primeInputNumber"
        validation="required"
        name="value"
        label="Wert"
        :preserve="false"
        :min="value.config.from"
        :max="value.config.to"
        :suffix="value.unit ? ` ${value.unit}` : ''"
        :value="0"
        :help="`Wert zwischen ${value.config.from || 'unlimitiert'} und ${value.config.to || 'unlimitiert'}`"
      />
    </div>

    <div v-if="value.type === 'switch'" key="switch">
      <FormKit type="primeInputSwitch" name="value" label="Wert" :value="false" :preserve="false" />
    </div>

    <div class="mt-3 border-t pt-3">
      <FormKit
        type="primeInputSwitch"
        name="alwaysAvailable"
        label="Zeitraum einschränken"
        help="Wendet das Feature nur in einem bestimmten Zeitraum auf neue Abonnements an"
      />

      <div v-if="value.alwaysAvailable" key="timeRange">
        <div class="flex gap-3 mt-3">
          <div class="w-full">
            <FormKit type="primeCalendar" name="validFrom" label="Gültig von" :nullable="true" :preserve="false" placeholder="Gültig von" />
          </div>
          <div class="w-full">
            <FormKit type="primeCalendar" name="validUntil" label="Gültig bis" :nullable="true" :preserve="false" placeholder="Gültig bis" />
          </div>
        </div>
      </div>
    </div>

    <div class="mt-3 border-t pt-3 border-b pb-3 mb-4">
      <FormKit
        type="primeInputSwitch"
        name="applyToExistingSubscriptions"
        label="Auf bestehende Abonnements anwenden"
        help="Wendet dieses Feature auf alle bestehenden Abonnements an, die mit diesem Produkt / Preis verbunden sind"
      />
    </div>

    <FormKit type="primeSubmitButton" submit-label="Feature zuweisen" button-icon="pi pi-plus" />
  </FormKit>
</template>

<style scoped lang="scss"></style>
