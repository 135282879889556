/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type Account = {
    readonly id?: string;
    readonly user?: string;
    readonly tenant?: string;
    readonly role?: Account.role;
    createdAt?: string;
    readonly deactivated?: boolean;
    avatar?: string | null;
    phone?: string | null;
    linkedin?: string | null;
    position?: string | null;
    /**
     * The date and time when the resource was last updated.
     */
    readonly updatedAt?: string;
    readonly roles?: Array<string>;
    readonly userIdentifier?: string;
    readonly password?: string | null;
    default?: string;
    /**
     * The first name of the user.
     */
    readonly firstName?: string;
    /**
     * The last name of the user.
     */
    readonly lastName?: string;
    /**
     * The email of the user.
     */
    readonly email?: string;
    readonly fullName?: string;
    readonly initial?: string;
    readonly gravatarUrl?: string | null;
};
export namespace Account {
    export enum role {
        ADMINISTRATOR = 'administrator',
        ACCOUNT = 'account',
        READ_ONLY = 'read-only',
    }
}

