/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Media_ExportJobDetail } from './Media_ExportJobDetail';
export type ExportJob_ExportJobDetail = {
    readonly id?: string;
    exportType?: ExportJob_ExportJobDetail.exportType;
    metadata?: Array<string>;
    readonly file?: Media_ExportJobDetail;
    readonly createdAt?: string;
};
export namespace ExportJob_ExportJobDetail {
    export enum exportType {
        DATEV_EXTIF_FULL = 'datev_extif_full',
        CUSTOMERS = 'customers',
        INVOICES = 'invoices',
    }
}

