/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Customer_DunningDocumentList } from './Customer_DunningDocumentList';
import type { Invoice_DunningDocumentList } from './Invoice_DunningDocumentList';
import type { Media_DunningDocumentList } from './Media_DunningDocumentList';
import type { Money_DunningDocumentList } from './Money_DunningDocumentList';
export type DunningDocument_DunningDocumentList = {
    /**
     * The id of the dunning document
     */
    readonly id?: string;
    /**
     * The customer of the dunning document
     */
    readonly customer?: Customer_DunningDocumentList;
    /**
     * The document date of the dunning document
     */
    readonly documentDate?: string;
    /**
     * The due date until when the invoice should be paid
     */
    readonly dueDate?: string;
    readonly media?: Media_DunningDocumentList | null;
    /**
     * The invoice for which this dunning document was created
     */
    invoice?: Invoice_DunningDocumentList;
    /**
     * The level of the current dunning document
     */
    readonly level?: number;
    /**
     * The type of the current dunning document
     */
    readonly type?: DunningDocument_DunningDocumentList.type;
    /**
     * The status of the current dunning document
     *
     * Possible values:
     * * `open`: The dunning document is open and can be paid.
     * * `cancelled`: The dunning document was cancelled and can not be paid anymore.
     * * `paid`: The dunning document was paid and can not be paid anymore. The dunning process for this invoice is stopped.
     */
    readonly status?: DunningDocument_DunningDocumentList.status;
    /**
     * The reason for the cancellation. This is used for internal purposes only.
     */
    readonly reason?: string | null;
    /**
     * The dunning fee in cents for this dunning document. This is only relevant for dunning documents of type "dunning".
     */
    readonly dunningFeeCents?: number | null;
    readonly createdAt?: string;
    readonly updatedAt?: string;
    readonly invoiceNumber?: string;
    readonly dunningFee?: (Money_DunningDocumentList | {
        /**
         * The amount as big integer, e.g. 1000 = 10.00 (precision = 2), 10000 = 10.000 (precision = 3)
         */
        readonly amount?: number;
        /**
         * The precision of the amount, which is the number of digits after the decimal separator, e.g. 2 = 10.00, 3 = 10.000
         */
        readonly precision?: number;
        /**
         * The currency code, e.g. EUR, USD, CHF
         */
        currency: string;
        /**
         * The formatted amount, e.g. 10.00 €, 10.00 $, 10.00 CHF
         */
        readonly i18n?: string;
        /**
         * Amount which can be used as input value, e.g. 10.00, 10.000.
         */
        inputValue: string;
    }) | null;
    readonly number?: string;
};
export namespace DunningDocument_DunningDocumentList {
    /**
     * The type of the current dunning document
     */
    export enum type {
        REMINDER = 'reminder',
        DUNNING = 'dunning',
    }
    /**
     * The status of the current dunning document
     *
     * Possible values:
     * * `open`: The dunning document is open and can be paid.
     * * `cancelled`: The dunning document was cancelled and can not be paid anymore.
     * * `paid`: The dunning document was paid and can not be paid anymore. The dunning process for this invoice is stopped.
     */
    export enum status {
        OPEN = 'open',
        PAID = 'paid',
        CANCELLED = 'cancelled',
    }
}

