/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BillingGroup_SubscriptionDetail } from './BillingGroup_SubscriptionDetail';
import type { CancellationReason_SubscriptionDetail } from './CancellationReason_SubscriptionDetail';
import type { ContractDetails_SubscriptionDetail } from './ContractDetails_SubscriptionDetail';
import type { Customer_SubscriptionDetail } from './Customer_SubscriptionDetail';
import type { CustomerAddress_SubscriptionDetail } from './CustomerAddress_SubscriptionDetail';
import type { PartnerResource_SubscriptionDetail } from './PartnerResource_SubscriptionDetail';
import type { PaymentMethod_SubscriptionDetail } from './PaymentMethod_SubscriptionDetail';
import type { Plan_PlanResource_SubscriptionDetail } from './Plan_PlanResource_SubscriptionDetail';
import type { SubscriptionItem_SubscriptionDetail } from './SubscriptionItem_SubscriptionDetail';
import type { TermPeriod_SubscriptionDetail } from './TermPeriod_SubscriptionDetail';
export type Subscription_SubscriptionDetail = {
    readonly id?: string;
    externalId?: string | null;
    /**
     * The payment method of the subscription. If null, the default payment method of the customer is used.
     */
    paymentMethod?: PaymentMethod_SubscriptionDetail | null;
    /**
     * The invoice address of the subscription. If null, the default invoice address of the customer is used.
     */
    invoiceAddress?: CustomerAddress_SubscriptionDetail | null;
    /**
     * The number of the subscription.
     */
    number?: string;
    customer?: Customer_SubscriptionDetail;
    /**
     * The plan this subscription is based on.
     */
    readonly plan?: Plan_PlanResource_SubscriptionDetail | null;
    /**
     * The name of the subscription. Defaults to the name of the plan. This will be used in the invoice for the grouping headers.
     */
    name?: string | null;
    /**
     * The selected items of the subscription. Can be charges or addons.
     */
    items?: Array<SubscriptionItem_SubscriptionDetail>;
    /**
     * The billing group defines the billing cycle of the subscription.
     */
    billingGroup?: BillingGroup_SubscriptionDetail | null;
    periods?: Array<TermPeriod_SubscriptionDetail>;
    /**
     * The status of the subscription.
     *
     * Possible values:
     * * `draft`: The subscription is in draft mode and not active yet.
     * * `active`: The subscription is active and will be billed.
     * * `paused`: The subscription is paused and will not be billed.
     * * `cancelled`: The subscription is cancelled and will not be billed anymore.
     * * `terminated`: The subscription is terminated and will not be billed anymore.
     * * `offer`: The subscription is an offer and waiting for acceptance.
     */
    readonly status?: Subscription_SubscriptionDetail.status;
    /**
     * The date the subscription was activated.
     */
    readonly activatedAt?: string | null;
    /**
     * The po number of the subscription.
     */
    poNumber?: string | null;
    /**
     * The contract details of the subscription.
     */
    contractDetails?: ContractDetails_SubscriptionDetail;
    /**
     * The date the trial period ends.
     */
    readonly trialEndsOn?: string | null;
    /**
     * Custom fields for the entity. The keys are the field names and the values are the field values. They need to be configured under "/custom-fields" in the API documentation.
     */
    customFields?: Array<string> | null;
    /**
     * The date and time when the resource was created.
     */
    readonly createdAt?: string;
    /**
     * The date and time when the resource was last updated.
     */
    readonly updatedAt?: string;
    /**
     * The payment method is null, we use the default payment method of the customer.
     */
    readonly defaultPaymentMethod?: boolean;
    /**
     * The invoice address is null, we use the default invoice address of the customer.
     */
    readonly defaultInvoiceAddress?: boolean;
    /**
     * The next billing date of the subscription.
     */
    readonly nextBillingDate?: string | null;
    /**
     * The last billing date of the subscription.
     */
    readonly lastBillingAt?: string | null;
    /**
     * The date the subscription will be cancelled.
     */
    readonly cancellationDate?: string | null;
    /**
     * The date when the subscription was cancelled.
     */
    readonly cancelledAt?: string | null;
    /**
     * The reason why the subscription was cancelled.
     */
    readonly cancellationReason?: CancellationReason_SubscriptionDetail | null;
    /**
     * Indicates if the subscription is pending for cancellation. If true, the subscription will be cancelled in the future and the cancellation could be revoked.
     */
    readonly isCancellationPending?: boolean;
    readonly nextPossibleCancellationDate?: Array<string> | null;
    /**
     * The partner of the customer.
     */
    readonly partner?: PartnerResource_SubscriptionDetail | null;
};
export namespace Subscription_SubscriptionDetail {
    /**
     * The status of the subscription.
     *
     * Possible values:
     * * `draft`: The subscription is in draft mode and not active yet.
     * * `active`: The subscription is active and will be billed.
     * * `paused`: The subscription is paused and will not be billed.
     * * `cancelled`: The subscription is cancelled and will not be billed anymore.
     * * `terminated`: The subscription is terminated and will not be billed anymore.
     * * `offer`: The subscription is an offer and waiting for acceptance.
     */
    export enum status {
        DRAFT = 'draft',
        ACTIVE = 'active',
        PAUSED = 'paused',
        OFFER = 'offer',
        CANCELLED = 'cancelled',
        TERMINATED = 'terminated',
    }
}

