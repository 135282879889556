import { navigateTo } from '#app/composables/router';
import { type ParsedQs } from 'qs';
import type { RouteLocationRaw } from '#vue-router';
import type { DataTableFilterDateRangeEvent, DataTableFilterItemEvent, DataTableSortMeta } from '~/composables/useTable';

export const convertSortByToOrderParams = (
  sortBy: Array<{
    key: string;
    order: 'desc' | 'asc';
  }>
) => {
  const params: { [key: string]: string } = {};

  sortBy.forEach(({ key, order }) => {
    // convert key (test.testFoo.bar) to testTestFooBar
    const formattedKey = key.split('.').reduce((acc, curr) => {
      return `${acc}${curr.charAt(0).toUpperCase()}${curr.slice(1)}`;
    });

    let orderKey = formattedKey;
    if (!orderKey.startsWith('order')) {
      orderKey = `${formattedKey.charAt(0).toUpperCase()}${formattedKey.slice(1)}`;
      orderKey = `order${orderKey}`;
    }

    params[orderKey] = order;
  });

  return params;
};

export const convertDataTableSortToApiOptions = <ApiCallbackParams>(options: DataTableSortMeta<ApiCallbackParams>[]) => {
  const sortParams: { key: string; order: 'asc' | 'desc' }[] =
    options.map((fields) => {
      const key = typeof fields.field === 'string' ? fields.field : String(fields.field);

      return {
        key,
        order: fields.order === 1 ? 'asc' : 'desc',
      };
    }) ?? [];

  return convertSortByToOrderParams(sortParams);
};

export const convertParamsToFilterParams = <ApiCallbackParams>(filters: DataTableFilterItemEvent<ApiCallbackParams>[]) => {
  const params: { [key: string]: DataTableFilterItemEvent<ApiCallbackParams>['value'] } = {};

  filters.forEach(({ key, value }) => {
    // convert key (test.testFoo.bar) to testTestFooBar
    const formattedKey = String(key)
      .split('.')
      .reduce((acc: any, curr: any) => {
        return `${acc}${curr.charAt(0).toUpperCase()}${curr.slice(1)}`;
      });

    const filterKey = Array.isArray(value) ? `${formattedKey}Array` : formattedKey;

    params[filterKey] = value;
  });

  return params;
};

export const convertParamsToDateFilterParams = <ApiCallbackParams>(filters: DataTableFilterDateRangeEvent<ApiCallbackParams>[]) => {
  const params: { [key: string]: string } = {};

  filters.forEach(({ key, value }) => {
    params[String(key)] = value.toISOString();
  });

  return params;
};

export const updateQueryParams = <ApiCallbackParams>(
  key: string,
  query: ParsedQs,
  hideFiltersInQuery: boolean,
  sort: DataTableSortMeta<ApiCallbackParams>[],
  filters: DataTableFilterItemEvent<ApiCallbackParams>[],
  dateFilters: DataTableFilterDateRangeEvent<ApiCallbackParams>[],
  page: number,
  rows: number,
  search?: string
) => {
  const parsedDateFilters = dateFilters.map(({ key, value }) => ({
    key,
    value: value.toISOString(),
  }));
  const parsedSortDate = sort.map(({ field, order }) => ({ field, order: order?.toString() }));

  const queryValue = {
    ...query,
    [key]: {
      ...(!hideFiltersInQuery && filters.length > 0 && { filters }),
      ...(parsedDateFilters.length > 0 && { dateFilters: parsedDateFilters }),
      ...(sort.length > 0 && { sort: parsedSortDate }),
      ...(search && { search }),
      itemsPerPage: rows.toString(),
      page: page.toString(),
    },
  };

  navigateTo({
    query: queryValue,
    // we are using QS to stringify the query (see app/app/router.options.ts), but nuxt does not know that the query type has changed,
    // so we are enforcing the type here to satisfy typescript.
    // if this causes more problems in the future, we should check if we can fix the typing or maybe create wrapper functions for navigateTo and useRoute
  } as unknown as RouteLocationRaw);
};

export const getQueryParamsByTableKey = (key: string, query: ParsedQs): ParsedQs | null => {
  const tableQuery = query?.[key];

  if (!tableQuery || typeof tableQuery !== 'object' || Array.isArray(tableQuery)) {
    return null;
  }

  return tableQuery;
};

export const getSearchQueryFromRoute = (tableQuery: ParsedQs | null) => {
  if (!tableQuery) {
    return null;
  }

  if ('search' in tableQuery && typeof tableQuery.search === 'string') {
    return tableQuery.search;
  }

  return null;
};

export const getPageQueryFromRoute = (tableQuery: ParsedQs | null): number => {
  if (!tableQuery) {
    return 1;
  }

  if (tableQuery?.page && typeof tableQuery.page === 'string') {
    return parseInt(tableQuery.page);
  }

  return 1;
};

export const getItemsPerPageQueryFromRoute = (tableQuery: ParsedQs | null, defaultItemsPerPage: number = 10): number => {
  if (!tableQuery) {
    return defaultItemsPerPage;
  }

  if (tableQuery?.itemsPerPage && typeof tableQuery.itemsPerPage === 'string') {
    return parseInt(tableQuery.itemsPerPage);
  }

  return defaultItemsPerPage;
};

export const getSortQueryFromRoute = <ApiCallbackParams>(tableQuery: ParsedQs | null): DataTableSortMeta<ApiCallbackParams>[] | null => {
  const multiSortMeta: DataTableSortMeta<ApiCallbackParams>[] = [];

  if (!tableQuery || !tableQuery?.sort || !Array.isArray(tableQuery.sort)) {
    return null;
  }

  tableQuery.sort.forEach((sortField) => {
    if (typeof sortField === 'string') {
      return;
    }

    const { field, order } = sortField;

    if (typeof field !== 'string' || typeof order !== 'string') {
      return;
    }

    const orderInt = parseInt(order);

    if (orderInt !== 0 && orderInt !== 1 && orderInt !== -1) {
      return;
    }

    // todo: validate field
    multiSortMeta.push({ field: field as DataTableSortMeta<ApiCallbackParams>['field'], order: orderInt });
  });

  return multiSortMeta;
};

export const getFiltersFromRoute = <ApiCallbackParams>(tableQuery: ParsedQs | null): DataTableFilterItemEvent<ApiCallbackParams>[] | null => {
  const filters: DataTableFilterItemEvent<ApiCallbackParams>[] = [];

  if (!tableQuery || !tableQuery?.filters || !Array.isArray(tableQuery.filters)) {
    return null;
  }

  tableQuery.filters.forEach((filter) => {
    if (typeof filter === 'string') {
      return;
    }

    const { key, value } = filter;

    if (typeof key !== 'string' || (typeof value !== 'string' && !Array.isArray(value))) {
      return;
    }

    if (typeof value === 'string' && value.trim().length === 0) {
      return;
    }

    // todo: key should be validated based on keyof <K>['key']
    filters.push({
      key: key as DataTableFilterItemEvent<ApiCallbackParams>['key'],
      value: value as DataTableFilterItemEvent<ApiCallbackParams>['value'],
    });
  });

  return filters.length > 0 ? filters : null;
};

export const getDateFiltersFromRoute = <ApiCallbackParams>(tableQuery: ParsedQs | null): DataTableFilterDateRangeEvent<ApiCallbackParams>[] | null => {
  const dateFilters: DataTableFilterDateRangeEvent<ApiCallbackParams>[] = [];

  if (!tableQuery || !tableQuery?.dateFilters || !Array.isArray(tableQuery.dateFilters)) {
    return null;
  }

  tableQuery.dateFilters.forEach((dateFilter) => {
    if (typeof dateFilter === 'string') {
      return;
    }

    const { key, value } = dateFilter;

    if (typeof key !== 'string' || typeof value !== 'string') {
      return;
    }

    // todo: key should be validated based on keyof <K>['key']
    dateFilters.push({ key: key as DataTableFilterDateRangeEvent<ApiCallbackParams>['key'], value: new Date(value) });
  });

  return dateFilters.length > 0 ? dateFilters : null;
};


export const addColspanToHeader = () => {
  const groupHeaders = document.querySelectorAll('table tbody .p-rowgroup-header td');
  for (const item of groupHeaders) {
    const newColspan = parseInt(item.colSpan) + 1;
    item.colSpan = newColspan;
  }
};
