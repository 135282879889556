/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type Subscription_CancelSubscription_CancelSubscription = {
    /**
     * The type how the cancellation date should be interpreted.
     */
    cancellationDateType?: Subscription_CancelSubscription_CancelSubscription.cancellationDateType;
    /**
     * The date when the subscription should be cancelled. Required if the type is `custom`.
     */
    cancellationDate?: string | null;
    /**
     * If the confirmation email should be sent to the customer.
     */
    sendConfirmationEmail?: boolean;
    /**
     * The reason code why the subscription should be cancelled.
     */
    reason?: string | null;
};
export namespace Subscription_CancelSubscription_CancelSubscription {
    /**
     * The type how the cancellation date should be interpreted.
     */
    export enum cancellationDateType {
        IMMEDIATE = 'immediate',
        NEXT_POSSIBLE = 'next_possible',
        CUSTOM = 'custom',
    }
}

