/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Customer_BankAccountTransactionDetail } from './Customer_BankAccountTransactionDetail';
import type { InvoiceAddress_BankAccountTransactionDetail } from './InvoiceAddress_BankAccountTransactionDetail';
import type { PaymentMethod_BankAccountTransactionDetail } from './PaymentMethod_BankAccountTransactionDetail';
export type Invoice_BankAccountTransactionDetail = {
    /**
     * The unique identifier of the invoice.
     */
    readonly id?: string;
    /**
     * The customer of the invoice.
     */
    customer?: Customer_BankAccountTransactionDetail;
    /**
     * The payment method which should be used to fullfill the payment of the invoice.
     */
    paymentMethod?: PaymentMethod_BankAccountTransactionDetail | null;
    /**
     * The type of the invoice.
     */
    type?: Invoice_BankAccountTransactionDetail.type;
    /**
     * The currency code of the invoice. If multiple currency codes will be used in the positions, they will be converted to that base currency.
     */
    currencyCode?: string;
    /**
     * The document id of the invoice. This will be generated, when the invoice will be finalized.
     */
    readonly number?: string | null;
    readonly status?: Invoice_BankAccountTransactionDetail.status;
    readonly finalizationDate?: string | null;
    dueDate?: string | null;
    readonly dunningLevel?: number;
    readonly dunningStatus?: Invoice_BankAccountTransactionDetail.dunningStatus;
    readonly payDate?: string | null;
    /**
     * The invoice address of the invoice.
     */
    readonly invoiceAddress?: InvoiceAddress_BankAccountTransactionDetail | null;
    /**
     * The notice of the invoice.
     * @deprecated
     */
    internalNote?: string | null;
    /**
     * The net amount of the invoice.
     */
    readonly netAmount?: {
        /**
         * The amount as big integer, e.g. 1000 = 10.00 (precision = 2), 10000 = 10.000 (precision = 3)
         */
        readonly amount?: number;
        /**
         * The precision of the amount, which is the number of digits after the decimal separator, e.g. 2 = 10.00, 3 = 10.000
         */
        readonly precision?: number;
        /**
         * The currency code, e.g. EUR, USD, CHF
         */
        currency: string;
        /**
         * The formatted amount, e.g. 10.00 €, 10.00 $, 10.00 CHF
         */
        readonly i18n?: string;
        /**
         * Amount which can be used as input value, e.g. 10.00, 10.000.
         */
        inputValue: string;
    };
    readonly grossAmount?: {
        /**
         * The amount as big integer, e.g. 1000 = 10.00 (precision = 2), 10000 = 10.000 (precision = 3)
         */
        readonly amount?: number;
        /**
         * The precision of the amount, which is the number of digits after the decimal separator, e.g. 2 = 10.00, 3 = 10.000
         */
        readonly precision?: number;
        /**
         * The currency code, e.g. EUR, USD, CHF
         */
        currency: string;
        /**
         * The formatted amount, e.g. 10.00 €, 10.00 $, 10.00 CHF
         */
        readonly i18n?: string;
        /**
         * Amount which can be used as input value, e.g. 10.00, 10.000.
         */
        inputValue: string;
    };
    readonly taxAmount?: {
        /**
         * The amount as big integer, e.g. 1000 = 10.00 (precision = 2), 10000 = 10.000 (precision = 3)
         */
        readonly amount?: number;
        /**
         * The precision of the amount, which is the number of digits after the decimal separator, e.g. 2 = 10.00, 3 = 10.000
         */
        readonly precision?: number;
        /**
         * The currency code, e.g. EUR, USD, CHF
         */
        currency: string;
        /**
         * The formatted amount, e.g. 10.00 €, 10.00 $, 10.00 CHF
         */
        readonly i18n?: string;
        /**
         * Amount which can be used as input value, e.g. 10.00, 10.000.
         */
        inputValue: string;
    };
    /**
     * Custom fields for the entity. The keys are the field names and the values are the field values. They need to be configured under "/custom-fields" in the API documentation.
     */
    customFields?: Array<string> | null;
    readonly unpaidAmount?: {
        /**
         * The amount as big integer, e.g. 1000 = 10.00 (precision = 2), 10000 = 10.000 (precision = 3)
         */
        readonly amount?: number;
        /**
         * The precision of the amount, which is the number of digits after the decimal separator, e.g. 2 = 10.00, 3 = 10.000
         */
        readonly precision?: number;
        /**
         * The currency code, e.g. EUR, USD, CHF
         */
        currency: string;
        /**
         * The formatted amount, e.g. 10.00 €, 10.00 $, 10.00 CHF
         */
        readonly i18n?: string;
        /**
         * Amount which can be used as input value, e.g. 10.00, 10.000.
         */
        inputValue: string;
    };
};
export namespace Invoice_BankAccountTransactionDetail {
    /**
     * The type of the invoice.
     */
    export enum type {
        TYPE_INVOICE = 'TYPE_INVOICE',
        TYPE_CREDIT = 'TYPE_CREDIT',
        TYPE_REFUND = 'TYPE_REFUND',
        TYPE_REMINDER = 'TYPE_REMINDER',
        TYPE_CANCEL = 'TYPE_CANCEL',
        TYPE_DUNNING = 'TYPE_DUNNING',
    }
    export enum status {
        STATUS_DRAFT = 'STATUS_DRAFT',
        STATUS_PAID = 'STATUS_PAID',
        STATUS_CANCELLED = 'STATUS_CANCELLED',
        STATUS_CLOSED = 'STATUS_CLOSED',
        STATUS_REFUNDED = 'STATUS_REFUNDED',
        STATUS_REMINDED = 'STATUS_REMINDED',
        STATUS_UNPAID = 'STATUS_UNPAID',
        STATUS_NEW = 'STATUS_NEW',
        STATUS_FINALIZING = 'STATUS_FINALIZING',
    }
    export enum dunningStatus {
        REMINDER = 'reminder',
        DUNNING = 'dunning',
        NONE = 'none',
    }
}

