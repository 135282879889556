/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PaymentMethod_PaymentMethodLinkRequest = {
    /**
     * The customer id
     */
    customer: string;
    /**
     * The allowed payment method types. If none is provided, all available will be shown.
     */
    allowedPaymentMethodTypes?: PaymentMethod_PaymentMethodLinkRequest.allowedPaymentMethodTypes;
    /**
     * Whether to send an email to the customer with the link.
     */
    sendEmail?: boolean;
};
export namespace PaymentMethod_PaymentMethodLinkRequest {
    /**
     * The allowed payment method types. If none is provided, all available will be shown.
     */
    export enum allowedPaymentMethodTypes {
        SEPA_DEBIT = 'sepa_debit',
        CARD = 'card',
        PAYPAL = 'paypal',
        BANK_TRANSFER = 'bank_transfer',
        WALLET = 'wallet',
    }
}

