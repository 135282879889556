/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Tax } from './Tax';
export type TaxGroup = {
    readonly id?: string;
    /**
     * The name of the tax group which will be displayed only in the UI
     */
    internalDescription: string;
    /**
     * Defines if reverse charge will be applicated or not
     */
    reverseChargeType: TaxGroup.reverseChargeType;
    internalUse?: boolean;
    type: TaxGroup.type;
    taxes?: Array<Tax>;
    readonly reverseChargeTax?: string | null;
    readonly reverseChargeIntraEuSupplyTax?: string | null;
    readonly reverseCharge?: boolean;
    readonly reverseChargeIntraEu?: boolean;
    readonly currentValidTaxes?: Array<string>;
    readonly tenantId?: string;
};
export namespace TaxGroup {
    /**
     * Defines if reverse charge will be applicated or not
     */
    export enum reverseChargeType {
        REVERSE_CHARGE_DEACTIVATED = 'REVERSE_CHARGE_DEACTIVATED',
        REVERSE_CHARGE = 'REVERSE_CHARGE',
        REVERSE_CHARGE_INTRA_EU_SUPPLY = 'REVERSE_CHARGE_INTRA_EU_SUPPLY',
    }
    export enum type {
        STANDARD = 'standard',
        REDUCED = 'reduced',
    }
}

