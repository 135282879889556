/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type SubscriptionPhaseStreamItem_SubscriptionPhaseDetail = {
    type?: SubscriptionPhaseStreamItem_SubscriptionPhaseDetail.type;
    data?: Array<string>;
    timestamp?: string;
    children?: Array<SubscriptionPhaseStreamItem_SubscriptionPhaseDetail>;
};
export namespace SubscriptionPhaseStreamItem_SubscriptionPhaseDetail {
    export enum type {
        SUBSCRIPTION_CONTRACT_START = 'subscription.contract_start',
        SUBSCRIPTION_CONTRACT_END = 'subscription.contract_end',
        SUBSCRIPTION_BILLING_START = 'subscription.billing_start',
        SUBSCRIPTION_CREATED = 'subscription.created',
        PHASE_START = 'phase.start',
        PRODUCT_ADD = 'product.add',
        PRODUCT_REMOVE = 'product.remove',
        PRODUCT_QUANTITY_CHANGE = 'product.quantity_change',
        PRODUCT_NAME_CHANGE = 'product.name_change',
        PRODUCT_DESCRIPTION_CHANGE = 'product.description_change',
        PRODUCT_PRICE_CHANGE = 'product.price_change',
        PRODUCT_DISCOUNT_ADD = 'product.discount.add',
        PRODUCT_DISCOUNT_CHANGE = 'product.discount.change',
        PRODUCT_DISCOUNT_REMOVED = 'product.discount.removed',
        CONTRACT_TERM_RESET = 'contract_term.reset',
        PRICE_CHANGE_APPLY_TRIAL = 'price_change.apply_trial',
        PRICE_CHANGE_PAY_IN_ADVANCE = 'price_change.pay_in_advance',
        PRICE_CHANGE_PRO_RATA = 'price_change.pro_rata',
        PRICE_CHANGE_FREE_UNITS = 'price_change.free_units',
        PRICE_CHANGE_BILLING_INTERVAL = 'price_change.billing_interval',
        PRICE_CHANGE_CURRENCY_CODE = 'price_change.currency_code',
        PRICE_CHANGE_AMOUNT = 'price_change.amount',
        PRICE_CHANGE_PERCENTAGE_FIXED_AMOUNT = 'price_change.percentage.fixed_amount',
        PRICE_CHANGE_PERCENTAGE_PERCENTAGE = 'price_change.percentage.percentage',
        PRICE_CHANGE_PERCENTAGE_FREE_UNITS_PER_EVENT = 'price_change.percentage.free_units_per_event',
        PRICE_CHANGE_PERCENTAGE_FREE_UNITS_PER_BILLING_INTERVAL = 'price_change.percentage.free_units_per_billing_interval',
        PRICE_CHANGE_TYPE = 'price_change.type',
        PRICE_CHANGE_TIERS = 'price_change.tiers',
        CHARGE_CHANGES = 'charge.changes',
    }
}

