/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type Account_AccountDetailsAdmin = {
    readonly id?: string;
    readonly user?: string;
    readonly role?: Account_AccountDetailsAdmin.role;
    createdAt?: string;
    readonly deactivated?: boolean;
};
export namespace Account_AccountDetailsAdmin {
    export enum role {
        ADMINISTRATOR = 'administrator',
        ACCOUNT = 'account',
        READ_ONLY = 'read-only',
    }
}

