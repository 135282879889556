/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type Account_SetAccountRole = {
    /**
     * The role to set for the account.
     */
    role: Account_SetAccountRole.role | null;
};
export namespace Account_SetAccountRole {
    /**
     * The role to set for the account.
     */
    export enum role {
        READ_ONLY = 'read-only',
        ADMINISTRATOR = 'administrator',
        ACCOUNT = 'account',
    }
}

