/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Billing_SubscriptionDetail } from './Billing_SubscriptionDetail';
import type { MeasurementValue_SubscriptionDetail } from './MeasurementValue_SubscriptionDetail';
import type { Plan_PlanResource_SubscriptionDetail } from './Plan_PlanResource_SubscriptionDetail';
import type { PricePlan_SubscriptionDetail } from './PricePlan_SubscriptionDetail';
import type { Product_SubscriptionDetail } from './Product_SubscriptionDetail';
import type { SubscriptionItemAppliedDiscount_SubscriptionDetail } from './SubscriptionItemAppliedDiscount_SubscriptionDetail';
import type { TermPeriod_SubscriptionDetail } from './TermPeriod_SubscriptionDetail';
export type SubscriptionItem_SubscriptionDetail = {
    readonly id?: string;
    /**
     * The parent item of the item. If this is null, the item is a root item.
     */
    readonly parent?: SubscriptionItem_SubscriptionDetail | null;
    /**
     * The plan this item is based on.
     */
    readonly plan?: Plan_PlanResource_SubscriptionDetail | null;
    /**
     * Usage values during the subscription item period.
     */
    readonly measurementValues?: Array<MeasurementValue_SubscriptionDetail>;
    /**
     * The billing details of the item.
     */
    readonly billing?: Billing_SubscriptionDetail;
    /**
     * The name of the item which will be used in the invoice. The name is translated based on the customer's locale.
     */
    readonly name?: string;
    /**
     * The description of the item which will be used in the invoice. The description is translated based on the customer's locale.
     */
    readonly description?: string | null;
    /**
     * Further information defines additional information for the item which will be displayed in the invoice.
     */
    readonly furtherInformation?: string | null;
    /**
     * The price plan of the item, which defines the price and billing interval.
     */
    readonly pricePlan?: PricePlan_SubscriptionDetail | null;
    /**
     * The status of the item.
     *
     * Possible values:
     * * `active`: The item is active and will be billed.
     * * `canceled`: The item is canceled and will be billed until the end of the contract.
     * * `pending`: The item is pending and will be billed after the contract start.
     * * `terminated`: The item is terminated and will not be billed.
     */
    readonly status?: SubscriptionItem_SubscriptionDetail.status;
    /**
     * The product of the item.
     */
    readonly product?: Product_SubscriptionDetail | null;
    readonly appliedDiscounts?: Array<SubscriptionItemAppliedDiscount_SubscriptionDetail>;
    periods?: Array<TermPeriod_SubscriptionDetail>;
    /**
     * The date the subscription item was activated.
     */
    readonly activatedAt?: string | null;
    /**
     * The type of the item.
     *
     * Possible values:
     * * `option`: The item is an product set option.
     * * `charge`: The item is a charge.
     * * `plan-product`: The item is a plan product.
     * * `product`: The item is a product, and not created from a plan.
     */
    readonly type?: SubscriptionItem_SubscriptionDetail.type;
    /**
     * The current measurement value of the item.
     */
    readonly currentMeasurementValue?: MeasurementValue_SubscriptionDetail | null;
    /**
     * Is the period inherited from the subscription?
     */
    readonly inheritedPeriod?: boolean;
};
export namespace SubscriptionItem_SubscriptionDetail {
    /**
     * The status of the item.
     *
     * Possible values:
     * * `active`: The item is active and will be billed.
     * * `canceled`: The item is canceled and will be billed until the end of the contract.
     * * `pending`: The item is pending and will be billed after the contract start.
     * * `terminated`: The item is terminated and will not be billed.
     */
    export enum status {
        ACTIVE = 'active',
        CANCELED = 'canceled',
        PENDING = 'pending',
        TERMINATED = 'terminated',
    }
    /**
     * The type of the item.
     *
     * Possible values:
     * * `option`: The item is an product set option.
     * * `charge`: The item is a charge.
     * * `plan-product`: The item is a plan product.
     * * `product`: The item is a product, and not created from a plan.
     */
    export enum type {
        ADDON = 'addon',
        CHARGE = 'charge',
        PLAN_PRODUCT = 'plan-product',
        PRODUCT = 'product',
    }
}

