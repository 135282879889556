/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CostCentre_CostCentreWrite = {
    /**
     * The name of the cost centre, which is displayed in the UI
     */
    name: string;
    /**
     * The type of the cost centre, which is used for exports
     */
    type: CostCentre_CostCentreWrite.type;
    /**
     * The status of the cost centre
     *
     * Possible values:
     * * `active`: The cost centre is active and can be used and referenced
     * * `archived`: The cost centre is archived and cannot be referenced anymore. It also disappears from the UI.
     */
    status: CostCentre_CostCentreWrite.status;
};
export namespace CostCentre_CostCentreWrite {
    /**
     * The type of the cost centre, which is used for exports
     */
    export enum type {
        KOST1 = 'KOST1',
        KOST2 = 'KOST2',
    }
    /**
     * The status of the cost centre
     *
     * Possible values:
     * * `active`: The cost centre is active and can be used and referenced
     * * `archived`: The cost centre is archived and cannot be referenced anymore. It also disappears from the UI.
     */
    export enum status {
        ACTIVE = 'active',
        ARCHIVED = 'archived',
    }
}

