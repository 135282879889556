/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type Offer_AddOfferRecipientInput_AddOfferRecipient = {
    /**
     * The first name of the recipient
     */
    firstName?: string;
    /**
     * The last name of the recipient
     */
    lastName?: string;
    /**
     * The email of the recipient. This will be used to send the email.
     */
    email?: string;
    /**
     * The role of the recipient
     *
     * Possible values:
     * * `read`: The recipient can read the offer
     * * `sign`: The recipient can read and sign the offer
     */
    role?: Offer_AddOfferRecipientInput_AddOfferRecipient.role;
    /**
     * Whether to send an invitation email to the recipient. Otherwise, you have to inform the recipient manually.
     */
    sendEmail?: boolean;
};
export namespace Offer_AddOfferRecipientInput_AddOfferRecipient {
    /**
     * The role of the recipient
     *
     * Possible values:
     * * `read`: The recipient can read the offer
     * * `sign`: The recipient can read and sign the offer
     */
    export enum role {
        READ = 'read',
        SIGN = 'sign',
    }
}

