/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CostCentre_CouponDetail = {
    readonly id?: string;
    /**
     * The name of the cost centre, which is displayed in the UI
     */
    name: string;
    /**
     * The code of the cost centre, which is used for exports
     */
    code?: string;
    /**
     * The type of the cost centre, which is used for exports
     */
    type: CostCentre_CouponDetail.type;
    /**
     * The date and time when the cost centre was created
     */
    readonly createdAt?: string;
    /**
     * The date and time when the cost centre was last updated
     */
    readonly updatedAt?: string;
};
export namespace CostCentre_CouponDetail {
    /**
     * The type of the cost centre, which is used for exports
     */
    export enum type {
        KOST1 = 'KOST1',
        KOST2 = 'KOST2',
    }
}

