/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type DocumentTemplate_DocumentTemplateDto_DocumentTemplateWrite = {
    /**
     * The document type. Currently supported: invoice, subscription_cancellation, dunning
     */
    type: DocumentTemplate_DocumentTemplateDto_DocumentTemplateWrite.type;
    name: string;
    /**
     * ISO 639-1 language code. The template is selected based on the customer language. If no template for language found, we will fallback to german (de).
     */
    language: DocumentTemplate_DocumentTemplateDto_DocumentTemplateWrite.language;
    title: string;
    introduction: string;
    closing: string;
    /**
     * Displayed in the header right side.
     */
    information?: Array<{
        key?: string;
        value?: string;
    }>;
};
export namespace DocumentTemplate_DocumentTemplateDto_DocumentTemplateWrite {
    /**
     * The document type. Currently supported: invoice, subscription_cancellation, dunning
     */
    export enum type {
        INVOICE = 'invoice',
        SUBSCRIPTION_CANCELLATION = 'subscription_cancellation',
        DUNNING = 'dunning',
    }
    /**
     * ISO 639-1 language code. The template is selected based on the customer language. If no template for language found, we will fallback to german (de).
     */
    export enum language {
        DE = 'de',
        EN = 'en',
    }
}

