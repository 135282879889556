/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CostCentre_ProductRead } from './CostCentre_ProductRead';
import type { Measurement_ProductRead } from './Measurement_ProductRead';
import type { ProductFamily_ProductRead } from './ProductFamily_ProductRead';
import type { TaxGroup_ProductRead } from './TaxGroup_ProductRead';
export type Product_ProductRead = {
    /**
     * The unique identifier of the product.
     */
    readonly id?: string;
    /**
     * A product family groups multiple products together.
     */
    productFamilies: Array<ProductFamily_ProductRead>;
    /**
     * The name of the product based on the current tenant language. This will be displayed on customer communication.
     */
    name?: string;
    /**
     * The description of the product based on the current tenant language.
     */
    description?: string | null;
    /**
     * Internal name of the product, to differentiate between products with the same name. This will shown in web-app lists, selections and reports.
     */
    internalName?: string | null;
    /**
     * Internal product number
     */
    number: string | null;
    /**
     * The type of the product.
     * - `product`: recurring billed product
     * - `charge`: one-time billing
     * - `plan`: plan specific product, which cannot be used as a normal product. Will be filtered out in any product lists.
     *
     */
    type: Product_ProductRead.type;
    /**
     * The measurement that is used for the price plan. This could define the quantity or a metered usage.
     */
    measurement: Measurement_ProductRead;
    /**
     * Defines when the product should be displayed in the invoice.
     * - `always`: The product is always displayed in the invoice.
     * - `only_if_charged`: The product is only displayed in the invoice if it is charged.
     *
     */
    invoiceVisibility?: Product_ProductRead.invoiceVisibility;
    /**
     * The cost centre is used for accounting exports.
     */
    costCentre?: CostCentre_ProductRead | null;
    createdAt?: string;
    updatedAt?: string;
    /**
     * The tax group that is used for the product.
     */
    taxGroup: TaxGroup_ProductRead;
    /**
     * Defines if the product is archived and should not be used anymore.
     */
    isArchived?: boolean;
    /**
     * Custom fields for the entity. The keys are the field names and the values are the field values. They need to be configured under "/custom-fields" in the API documentation.
     */
    customFields?: Array<string>;
};
export namespace Product_ProductRead {
    /**
     * The type of the product.
     * - `product`: recurring billed product
     * - `charge`: one-time billing
     * - `plan`: plan specific product, which cannot be used as a normal product. Will be filtered out in any product lists.
     *
     */
    export enum type {
        PRODUCT = 'product',
        CHARGE = 'charge',
        PLAN = 'plan',
    }
    /**
     * Defines when the product should be displayed in the invoice.
     * - `always`: The product is always displayed in the invoice.
     * - `only_if_charged`: The product is only displayed in the invoice if it is charged.
     *
     */
    export enum invoiceVisibility {
        ALWAYS = 'always',
        ONLY_IF_CHARGED = 'only_if_charged',
    }
}

