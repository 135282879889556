/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CheckoutStyle_checkout_settings_read } from './CheckoutStyle_checkout_settings_read';
export type CheckoutSettings_checkout_settings_read = {
    readonly id?: string;
    style?: CheckoutStyle_checkout_settings_read;
    technicalName?: string;
    /**
     * @deprecated
     */
    allowedPaymentMethods?: CheckoutSettings_checkout_settings_read.allowedPaymentMethods;
    /**
     * If true, the customer can enter a coupon code in the checkout.
     * @deprecated
     */
    couponsAllowed?: boolean;
    /**
     * The customer will be redirected to that url, when the checkout process is canceled by customer.
     */
    cancelUrl?: string | null;
    /**
     * The customer will be redirected to that url, when the checkout process is finished in any other state than cancel.
     */
    returnUrl?: string | null;
    /**
     * This conditions link will be shown in the checkout.
     * @deprecated
     */
    conditionsLink?: string | null;
    /**
     * This privacy url will be shown in the checkout.
     * @deprecated
     */
    privacyUrl?: string | null;
    /**
     * This logo will be shown in the checkout and on email templates.
     * @deprecated
     */
    logoUrl?: string | null;
    /**
     * This payment success url will be used, to redirect the customer to the url after a successful payment. This url will only be used, when it is not an initial cart payment, or from our hosted checkout & customer portal.
     */
    paymentSuccessUrl?: string | null;
    /**
     * This payment failure url will be used, to redirect the customer to the url after a failed payment. This url will only be used, when it is not an initial cart payment, or from our hosted checkout & customer portal.
     */
    paymentFailureUrl?: string | null;
    /**
     * This payment cancel url will be used, to redirect the customer to the url after a canceled payment. This url will only be used, when it is not an initial cart payment, or from our hosted checkout & customer portal.
     */
    paymentCancelUrl?: string | null;
    /**
     * This payment pending url will be used, to redirect the customer to the url after a pending payment. This url will only be used, when it is not an initial cart payment, or from our hosted checkout & customer portal.
     */
    paymentPendingUrl?: string | null;
    readonly createdAt?: string;
};
export namespace CheckoutSettings_checkout_settings_read {
    export enum allowedPaymentMethods {
        SEPA_DEBIT = 'sepa_debit',
        CARD = 'card',
        PAYPAL = 'paypal',
        BANK_TRANSFER = 'bank_transfer',
        WALLET = 'wallet',
    }
}

