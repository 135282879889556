/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BillingGroup_SubscriptionRead } from './BillingGroup_SubscriptionRead';
import type { ContractDetails_SubscriptionRead } from './ContractDetails_SubscriptionRead';
import type { Customer_SubscriptionRead } from './Customer_SubscriptionRead';
import type { CustomerAddress_SubscriptionRead } from './CustomerAddress_SubscriptionRead';
import type { PartnerResource_SubscriptionRead } from './PartnerResource_SubscriptionRead';
import type { PaymentMethod_SubscriptionRead } from './PaymentMethod_SubscriptionRead';
import type { Plan_PlanResource_SubscriptionRead } from './Plan_PlanResource_SubscriptionRead';
import type { TermPeriod_SubscriptionRead } from './TermPeriod_SubscriptionRead';
export type Subscription_SubscriptionRead = {
    readonly id?: string;
    externalId?: string | null;
    /**
     * The payment method of the subscription. If null, the default payment method of the customer is used.
     */
    paymentMethod?: PaymentMethod_SubscriptionRead | null;
    /**
     * The invoice address of the subscription. If null, the default invoice address of the customer is used.
     */
    invoiceAddress?: CustomerAddress_SubscriptionRead | null;
    /**
     * The number of the subscription.
     */
    number?: string;
    customer?: Customer_SubscriptionRead;
    /**
     * The plan this subscription is based on.
     */
    readonly plan?: Plan_PlanResource_SubscriptionRead | null;
    /**
     * The name of the subscription. Defaults to the name of the plan. This will be used in the invoice for the grouping headers.
     */
    name?: string | null;
    /**
     * The billing group defines the billing cycle of the subscription.
     */
    billingGroup?: BillingGroup_SubscriptionRead | null;
    periods?: Array<TermPeriod_SubscriptionRead>;
    /**
     * The status of the subscription.
     *
     * Possible values:
     * * `draft`: The subscription is in draft mode and not active yet.
     * * `active`: The subscription is active and will be billed.
     * * `paused`: The subscription is paused and will not be billed.
     * * `cancelled`: The subscription is cancelled and will not be billed anymore.
     * * `terminated`: The subscription is terminated and will not be billed anymore.
     * * `offer`: The subscription is an offer and waiting for acceptance.
     */
    readonly status?: Subscription_SubscriptionRead.status;
    /**
     * The date the subscription was activated.
     */
    readonly activatedAt?: string | null;
    /**
     * The po number of the subscription.
     */
    poNumber?: string | null;
    /**
     * The contract details of the subscription.
     */
    contractDetails?: ContractDetails_SubscriptionRead;
    /**
     * The date the trial period ends.
     */
    readonly trialEndsOn?: string | null;
    /**
     * Custom fields for the entity. The keys are the field names and the values are the field values. They need to be configured under "/custom-fields" in the API documentation.
     */
    customFields?: Array<string> | null;
    /**
     * The date and time when the resource was created.
     */
    readonly createdAt?: string;
    /**
     * The date and time when the resource was last updated.
     */
    readonly updatedAt?: string;
    /**
     * The payment method is null, we use the default payment method of the customer.
     */
    readonly defaultPaymentMethod?: boolean;
    /**
     * The invoice address is null, we use the default invoice address of the customer.
     */
    readonly defaultInvoiceAddress?: boolean;
    /**
     * The next billing date of the subscription.
     */
    readonly nextBillingDate?: string | null;
    /**
     * The last billing date of the subscription.
     */
    readonly lastBillingAt?: string | null;
    /**
     * The date the subscription will be cancelled.
     */
    readonly cancellationDate?: string | null;
    /**
     * The date when the subscription was cancelled.
     */
    readonly cancelledAt?: string | null;
    /**
     * Indicates if the subscription is pending for cancellation. If true, the subscription will be cancelled in the future and the cancellation could be revoked.
     */
    readonly isCancellationPending?: boolean;
    readonly nextPossibleCancellationDate?: Array<string> | null;
    /**
     * The partner of the customer.
     */
    readonly partner?: PartnerResource_SubscriptionRead | null;
};
export namespace Subscription_SubscriptionRead {
    /**
     * The status of the subscription.
     *
     * Possible values:
     * * `draft`: The subscription is in draft mode and not active yet.
     * * `active`: The subscription is active and will be billed.
     * * `paused`: The subscription is paused and will not be billed.
     * * `cancelled`: The subscription is cancelled and will not be billed anymore.
     * * `terminated`: The subscription is terminated and will not be billed anymore.
     * * `offer`: The subscription is an offer and waiting for acceptance.
     */
    export enum status {
        DRAFT = 'draft',
        ACTIVE = 'active',
        PAUSED = 'paused',
        OFFER = 'offer',
        CANCELLED = 'cancelled',
        TERMINATED = 'terminated',
    }
}

