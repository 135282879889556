/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type DiscountInput_OrderSubscriptionProducts = {
    /**
     * The coupon of the discount. If none provided, the discount is applied without a coupon relation, which means that the discount is not visible in any statistics.
     */
    coupon: string | null;
    /**
     * The fixed amount of the discount. This is only applicable for fixed amount discounts with type "fixed_amount".
     */
    fixedAmount?: Array<string> | null;
    /**
     * The frequency how often the discount is applied.
     */
    frequency?: DiscountInput_OrderSubscriptionProducts.frequency;
    /**
     * The frequency interval how often the discount is applied. This is only applicable for limited recurring discounts.
     */
    frequencyInterval: number | null;
    /**
     * The name of the discount. This is displayed in the customer portal and in the invoice.
     */
    name: string;
    /**
     * The percentage of the discount. This is only applicable for percentage discounts with type "percentage".
     */
    percentage: number | null;
    /**
     * The type of the discount.
     */
    type?: DiscountInput_OrderSubscriptionProducts.type;
};
export namespace DiscountInput_OrderSubscriptionProducts {
    /**
     * The frequency how often the discount is applied.
     */
    export enum frequency {
        LIFETIME = 'lifetime',
        ONCE = 'once',
        LIMITED = 'limited',
    }
    /**
     * The type of the discount.
     */
    export enum type {
        FIXED_AMOUNT = 'fixed_amount',
        PERCENTAGE = 'percentage',
    }
}

