/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProductSetOptionResource_PlanUpdate } from './ProductSetOptionResource_PlanUpdate';
import type { SelectionSettingsResource_PlanUpdate } from './SelectionSettingsResource_PlanUpdate';
export type ProductSetResource_PlanUpdate = {
    id?: string | null;
    /**
     * Name of the product set, shown in the checkout. If null, the product set has no title in the checkout.
     */
    name?: string | null;
    /**
     * An internal name for the product set. This is not shown in the checkout.
     */
    internalName?: string | null;
    /**
     * Whether this set is required, recommended or optional.
     * `required`: This set must be added to the plan.
     * `recommended`: This set is recommended to be added to the plan.
     * `optional`: This set can be added to the plan.
     */
    type?: ProductSetResource_PlanUpdate.type;
    /**
     * Defines how the customer can select the products.
     */
    selection?: SelectionSettingsResource_PlanUpdate;
    /**
     * The product options of the product set.
     */
    options?: Array<ProductSetOptionResource_PlanUpdate>;
};
export namespace ProductSetResource_PlanUpdate {
    /**
     * Whether this set is required, recommended or optional.
     * `required`: This set must be added to the plan.
     * `recommended`: This set is recommended to be added to the plan.
     * `optional`: This set can be added to the plan.
     */
    export enum type {
        REQUIRED = 'required',
        OPTIONAL = 'optional',
        RECOMMENDED = 'recommended',
    }
}

