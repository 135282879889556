<script setup lang="ts">
import type { DynamicDialogInstance } from 'primevue/dynamicdialogoptions';
import slugify from '~/utils/slugify';
const dialogRef = inject<DynamicDialogInstance | null>('dialogRef');
const message = useMessage();
const { submitHandler, nodeHandler } = useForm();

const typeOptions = [
  { label: 'Schalter', value: 'switch' },
  { label: 'Menge', value: 'quantity' },
  { label: 'Zahlenbereich', value: 'range' },
  { label: 'Benutzerdefiniert', value: 'custom' },
];

const onSubmit = async (form: any, node: any) => {
  await submitHandler(
    {
      route: '/api/v1/entitlement/features',
      method: 'POST',
      successHandler(response) {
        message.success('Feature wurde erfolgreich erstellt');
        if (dialogRef?.value) {
          dialogRef.value.close(response.data);
        }
      },
      customErrorHandler() {
        message.error('Fehler beim Erstellen des Features');
      },
    },
    form,
    node
  );
};

const id = ref('');
const updateId = (value: string) => {
  id.value = slugify(value.replaceAll(/[^a-zA-Z0-9_-\s]/g, '')).toLowerCase();
};

const statusOptions = [
  { label: 'Aktiv', value: 'active' },
  { label: 'Entwurf', value: 'draft' },
];
</script>

<template>
  <div>
    <FormKit v-slot="{ value }" type="form" :actions="false" @submit="onSubmit" @node="nodeHandler">
      <FormKit type="primeInputText" name="name" label="Name" validation="required" placeholder="Anzahl Benutzer" @input="updateId" />
      <FormKit type="primeDropdown" name="type" label="Typ" :options="typeOptions" validation="required" placeholder="Typ auswählen" />
      <ul class="text-sm text-gray-500 list-disc max-w-xl ml-4">
        <li>Schalter: Ein/Aus, bspw. für Features die nur aktiviert oder deaktiviert werden können</li>
        <li>Menge: Anzahl, bspw. für Features die eine bestimmte Anzahl von Benutzern erlauben</li>
        <li>Zahlenbereich: Bereich, bspw. für Features die eine festgelegte Anzahl von Benutzern in einem Bereich erlauben</li>
        <li>Benutzerdefiniert: Benutzerdefiniert, bspw. für SLAs die bestimmte Werte wie "Gold", "Silber" oder "Bronze" erlauben</li>
      </ul>

      <div v-if="value.type === 'quantity' || value.type === 'custom'" key="quantityOrCustom">
        <FormKit type="repeater" name="config" :preserve="false">
          <FormKit
            :type="value.type === 'quantity' ? 'primeInputNumber' : 'primeInputText'"
            :max-fraction-digits="0"
            name="value"
            label="Wert"
            :validation="value.type === 'quantity' ? 'required|numeric' : 'required'"
            :placeholder="value.type === 'quantity' ? '10' : 'silver'"
            help="Wert zur Identifikation des Levels"
          />
          <div class="mt-3">
            <FormKit
              type="primeInputText"
              name="label"
              label="Beschreibung"
              validation="required"
              :placeholder="value.type === 'quantity' ? '10 Benutzer' : 'Silber SLA-Level'"
            />
          </div>
        </FormKit>
      </div>

      <div v-if="value.type === 'range'" key="range">
        <FormKit type="group" name="config" :preserve="false">
          <div class="flex gap-3">
            <div class="w-full">
              <FormKit
                type="primeInputNumber"
                name="from"
                label="Minimaler Wert"
                :max-fraction-digits="0"
                placeholder="Unbegrenzt"
                :nullable="true"
                :model-value="null"
              />
            </div>
            <div class="w-full">
              <FormKit
                type="primeInputNumber"
                name="to"
                label="Maximaler Wert"
                :max-fraction-digits="0"
                placeholder="Unbegrenzt"
                :nullable="true"
                :model-value="null"
              />
            </div>
          </div>
        </FormKit>
      </div>

      <div v-if="value.type !== 'custom' && value.type !== 'switch'">
        <FormKit
          type="unitDropdown"
          :show-clear="true"
          label="Einheit"
          name="unit"
          placeholder="Einheit auswählen"
          help="Einheit des Wertes (optional)"
          :preserve="false"
        />
      </div>

      <FormKit
        type="primeDropdown"
        name="status"
        label="Status"
        :options="statusOptions"
        option-label="label"
        option-value="value"
        model-value="active"
        validation="required"
        placeholder="Status auswählen"
        :preselect-option="false"
      />

      <FormKit
        type="primeInputText"
        name="description"
        label="Interne Beschreibung"
        placeholder="Erlaubte Anzahl der Benutzer"
        help="Zur einfacheren Wiedererkennung des Features"
      />

      <FormKit
        v-model="id"
        type="primeInputText"
        name="customId"
        label="Technische ID"
        placeholder="number-of-users"
        help="Technische ID des Features. Wird generiert wenn nicht angegeben"
      />

      <FormKit type="primeSubmitButton" submit-label="Feature erstellen" button-icon="pi pi-plus" />
    </FormKit>
  </div>
</template>

<style scoped lang="scss"></style>
