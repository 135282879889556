/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DocumentTemplateDto_OverdueRuleWrite } from './DocumentTemplateDto_OverdueRuleWrite';
import type { EmailTemplate_OverdueRuleWrite } from './EmailTemplate_OverdueRuleWrite';
export type OverdueRule_OverdueRuleWrite = {
    /**
     * Days after the invoice or last reminder / dunning is overdue.
     */
    daysOverdue: number;
    /**
     * Number of days until this reminder / dunning should be paid.
     */
    dueInDays: number;
    /**
     * Type of the overdue rule.
     *
     * Possible values:
     * * `reminder`: Send a reminder to the customer.
     * * `dunning`: Send a dunning to the customer.
     */
    type: OverdueRule_OverdueRuleWrite.type;
    /**
     * The email template that is used to send the reminder / dunning.
     */
    emailTemplate: EmailTemplate_OverdueRuleWrite;
    /**
     * The document template that is used to generate the reminder / dunning. This is required for a dunning and optional for a reminder.
     */
    documentTemplate: DocumentTemplateDto_OverdueRuleWrite;
    /**
     * Amount in cents that is charged in the currency of the base invoice, for the dunning. This is required for type "dunning".
     */
    amountInCents?: number | null;
    /**
     * Attach the original invoice additionally to the reminder / dunning.
     */
    attachOriginalInvoice?: boolean;
    /**
     * Send a letter by mail to the customer.
     */
    sendLetter?: boolean;
    /**
     * Enable or disable the overdue rule.
     */
    isEnabled?: boolean;
};
export namespace OverdueRule_OverdueRuleWrite {
    /**
     * Type of the overdue rule.
     *
     * Possible values:
     * * `reminder`: Send a reminder to the customer.
     * * `dunning`: Send a dunning to the customer.
     */
    export enum type {
        REMINDER = 'reminder',
        DUNNING = 'dunning',
    }
}

