<script setup lang='ts'>
import {CalendarBlurEvent} from "primevue/calendar";
import Calendar from "primevue/calendar";
import {watch} from "vue";
import type { FormKitFrameworkContext } from '@formkit/core';

const props = defineProps({
  context: Object,
})

const context: FormKitFrameworkContext = props.context
const checkIfInitValueHasTime = (value: string | Date) => {
  if (!value) {
    return false
  }

  if (value instanceof Date) {
    return !value.toString().endsWith('T00:00:00+00:00')
  }

  return !value.endsWith('T00:00:00+00:00')
}

const originalDate = ref(context._value ? new Date(context._value) : null);

console.log('context', context, context._value, checkIfInitValueHasTime(context._value));

const initValueHasTime = ref(checkIfInitValueHasTime(context._value));
const outputFormat = computed<'date' | 'datetime'>(() => initValueHasTime.value ? 'datetime' : (context?.attrs?.outputFormat ?? context?.props?.outputFormat ?? 'date'))
const attrs = computed(() => context?.attrs)
const showTime = computed(() => context.showTime ?? context?.attrs.showTime ?? initValueHasTime.value)

function handleInput(e: any) {
  // ignore input, because this is not allowed in calendar input
  return
  // context?.node.input(context?._value)
}

function handleSelect(e: any) {
  if (!e) {
   context?.node.input(null)
    return
  }

  const utcDate = new Date(e)
  if (outputFormat.value === 'date') {
    // set date to 00:00:00
    utcDate.setHours(0, 0, 0, 0)
  }

  if (initValueHasTime.value && outputFormat.value === 'datetime' && showTime.value === false) {
    // 1) Jahr, Monat, Tag aus `newCalendarDate`
    const year = utcDate.getUTCFullYear();
    const month = utcDate.getUTCMonth();        // 0-based
    const day = utcDate.getUTCDate();

    // 2) Stunden, Minuten, Sekunden aus `originalDate`
    const hours = originalDate.value?.getUTCHours();
    const minutes = originalDate.value?.getUTCMinutes();
    const seconds = originalDate.value?.getUTCSeconds();
    const ms = originalDate.value?.getUTCMilliseconds();

    // 3) Neues Datumobjekt mit dem neuen Datum und der alten Uhrzeit erzeugen
    const combinedDate = new Date(Date.UTC(year, month, day, hours, minutes, seconds, ms));
    // => So hast du z.B. 2024-09-15 10:45:40 UTC

    context?.node.input(combinedDate.toISOString().slice(0, -5) + '+00:00');
    return;
  }

  context?.node.input(utcDate.toISOString().slice(0, -5) + '+00:00')
}

function handleBlur(e: CalendarBlurEvent) {
  if (!e.value) {
    context?.handlers.blur(null)
    return
  }

  context?.handlers.blur(e.originalEvent)
}

const styleClass = computed(() => (context?.state.validationVisible && !context?.state.valid) ? `${attrs.value?.class} p-invalid` : attrs.value?.class)

const inputValue = computed(() => {
  if (!context?._value) {
    return null
  }
  const date = new Date(context._value)

  return date;
})


if (context._value) {
  handleSelect(context._value)
}

watch(() => attrs.value, (value, oldValue, onCleanup) => {
  if (oldValue?.minDate === value?.minDate) {
    return
  }

  // refresh calendar
  context.node.props.definition.schemaMemoKey = `${attrs.value.minDate}_${context.node.props.definition.schemaMemoKey}`
})
</script>

<template>
  <div class="p-formkit">
    <Calendar
        :model-value="inputValue"
        :input-id="props.context.id"
        :disabled="attrs._disabled ?? false"
        :readonly="attrs._readonly ?? false"
        :input-style="attrs.style"
        :input-class="styleClass"
        :tabindex="attrs.tabindex"
        :aria-label="attrs.ariaLabel"
        :aria-labelledby="attrs.ariaLabelledby"
        :date-format="attrs.dateFormat"
        :placeholder="attrs.placeholder"
        :selection-mode="attrs.selectionMode ?? 'single'"
        :inline="attrs.inline ?? false"
        :show-other-months="attrs.showOtherMonths ?? true"
        :select-other-months="attrs.selectOtherMonths ?? false"
        :icon="attrs.icon"
        :show-icon="context.showIcon ?? true"
        :previous-icon="attrs.previousIcon ?? 'pi pi-chevron-left'"
        :next-icon="attrs.nextIcon ?? 'pi pi-chevron-right'"
        :increment-icon="attrs.incrementIcon ?? 'pi pi-chevron-up'"
        :decrement-icon="attrs.decrementIcon ?? 'pi pi-chevron-down'"
        :number-of-months="attrs.numberOfMonths ?? 1"
        :responsive-options="attrs.responsiveOptions"
        :view="attrs.view ?? 'date'"
        :touch-u-i="attrs.touchUI ?? false"
        :min-date="context?.minDate ?? attrs.minDate"
        :max-date="context?.maxDate ?? attrs.maxDate"
        :disabled-dates="attrs.disabledDates"
        :disabled-days="attrs.disabledDays"
        :max-date-count="attrs.maxDateCount"
        :show-on-focus="attrs.showOnFocus ?? true"
        :auto-z-index="attrs.autoZIndex ?? true"
        :base-z-index="attrs.baseZIndex ?? 0"
        :show-button-bar="attrs.showButtonBar ?? true"
        :show-time="showTime ?? false"
        :time-only="attrs.timeOnly ?? false"
        :short-year-cutoff="attrs.shortYearCutoff ?? '+10'"
        :hour-format="attrs.hourFormat ?? '24'"
        :step-hour="attrs.stepHour ?? 1"
        :step-minute="attrs.stepMinute ?? 1"
        :step-second="attrs.stepSecond ?? 1"
        :show-seconds="attrs.showSeconds ?? false"
        :hide-on-date-time-select="attrs.hideOnDateTimeSelect ?? false"
        :hide-on-range-selection="attrs.hideOnRangeSelection ?? false"
        :time-separator="attrs.timeSeparator ?? ':'"
        :show-week="attrs.showWeek ?? false"
        :manual-input="attrs.manualInput ?? true"
        :append-to="attrs.appendTo ?? 'body'"
        :panel-style="attrs.panelStyle"
        :panel-class="attrs.panelClass"
        :pt="attrs.pt"
        :pt-options="attrs.ptOptions"
        :unstyled="attrs.unstyled ?? false"
        @date-select="handleSelect"
        @input="handleInput"
        @clear-click="handleSelect(null)"
        @today-click="handleSelect(new Date())"
        @blur="handleBlur"
    >
    </Calendar>
  </div>
</template>
