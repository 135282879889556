/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CustomerEmail_CustomerRead = {
    readonly id?: string;
    email: string;
    receiverName: string;
    type: CustomerEmail_CustomerRead.type;
    default?: boolean;
};
export namespace CustomerEmail_CustomerRead {
    export enum type {
        TYPE_COMPANY = 'TYPE_COMPANY',
        TYPE_INVOICE = 'TYPE_INVOICE',
    }
}

