/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type BillingGroup_BillingGroupRead = {
    readonly id?: string;
    /**
     * The name of the billing group.
     */
    name: string;
    /**
     * The type of the billing group.
     *
     * Possible values:
     * * `start_of_month`: The billing group will be charged on the first day of the month.
     * * `end_of_month`: The billing group will be charged on the last day of the month.
     * * `start_of_year`: The billing group will be charged on the first day of the year.
     * * `end_of_year`: The billing group will be charged on the last day of the year.
     * * `custom`: The billing group will be charged on a custom day of the month.
     */
    readonly type: BillingGroup_BillingGroupRead.type;
    /**
     * The custom day of the billing group, only used when type is "custom".
     */
    readonly customDay: number | null;
    /**
     * The custom month of the billing group, only used when type is "custom".
     */
    readonly customMonth: number | null;
};
export namespace BillingGroup_BillingGroupRead {
    /**
     * The type of the billing group.
     *
     * Possible values:
     * * `start_of_month`: The billing group will be charged on the first day of the month.
     * * `end_of_month`: The billing group will be charged on the last day of the month.
     * * `start_of_year`: The billing group will be charged on the first day of the year.
     * * `end_of_year`: The billing group will be charged on the last day of the year.
     * * `custom`: The billing group will be charged on a custom day of the month.
     */
    export enum type {
        START_OF_MONTH = 'start_of_month',
        END_OF_MONTH = 'end_of_month',
        START_OF_YEAR = 'start_of_year',
        END_OF_YEAR = 'end_of_year',
        CUSTOM = 'custom',
    }
}

