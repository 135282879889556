/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProductSetOptionResource_PlanDetail } from './ProductSetOptionResource_PlanDetail';
import type { SelectionSettingsResource_PlanDetail } from './SelectionSettingsResource_PlanDetail';
export type ProductSetResource_PlanDetail = {
    id?: string | null;
    /**
     * Name of the product set, shown in the checkout. If null, the product set has no title in the checkout.
     */
    name?: string | null;
    /**
     * An internal name for the product set. This is not shown in the checkout.
     */
    internalName?: string | null;
    /**
     * Whether this set is required, recommended or optional.
     * `required`: This set must be added to the plan.
     * `recommended`: This set is recommended to be added to the plan.
     * `optional`: This set can be added to the plan.
     */
    type?: ProductSetResource_PlanDetail.type;
    /**
     * Defines how the customer can select the products.
     */
    selection?: SelectionSettingsResource_PlanDetail;
    /**
     * The product options of the product set.
     */
    options?: Array<ProductSetOptionResource_PlanDetail>;
};
export namespace ProductSetResource_PlanDetail {
    /**
     * Whether this set is required, recommended or optional.
     * `required`: This set must be added to the plan.
     * `recommended`: This set is recommended to be added to the plan.
     * `optional`: This set can be added to the plan.
     */
    export enum type {
        REQUIRED = 'required',
        OPTIONAL = 'optional',
        RECOMMENDED = 'recommended',
    }
}

