/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PriceUpdateBatchItem_PriceUpdateBatchDetail } from './PriceUpdateBatchItem_PriceUpdateBatchDetail';
export type PriceUpdateBatch_PriceUpdateBatchDetail = {
    readonly id?: string;
    /**
     * The name of the price update batch.
     */
    name?: string | null;
    /**
     * The date and time when the price updates should be applied.
     */
    applyOn?: string;
    /**
     * Whether the customer should be informed about the price update.
     */
    informCustomer?: boolean;
    /**
     * The price update batch items that belong to this batch.
     */
    items?: Array<PriceUpdateBatchItem_PriceUpdateBatchDetail>;
    /**
     * The status of the price update batch.
     *
     * Possible values:
     * * `pending`: The price update batch is pending and has not been applied yet.
     * * `applied`: The price update batch has been applied successfully.
     * * `cancelled`: The price update batch has been cancelled.
     */
    readonly status?: PriceUpdateBatch_PriceUpdateBatchDetail.status;
    /**
     * The date and time when the price update batch was created.
     */
    readonly createdAt?: string;
    /**
     * The date and time when the price update batch was last updated.
     */
    readonly updatedAt?: string;
};
export namespace PriceUpdateBatch_PriceUpdateBatchDetail {
    /**
     * The status of the price update batch.
     *
     * Possible values:
     * * `pending`: The price update batch is pending and has not been applied yet.
     * * `applied`: The price update batch has been applied successfully.
     * * `cancelled`: The price update batch has been cancelled.
     */
    export enum status {
        PENDING = 'pending',
        APPLIED = 'applied',
        CANCELLED = 'cancelled',
    }
}

