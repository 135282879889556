/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BankAccount_BankAccountTransactionDetail } from './BankAccount_BankAccountTransactionDetail';
import type { BankAccountTransactionAssignment_BankAccountTransactionDetail } from './BankAccountTransactionAssignment_BankAccountTransactionDetail';
import type { Customer_BankAccountTransactionDetail } from './Customer_BankAccountTransactionDetail';
import type { Invoice_BankAccountTransactionDetail } from './Invoice_BankAccountTransactionDetail';
export type BankAccountTransaction_BankAccountTransactionDetail = {
    readonly id?: string;
    readonly suggestedInvoice?: Invoice_BankAccountTransactionDetail | null;
    readonly suggestedCustomer?: Customer_BankAccountTransactionDetail | null;
    valueDate?: string;
    bookingDate?: string;
    paymentDate?: string;
    usageDescription?: string;
    endToEndId?: string | null;
    counterParty?: BankAccount_BankAccountTransactionDetail | null;
    amount?: {
        /**
         * The amount as big integer, e.g. 1000 = 10.00 (precision = 2), 10000 = 10.000 (precision = 3)
         */
        readonly amount?: number;
        /**
         * The precision of the amount, which is the number of digits after the decimal separator, e.g. 2 = 10.00, 3 = 10.000
         */
        readonly precision?: number;
        /**
         * The currency code, e.g. EUR, USD, CHF
         */
        currency: string;
        /**
         * The formatted amount, e.g. 10.00 €, 10.00 $, 10.00 CHF
         */
        readonly i18n?: string;
        /**
         * Amount which can be used as input value, e.g. 10.00, 10.000.
         */
        inputValue: string;
    };
    readonly fee?: {
        /**
         * The amount as big integer, e.g. 1000 = 10.00 (precision = 2), 10000 = 10.000 (precision = 3)
         */
        readonly amount?: number;
        /**
         * The precision of the amount, which is the number of digits after the decimal separator, e.g. 2 = 10.00, 3 = 10.000
         */
        readonly precision?: number;
        /**
         * The currency code, e.g. EUR, USD, CHF
         */
        currency: string;
        /**
         * The formatted amount, e.g. 10.00 €, 10.00 $, 10.00 CHF
         */
        readonly i18n?: string;
        /**
         * Amount which can be used as input value, e.g. 10.00, 10.000.
         */
        inputValue: string;
    };
    status?: BankAccountTransaction_BankAccountTransactionDetail.status;
    readonly receivedAt?: string;
    readonly bookedAt?: string | null;
    readonly mandateId?: string | null;
    /**
     * The source of the bank account transaction.
     */
    readonly source?: BankAccountTransaction_BankAccountTransactionDetail.source | null;
    /**
     * The type of the bank account transaction.
     */
    type?: BankAccountTransaction_BankAccountTransactionDetail.type | null;
    /**
     * The transaction code as defined in ISO 20022. See: https://www.iso20022.org/sites/default/files/2020-08/BTC_ExternalCodeListDescription_31Aug2020.doc
     */
    transactionCode?: string | null;
    /**
     * All assignments to invoices and their transactions. Can be one or multiple, if the bank transaction was split into multiple invoices. Use case: A customer pays multiple invoices with one bank transaction.
     */
    readonly assignments?: Array<BankAccountTransactionAssignment_BankAccountTransactionDetail>;
    /**
     * The amount that is not assigned to any invoice or transaction. Can be negative if the bank transaction is assigned to invoices and transactions with a higher amount than the bank transaction amount.
     */
    readonly unassignedAmount?: {
        /**
         * The amount as big integer, e.g. 1000 = 10.00 (precision = 2), 10000 = 10.000 (precision = 3)
         */
        readonly amount?: number;
        /**
         * The precision of the amount, which is the number of digits after the decimal separator, e.g. 2 = 10.00, 3 = 10.000
         */
        readonly precision?: number;
        /**
         * The currency code, e.g. EUR, USD, CHF
         */
        currency: string;
        /**
         * The formatted amount, e.g. 10.00 €, 10.00 $, 10.00 CHF
         */
        readonly i18n?: string;
        /**
         * Amount which can be used as input value, e.g. 10.00, 10.000.
         */
        inputValue: string;
    };
};
export namespace BankAccountTransaction_BankAccountTransactionDetail {
    export enum status {
        STATUS_RECEIVED = 'STATUS_RECEIVED',
        STATUS_BOOKED = 'STATUS_BOOKED',
        STATUS_MANUAL_MATCHING_REQUIRED = 'STATUS_MANUAL_MATCHING_REQUIRED',
        STATUS_IGNORED = 'STATUS_IGNORED',
        OUTSTANDING_AMOUNT = 'outstanding_amount',
        SUGGESTIONS_AVAILABLE = 'suggestions_available',
    }
    /**
     * The source of the bank account transaction.
     */
    export enum source {
        KLARNA = 'klarna',
    }
    /**
     * The type of the bank account transaction.
     */
    export enum type {
        DEBIT = 'debit',
        CREDIT = 'credit',
    }
}

