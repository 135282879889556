/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PricePlan_Cart } from './PricePlan_Cart';
import type { Product_Cart } from './Product_Cart';
import type { TermPeriod_Cart } from './TermPeriod_Cart';
export type CartItem_Cart = {
    readonly id?: string;
    product?: Product_Cart;
    pricePlan?: PricePlan_Cart | null;
    /**
     * The quantity as float, e.g. 1.5
     */
    quantity?: number;
    readonly type?: CartItem_Cart.type;
    periods?: Array<TermPeriod_Cart>;
    readonly createdAt?: string;
    readonly updatedAt?: string;
};
export namespace CartItem_Cart {
    export enum type {
        PRODUCT = 'product',
    }
}

