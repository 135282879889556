/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type AfterCheckout_Default_contract_period_term_create = {
    /**
     * The url to redirect to after checkout. This is required for action "redirect".
     */
    url?: string | null;
    /**
     * The confirmation message to display after checkout. If none is set, the default confirmation message will be used.
     */
    confirmationMessage?: string | null;
    /**
     * The action to perform after checkout.
     */
    action?: AfterCheckout_Default_contract_period_term_create.action;
    /**
     * The url of the button "Zurück zum Anbieter". If none is provided, the button is hidden.
     */
    backToProviderUrl: string | null;
};
export namespace AfterCheckout_Default_contract_period_term_create {
    /**
     * The action to perform after checkout.
     */
    export enum action {
        REDIRECT = 'redirect',
        CONFIRMATION_MESSAGE = 'confirmation_message',
    }
}

