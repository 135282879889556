/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { User_UserMeResponse_UserInvitationRead } from './User_UserMeResponse_UserInvitationRead';
export type UserInvitation_UserInvitationRead = {
    readonly id?: string;
    readonly user?: User_UserMeResponse_UserInvitationRead | null;
    email?: string;
    expiresAt?: string;
    readonly acceptedAt?: string | null;
    role?: UserInvitation_UserInvitationRead.role;
};
export namespace UserInvitation_UserInvitationRead {
    export enum role {
        ADMINISTRATOR = 'administrator',
        ACCOUNT = 'account',
        READ_ONLY = 'read-only',
    }
}

