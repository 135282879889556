/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Appearance_TenantRead } from './Appearance_TenantRead';
import type { DocumentSettings_TenantRead } from './DocumentSettings_TenantRead';
import type { User_UserMeResponse_TenantRead } from './User_UserMeResponse_TenantRead';
export type Tenant_TenantRead = {
    readonly id?: string;
    /**
     * The name of the tenant
     */
    name: string;
    readonly username?: string;
    vatNumber?: string | null;
    readonly owner?: User_UserMeResponse_TenantRead;
    legalCompanyName: string;
    city?: string;
    zip?: string;
    street?: string;
    housenumber?: string;
    countryCode?: string;
    website?: string | null;
    commercialRegister: string | null;
    commercialRegisterNumber: string | null;
    phone?: string | null;
    email?: string;
    ceo?: string | null;
    /**
     * The IBAN will be displayed on the invoice
     */
    iban?: string;
    readonly environment?: Tenant_TenantRead.environment;
    appearance?: Appearance_TenantRead | null;
    documentSettings?: DocumentSettings_TenantRead | null;
    /**
     * IANA time zone
     */
    readonly timeZone?: string;
    /**
     * If true, customers are read-only in the frontend
     */
    readOnlyCustomers?: boolean;
    /**
     * The date and time when the resource was created.
     */
    readonly createdAt?: string;
    /**
     * The date and time when the resource was last updated.
     */
    readonly updatedAt?: string;
};
export namespace Tenant_TenantRead {
    export enum environment {
        SANDBOX = 'sandbox',
        PRODUCTION = 'production',
    }
}

