/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CartItem_Cart } from './CartItem_Cart';
import type { Customer_Cart } from './Customer_Cart';
import type { CustomerAddress_Cart } from './CustomerAddress_Cart';
import type { CustomerEmail_Cart } from './CustomerEmail_Cart';
import type { Invoice_Cart } from './Invoice_Cart';
import type { PaymentMethod_Cart } from './PaymentMethod_Cart';
import type { Subscription_Cart } from './Subscription_Cart';
import type { Transaction_Cart } from './Transaction_Cart';
export type Cart_Cart = {
    readonly id?: string;
    /**
     * The status of the cart.
     *
     * Possible values:
     * * `open`: The cart is open and can be edited.
     * * `confirming`: The cart is confirming and cannot be edited.
     */
    readonly status?: Cart_Cart.status;
    /**
     * The customer who owns the cart.
     */
    customer?: Customer_Cart | null;
    /**
     * The customer email which is used for the cart.
     */
    readonly customerEmail?: CustomerEmail_Cart | null;
    invoiceAddress?: CustomerAddress_Cart | null;
    /**
     * The payment method which should be used for the cart.
     */
    paymentMethod?: PaymentMethod_Cart | null;
    readonly createdAt?: string;
    readonly updatedAt?: string;
    readonly completedAt?: string | null;
    readonly redirectUrl?: string | null;
    /**
     * The items of the cart.
     */
    items?: Array<CartItem_Cart>;
    /**
     * The invoice of the cart. If the cart total amount is zero, no invoice will be created.
     */
    readonly invoice?: Invoice_Cart | null;
    /**
     * The subscription of the cart. If the cart has no recurring items (has only one-time fees), no subscription will be created.
     */
    readonly subscription?: Subscription_Cart | null;
    /**
     *  A collection of key-value pairs that can be linked to an object. This feature is beneficial for storing extra details regarding the object in an organized manner. To remove all keys, simply submit an empty value to the metadata.
     */
    metadata?: Array<string>;
    /**
     * The transactions of the cart.
     */
    readonly transactions?: Array<Transaction_Cart>;
};
export namespace Cart_Cart {
    /**
     * The status of the cart.
     *
     * Possible values:
     * * `open`: The cart is open and can be edited.
     * * `confirming`: The cart is confirming and cannot be edited.
     */
    export enum status {
        OPEN = 'open',
        CONFIRMING = 'confirming',
        REDIRECT_TO_PAYMENT = 'redirect_to_payment',
        EXPIRED = 'expired',
        COMPLETED = 'completed',
        PAID = 'paid',
    }
}

