/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type EmailTemplateVersion_OverdueRuleRead = {
    readonly id?: string;
    readonly version?: number;
    subject?: string;
    contentHtml?: string;
    contentPlain?: string;
    preHeader?: string | null;
    readonly createdAt?: string;
    createdBy?: EmailTemplateVersion_OverdueRuleRead.createdBy;
};
export namespace EmailTemplateVersion_OverdueRuleRead {
    export enum createdBy {
        SYSTEM = 'system',
        USER = 'user',
    }
}

