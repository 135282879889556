/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type OfferRecipient_SetOfferRecipientRoleInput = {
    /**
     * The role of the recipient
     */
    role: OfferRecipient_SetOfferRecipientRoleInput.role | null;
};
export namespace OfferRecipient_SetOfferRecipientRoleInput {
    /**
     * The role of the recipient
     */
    export enum role {
        READ = 'read',
        SIGN = 'sign',
    }
}

