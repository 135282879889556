/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TaxGroup } from './TaxGroup';
export type Tax = {
    readonly id?: string;
    taxGroup?: TaxGroup;
    /**
     * Some internal description
     */
    description: string | null;
    /**
     * Some internal code
     */
    code: string;
    /**
     * Tax rate in percent
     */
    rate: number;
    /**
     * Date from which the tax is valid.
     */
    validFrom: string | null;
    /**
     * Country code
     */
    countryCode: Tax.countryCode;
    readonly reverseChargeTax?: boolean;
    readonly reverseChargeIntraEuSupplyTax?: boolean;
    readonly exemptTax?: boolean;
    readonly tenantId?: string;
};
export namespace Tax {
    /**
     * Country code
     */
    export enum countryCode {
        _ = '*',
        EU__ = 'EU_*',
        AT = 'AT',
        BE = 'BE',
        BG = 'BG',
        CY = 'CY',
        CH = 'CH',
        CZ = 'CZ',
        DE = 'DE',
        DK = 'DK',
        EE = 'EE',
        EL = 'EL',
        ES = 'ES',
        FI = 'FI',
        FR = 'FR',
        GB = 'GB',
        HR = 'HR',
        HU = 'HU',
        IE = 'IE',
        IT = 'IT',
        LT = 'LT',
        LU = 'LU',
        LV = 'LV',
        MT = 'MT',
        NL = 'NL',
        PL = 'PL',
        PT = 'PT',
        RO = 'RO',
        SE = 'SE',
        SI = 'SI',
        SK = 'SK',
    }
}

