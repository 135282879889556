/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Measurement_PlanDetail } from './Measurement_PlanDetail';
import type { PlanChargeResource_PlanDetail } from './PlanChargeResource_PlanDetail';
import type { PlanFamily_PlanDetail } from './PlanFamily_PlanDetail';
import type { PricePlan_PlanDetail } from './PricePlan_PlanDetail';
import type { ProductSetResource_PlanDetail } from './ProductSetResource_PlanDetail';
import type { TaxGroup_PlanDetail } from './TaxGroup_PlanDetail';
export type Plan_PlanResource_PlanDetail = {
    id?: string | null;
    family: PlanFamily_PlanDetail;
    /**
     * The internal product id which is used for price plan filtering.
     */
    productId?: string;
    /**
     * The tax group of the plan. This does not applicate to the addons and charges.
     * If a product is provided, this value is ignored.
     */
    taxGroup?: TaxGroup_PlanDetail | null;
    /**
     * The measurement of the plan. This does not applicate to the addons and charges.
     * If a product is provided, this value is ignored.
     */
    measurement?: Measurement_PlanDetail | null;
    /**
     * The code of the plan. This is used to identify the plan e.g. in the checkout url.
     */
    code: string;
    /**
     * The name of the plan. If a product is provided, this value is not required.
     */
    name?: string;
    /**
     * The description of the plan shown in the checkout. If a product is provided, this value is not required.
     */
    description?: string | null;
    /**
     * The translations of the plan.
     */
    translations?: Record<string, {
        name?: string;
        description?: string;
        locale?: string;
    }>;
    /**
     * Define the allowed actions after subscription by customer, for upgrade / downgrade.
     */
    changeBehaviour: Plan_PlanResource_PlanDetail.changeBehaviour;
    /**
     * Define the behaviour when a plan change will be applied. If null the default behaviour will be used.
     */
    changeApplyBehaviour: Plan_PlanResource_PlanDetail.changeApplyBehaviour;
    /**
     * Allow to checkout this plan. If this is set to false, the plan will not be available in the checkout and can only be assigned to a subscription by the API or in the admin panel. The plan.code is a part of the checkout url.
     */
    allowCheckout?: boolean;
    /**
     * The prices of the item. You can define multiple prices here for different billing intervals.
     */
    pricePlans?: Array<PricePlan_PlanDetail>;
    /**
     * The product sets of the plan.
     */
    productSets?: Array<ProductSetResource_PlanDetail>;
    /**
     * The status of the plan.
     *
     * Possible values:
     * * `draft`: The plan is a draft and not available for usage in a subscription.
     * * `active`: The plan is active and can be used in a subscription.
     * * `archived`: The plan is archived and not longer available for usage in a subscription.
     * * `inactive`: The plan is inactive and not available for usage in a subscription.
     */
    status?: Plan_PlanResource_PlanDetail.status;
    /**
     * The charges of the plan.
     */
    charges?: Array<PlanChargeResource_PlanDetail>;
};
export namespace Plan_PlanResource_PlanDetail {
    /**
     * Define the allowed actions after subscription by customer, for upgrade / downgrade.
     */
    export enum changeBehaviour {
        UPGRADEABLE = 'upgradeable',
        DOWNGRADEABLE = 'downgradeable',
        UPGRADEABLE_AND_DOWNGRADEABLE = 'upgradeable_and_downgradeable',
        NOT_CHANGEABLE = 'not_changeable',
    }
    /**
     * Define the behaviour when a plan change will be applied. If null the default behaviour will be used.
     */
    export enum changeApplyBehaviour {
        IMMEDIATELY = 'immediately',
        END_OF_PERIOD = 'end_of_period',
    }
    /**
     * The status of the plan.
     *
     * Possible values:
     * * `draft`: The plan is a draft and not available for usage in a subscription.
     * * `active`: The plan is active and can be used in a subscription.
     * * `archived`: The plan is archived and not longer available for usage in a subscription.
     * * `inactive`: The plan is inactive and not available for usage in a subscription.
     */
    export enum status {
        DRAFT = 'draft',
        ACTIVE = 'active',
        ARCHIVED = 'archived',
        INACTIVE = 'inactive',
    }
}

