/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InvoicePositionItemGroup_InvoicePositionRead } from './InvoicePositionItemGroup_InvoicePositionRead';
import type { Tax_InvoicePositionRead } from './Tax_InvoicePositionRead';
import type { TaxGroup_InvoicePositionRead } from './TaxGroup_InvoicePositionRead';
import type { Unit_InvoicePositionRead } from './Unit_InvoicePositionRead';
export type InvoicePositionItem_InvoicePositionRead = {
    id?: string;
    /**
     * The position of the invoice position item in the invoice. If 0, the item will be added at the end of the invoice.
     */
    readonly position?: number | null;
    /**
     * The quantity of the invoice position item.
     */
    quantity?: number;
    /**
     * The unit of the invoice position item.
     */
    unit?: Unit_InvoicePositionRead | null;
    /**
     * The unit price of the invoice position item.
     */
    unitPrice?: {
        /**
         * The amount as big integer, e.g. 1000 = 10.00 (precision = 2), 10000 = 10.000 (precision = 3)
         */
        readonly amount?: number;
        /**
         * The precision of the amount, which is the number of digits after the decimal separator, e.g. 2 = 10.00, 3 = 10.000
         */
        readonly precision?: number;
        /**
         * The currency code, e.g. EUR, USD, CHF
         */
        currency: string;
        /**
         * The formatted amount, e.g. 10.00 €, 10.00 $, 10.00 CHF
         */
        readonly i18n?: string;
        /**
         * Amount which can be used as input value, e.g. 10.00, 10.000.
         */
        inputValue: string;
    };
    /**
     * The net amount of the invoice position item, before discount.
     */
    readonly netAmount?: {
        /**
         * The amount as big integer, e.g. 1000 = 10.00 (precision = 2), 10000 = 10.000 (precision = 3)
         */
        readonly amount?: number;
        /**
         * The precision of the amount, which is the number of digits after the decimal separator, e.g. 2 = 10.00, 3 = 10.000
         */
        readonly precision?: number;
        /**
         * The currency code, e.g. EUR, USD, CHF
         */
        currency: string;
        /**
         * The formatted amount, e.g. 10.00 €, 10.00 $, 10.00 CHF
         */
        readonly i18n?: string;
        /**
         * Amount which can be used as input value, e.g. 10.00, 10.000.
         */
        inputValue: string;
    };
    /**
     * The gross amount of the invoice position item, after discount.
     */
    readonly grossAmount?: {
        /**
         * The amount as big integer, e.g. 1000 = 10.00 (precision = 2), 10000 = 10.000 (precision = 3)
         */
        readonly amount?: number;
        /**
         * The precision of the amount, which is the number of digits after the decimal separator, e.g. 2 = 10.00, 3 = 10.000
         */
        readonly precision?: number;
        /**
         * The currency code, e.g. EUR, USD, CHF
         */
        currency: string;
        /**
         * The formatted amount, e.g. 10.00 €, 10.00 $, 10.00 CHF
         */
        readonly i18n?: string;
        /**
         * Amount which can be used as input value, e.g. 10.00, 10.000.
         */
        inputValue: string;
    };
    /**
     * The tax amount of the invoice position item, after discount.
     */
    readonly taxAmount?: {
        /**
         * The amount as big integer, e.g. 1000 = 10.00 (precision = 2), 10000 = 10.000 (precision = 3)
         */
        readonly amount?: number;
        /**
         * The precision of the amount, which is the number of digits after the decimal separator, e.g. 2 = 10.00, 3 = 10.000
         */
        readonly precision?: number;
        /**
         * The currency code, e.g. EUR, USD, CHF
         */
        currency: string;
        /**
         * The formatted amount, e.g. 10.00 €, 10.00 $, 10.00 CHF
         */
        readonly i18n?: string;
        /**
         * Amount which can be used as input value, e.g. 10.00, 10.000.
         */
        inputValue: string;
    };
    /**
     * The discount amount of the invoice position item.
     */
    discountAmount?: {
        /**
         * The amount as big integer, e.g. 1000 = 10.00 (precision = 2), 10000 = 10.000 (precision = 3)
         */
        readonly amount?: number;
        /**
         * The precision of the amount, which is the number of digits after the decimal separator, e.g. 2 = 10.00, 3 = 10.000
         */
        readonly precision?: number;
        /**
         * The currency code, e.g. EUR, USD, CHF
         */
        currency: string;
        /**
         * The formatted amount, e.g. 10.00 €, 10.00 $, 10.00 CHF
         */
        readonly i18n?: string;
        /**
         * Amount which can be used as input value, e.g. 10.00, 10.000.
         */
        inputValue: string;
    } | null;
    /**
     * The discount percentage of the invoice position item. You can either define an amount or a percentage.
     */
    discountPercentage?: string | null;
    /**
     * The fixed applied discount amount of the invoice position item.
     */
    discountFixed?: {
        /**
         * The amount as big integer, e.g. 1000 = 10.00 (precision = 2), 10000 = 10.000 (precision = 3)
         */
        readonly amount?: number;
        /**
         * The precision of the amount, which is the number of digits after the decimal separator, e.g. 2 = 10.00, 3 = 10.000
         */
        readonly precision?: number;
        /**
         * The currency code, e.g. EUR, USD, CHF
         */
        currency: string;
        /**
         * The formatted amount, e.g. 10.00 €, 10.00 $, 10.00 CHF
         */
        readonly i18n?: string;
        /**
         * Amount which can be used as input value, e.g. 10.00, 10.000.
         */
        inputValue: string;
    } | null;
    /**
     * The description of the invoice position item.
     */
    name?: string;
    /**
     * The description of the invoice position item.
     */
    description?: string | null;
    /**
     * The tax group of the invoice position item. Based on the customer and tax group, the correct tax rate will be calculated.
     */
    taxGroup?: TaxGroup_InvoicePositionRead;
    /**
     * The tax of the invoice position item, calculated based on the tax group.
     */
    readonly tax?: Tax_InvoicePositionRead | null;
    /**
     * The type of the invoice position item.
     */
    type?: InvoicePositionItem_InvoicePositionRead.type | null;
    /**
     * The parent invoice position item. Positions can be defined in a tree, with a maximum depth of two.
     */
    parent?: InvoicePositionItem_InvoicePositionRead | null;
    /**
     * The invoice position item group. Positions can be grouped together with a headline, which is shown on the invoice.
     */
    group?: InvoicePositionItemGroup_InvoicePositionRead | null;
    /**
     * The creation date of the invoice position item.
     */
    readonly createdAt?: string;
    product?: string | null;
    subscriptionItem?: string | null;
    /**
     * The service date from of the invoice position item.
     */
    readonly serviceDateFrom?: string | null;
    /**
     * The service date to of the invoice position item.
     */
    readonly serviceDateTo?: string | null;
    /**
     * If the position can be credited.
     */
    readonly isCreditable?: boolean;
    /**
     * If the whole position is credited.
     */
    readonly isFullyCredited?: boolean;
    /**
     * The quantity that can be credited.
     */
    readonly creditableQuantity?: number;
};
export namespace InvoicePositionItem_InvoicePositionRead {
    /**
     * The type of the invoice position item.
     */
    export enum type {
        PRODUCT = 'product',
        DISCOUNT = 'discount',
        SETUP = 'setup',
        DUNNING_FEE = 'dunning_fee',
        INVOICE = 'invoice',
    }
}

