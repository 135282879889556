/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type Customer_CreateSubscriptionFromPlan = {
    customerNumber?: string;
    /**
     * IANA time zone
     */
    readonly timeZone?: string;
    /**
     * Can be empty if the customer is a company.
     */
    firstName?: string | null;
    /**
     * Can be empty if the customer is a company.
     */
    lastName?: string | null;
    /**
     * Can be empty. In this case the default currency of the tenant is used.
     */
    currencyCode?: string;
    countryCode: string;
    companyName?: string | null;
    vatId?: string | null;
    /**
     * The ID of the customer in DATEV
     */
    datevId?: string | null;
    /**
     * The name of the commercial register. This is needed tofullfill the collection processes on companies.
     */
    commercialRegisterName?: string | null;
    /**
     * The ID of the customer in the commercial register, e.g. HRB 123456. This is needed tofullfill the collection processes on companies.
     */
    commercialRegisterNumber?: string | null;
    /**
     * This field is needed, to fullfill a collection process on a personal customer.
     */
    gender?: Customer_CreateSubscriptionFromPlan.gender | null;
    phone?: string | null;
    /**
     * The language of the customer. This is used for the communication with the customer or on generating documents like invoices, reminders, etc.
     */
    language?: Customer_CreateSubscriptionFromPlan.language;
};
export namespace Customer_CreateSubscriptionFromPlan {
    /**
     * This field is needed, to fullfill a collection process on a personal customer.
     */
    export enum gender {
        MALE = 'male',
        FEMALE = 'female',
        DIVERSE = 'diverse',
    }
    /**
     * The language of the customer. This is used for the communication with the customer or on generating documents like invoices, reminders, etc.
     */
    export enum language {
        DE = 'de',
        EN = 'en',
    }
}

