/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Assign a feature
 */
export type Feature_CreateFeatureAssignmentInput = {
    /**
     * The feature which should be assigned. Can be in any state.
     */
    feature: string | null;
    /**
     * The value of the feature assignment
     */
    value: string | null;
    /**
     * The date from which the feature assignment is valid
     */
    validFrom?: string | null;
    /**
     * The date until which the feature assignment is valid
     */
    validUntil?: string | null;
    /**
     * The object to which the feature should be assigned
     */
    object: Feature_CreateFeatureAssignmentInput.object | null;
    /**
     * The ID of the object to which the feature should be assigned
     */
    objectId: string;
    /**
     * Whether the new entitlement should be applied to existing subscriptions using this product or price.
     */
    applyToExistingSubscriptions?: boolean;
};
export namespace Feature_CreateFeatureAssignmentInput {
    /**
     * The object to which the feature should be assigned
     */
    export enum object {
        PRODUCT_PRICE = 'product-price',
        PRODUCT = 'product',
    }
}

