/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Entry_PostingRead } from './Entry_PostingRead';
import type { PostingId_PostingRead } from './PostingId_PostingRead';
export type Posting_PostingRead = {
    id?: PostingId_PostingRead;
    debit?: Entry_PostingRead | null;
    credit?: Entry_PostingRead | null;
    readonly createdAt?: string;
    readonly updatedAt?: string;
    type?: Posting_PostingRead.type;
    /**
     * Required for api platform.
     */
    readonly committed?: boolean;
};
export namespace Posting_PostingRead {
    export enum type {
        REVENUE = 'revenue',
        EXPENSE = 'expense',
        RESOLVE_DEFERAL = 'resolveDeferal',
        DEFERAL = 'deferal',
        PAYMENT = 'payment',
        REVENUE_TAX = 'revenueTax',
    }
}

