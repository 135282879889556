/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type InvoiceAddress_BankAccountTransactionAssignmentDetail = {
    readonly id?: string;
    readonly city?: string;
    readonly zip?: string;
    readonly street?: string;
    readonly houseNumber?: string;
    readonly country?: string;
    /**
     * Company name, which will be used to bill to.
     */
    readonly companyName?: string | null;
    readonly salutation?: InvoiceAddress_BankAccountTransactionAssignmentDetail.salutation | null;
    /**
     * Additional address information, which will be shown on generated documents like invoices, reminders, etc.
     */
    readonly addition?: string | null;
    /**
     * Cost centre, which will be shown on generated documents like invoices, reminders, etc.
     */
    readonly costCentre?: string | null;
    /**
     * First name, which will be shown on generated documents like invoices, reminders, etc.
     */
    readonly firstName?: string | null;
    /**
     * Last name, which will be shown on generated documents like invoices, reminders, etc.
     */
    readonly lastName?: string | null;
    /**
     * VAT ID, which will be shown on generated documents like invoices, reminders, etc.
     */
    readonly vatId?: string | null;
    /**
     * Customer address id, from which this invoice address was created.
     */
    readonly customerAddressId?: string | null;
};
export namespace InvoiceAddress_BankAccountTransactionAssignmentDetail {
    export enum salutation {
        FIRMA = 'Firma',
        HERR = 'Herr',
        FRAU = 'Frau',
    }
}

