/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CostCentre_PlanDetail } from './CostCentre_PlanDetail';
import type { Measurement_PlanDetail } from './Measurement_PlanDetail';
import type { ProductTranslation_PlanDetail } from './ProductTranslation_PlanDetail';
import type { TaxGroup_PlanDetail } from './TaxGroup_PlanDetail';
export type Product_PlanDetail = {
    /**
     * The unique identifier of the product.
     */
    readonly id?: string;
    /**
     * The name of the product based on the current tenant language. This will be displayed on customer communication.
     */
    name?: string;
    /**
     * The description of the product based on the current tenant language.
     */
    description?: string | null;
    /**
     * Internal name of the product, to differentiate between products with the same name. This will shown in web-app lists, selections and reports.
     */
    internalName?: string | null;
    /**
     * The translations of the product. The locale is used as key.
     */
    translations: Record<string, ProductTranslation_PlanDetail>;
    /**
     * The type of the product.
     * - `product`: recurring billed product
     * - `charge`: one-time billing
     * - `plan`: plan specific product, which cannot be used as a normal product. Will be filtered out in any product lists.
     *
     */
    type: Product_PlanDetail.type;
    /**
     * The measurement that is used for the price plan. This could define the quantity or a metered usage.
     */
    measurement: Measurement_PlanDetail;
    /**
     * Defines when the product should be displayed in the invoice.
     * - `always`: The product is always displayed in the invoice.
     * - `only_if_charged`: The product is only displayed in the invoice if it is charged.
     *
     */
    invoiceVisibility?: Product_PlanDetail.invoiceVisibility;
    /**
     * The cost centre is used for accounting exports.
     */
    costCentre?: CostCentre_PlanDetail | null;
    /**
     * The tax group that is used for the product.
     */
    taxGroup: TaxGroup_PlanDetail;
    /**
     * Custom fields for the entity. The keys are the field names and the values are the field values. They need to be configured under "/custom-fields" in the API documentation.
     */
    customFields?: Array<string>;
};
export namespace Product_PlanDetail {
    /**
     * The type of the product.
     * - `product`: recurring billed product
     * - `charge`: one-time billing
     * - `plan`: plan specific product, which cannot be used as a normal product. Will be filtered out in any product lists.
     *
     */
    export enum type {
        PRODUCT = 'product',
        CHARGE = 'charge',
        PLAN = 'plan',
    }
    /**
     * Defines when the product should be displayed in the invoice.
     * - `always`: The product is always displayed in the invoice.
     * - `only_if_charged`: The product is only displayed in the invoice if it is charged.
     *
     */
    export enum invoiceVisibility {
        ALWAYS = 'always',
        ONLY_IF_CHARGED = 'only_if_charged',
    }
}

