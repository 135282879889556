import { FormKitTypeDefinition } from '@formkit/core';
import { createInput } from '@formkit/vue';
import ProductFamilyDropdown from '~/formkit/inputs/ProductFamilyDropdown.vue';
import MeasurementDropdown2 from '~/formkit/inputs/MeasurementDropdown2.vue';
import ProductDropdown from '~/formkit/inputs/ProductDropdown.vue';
import PlanFamilyDropdown from '~/formkit/inputs/PlanFamilyDropdown.vue';
import CurrencyDropdown from '~/formkit/inputs/CurrencyDropdown.vue';
import CountryDropdown from '~/formkit/inputs/CountryDropdown.vue';
import LanguageDropdown from '~/formkit/inputs/LanguageDropdown.vue';
import CostCentreDropdown from '~/formkit/inputs/CostCentreDropdown.vue';
import CustomerAddressDropdown from '~/formkit/inputs/CustomerAddressDropdown.vue';
import CustomerDropdown from '~/formkit/inputs/CustomerDropdown.vue';
import TaxGroupDropdown from '~/formkit/inputs/TaxGroupDropdown.vue';
import AutoComplete from '~/formkit/inputs/AutoComplete.vue';
import UnitDropdown from '~/formkit/inputs/UnitDropdown.vue';
import PaymentMethodDropdown from '~/formkit/inputs/PaymentMethodDropdown.vue';
import PrimeVueDropdown from '~/formkit/inputs/PrimeVueDropdown.vue';
import SubmitButton from '~/formkit/inputs/SubmitButton.vue';
import PricePlanDropdown from '~/formkit/inputs/PricePlanDropdown.vue';
import PrimeInputMask from '~/formkit/inputs/PrimeInputMask.vue';
import PrimeMultiSelect from '~/formkit/inputs/PrimeMultiSelect.vue';
import CustomerEmailAddressDropdown from '~/formkit/inputs/CustomerEmailAddressDropdown.vue';
import RadioButton from '~/formkit/inputs/RadioButton.vue';
import BillingGroupDropdown from '~/formkit/inputs/BillingGroupDropdown.vue';
import ProductTreeDropdown from '~/formkit/inputs/ProductTreeDropdown.vue';
import CategoryTreeDropdown from '~/formkit/inputs/CategoryTreeDropdown.vue';
import CouponDropdown from '~/formkit/inputs/CouponDropdown.vue';
import IntervalInput from '~/formkit/inputs/IntervalInput.vue';
import Calendar from '~/formkit/inputs/Calendar.vue';
import TimeZoneDropdown from '~/formkit/inputs/TimeZoneDropdown.vue';
import PricePlanTierInput from '~/formkit/inputs/PricePlanTierInput.vue';
import PricePlanListInput from "~/formkit/inputs/PricePlanListInput.vue";
import PrimeSubmitButton from "~/formkit/inputs/PrimeSubmitButton.vue";
import PlanProductSetListInput from "~/formkit/inputs/PlanProductSetListInput.vue";
import PlanChargeListInput from "~/formkit/inputs/PlanChargeListInput.vue";
import CopyText from "~/formkit/inputs/CopyText.vue";
import GoogleAddressAutocomplete from "~/formkit/inputs/GoogleAddressAutocomplete.vue";
import Base64ImageUpload from "~/formkit/inputs/Base64ImageUpload.vue";
import TipTapEditor from "~/formkit/inputs/TipTapEditor.vue";
import FieldMappingInput from "~/formkit/inputs/FieldMappingInput.vue";
import DateInput from "~/formkit/inputs/DateInput.vue";
import BillingIntervalDropdown from "~/formkit/inputs/BillingIntervalDropdown.vue";
import ColorPicker from "~/formkit/inputs/ColorPicker.vue";
import OfferTemplateDropdown from "~/formkit/inputs/OfferTemplateDropdown.vue";
import CrmPipelineDropdown from "~/formkit/inputs/CrmPipelineDropdown.vue";
import CrmPipelineStageDropdown from "~/formkit/inputs/CrmPipelineStageDropdown.vue";
import CrmContactDropdown from "~/formkit/inputs/CrmContactDropdown.vue";
import CrmOwnerDropdown from "~/formkit/inputs/CrmOwnerDropdown.vue";
import CrmCompanyDropdown from "~/formkit/inputs/CrmCompanyDropdown.vue";
import ContactPersonDropdown from "~/formkit/inputs/ContactPersonDropdown.vue";
import ProductAutoComplete from "~/formkit/inputs/ProductAutoComplete.vue";
import CustomerGroupDropdown from "~/formkit/inputs/CustomerGroupDropdown.vue";
import PartnerDropdown from "~/formkit/inputs/PartnerDropdown.vue";
import TenantUsernameInput from "~/formkit/inputs/TenantUsernameInput.vue";
import TextInputSuffix from "~/formkit/inputs/TextInputSuffix.vue";
import Turnstile from "~/formkit/inputs/Turnstile.vue";
import PrimePassword from "~/formkit/inputs/PrimePassword.vue";
import CustomFieldsGroup from "~/formkit/inputs/CustomFieldsGroup.vue";
import Chips from "~/formkit/inputs/Chips.vue";
import TestingGroupInput from "~/formkit/inputs/TestingGroupInput.vue";
import PrimeInputNumber from "~/formkit/inputs/PrimeInputNumber.vue";
import CardSelectButton from "~/formkit/inputs/CardSelectButton.vue";
import PrimeInputSwitch from "~/formkit/inputs/PrimeInputSwitch.vue";
import FeatureDropdown from '~/formkit/inputs/FeatureDropdown.vue';

export const productFamilyDropdownDefinition: FormKitTypeDefinition = createInput(ProductFamilyDropdown, {
  props: [],
  family: 'fynnDropdown',
});

export const autoCompleteDefinition: FormKitTypeDefinition = createInput(AutoComplete, {
  props: [
    'itemSelect',
    'complete',
    'dropdown',
    'multiple',
    'optionLabel',
  ],
});

export const productAutoCompleteDefinition: FormKitTypeDefinition = createInput(ProductAutoComplete, {
  props: ['itemSelect']
});

export const measurementDropdownDefinition: FormKitTypeDefinition = createInput(MeasurementDropdown2, {
  props: [
    'defaultPlaceholder',
    'preselectOption',
    'defaultField',
    'defaultValue',
    'defaultOption',
  ],
  family: 'fynnDropdown',
});

export const productDropdownDefinition: FormKitTypeDefinition = createInput(ProductDropdown, {
  props: ['type', 'dropdownType', 'allowCreate', 'preloadOptions', 'productType', 'createProductDefaultData', 'onCreate', 'valueField'],
  family: 'fynnDropdown',
});

export const planFamilyDropdownDefinition: FormKitTypeDefinition = createInput(PlanFamilyDropdown, {
  props: [],
  family: 'fynnDropdown',
});

export const currencyDropdownDefinition: FormKitTypeDefinition = createInput(CurrencyDropdown, {
  props: ['returnCode', 'onlyEnabled', 'allowCreate'],
  family: 'fynnDropdown',
});

export const countryDropdownDefinition: FormKitTypeDefinition = createInput(CountryDropdown, {
  props: [],
  family: 'fynnDropdown',
});

export const languageDropdownDefinition: FormKitTypeDefinition = createInput(LanguageDropdown, {
  props: [],
  family: 'fynnDropdown',
});

export const costCentreDropdownDefinition: FormKitTypeDefinition = createInput(CostCentreDropdown, {
  props: [],
  family: 'fynnDropdown',
});

export const customerAddressDropdownDefinition: FormKitTypeDefinition = createInput(CustomerAddressDropdown, {
  props: ['customerId', 'showClear', 'filterByType', 'addressType', 'customerAddressId'],
  family: 'fynnDropdown',
});

export const customerEmailAddressDropdownDefinition: FormKitTypeDefinition = createInput(CustomerEmailAddressDropdown, {
  props: ['customerId'],
  family: 'fynnDropdown',
});

export const customerDropdownDefinition: FormKitTypeDefinition = createInput(CustomerDropdown, {
  props: ['customerId', 'currencyField', 'invoiceAddressField', 'paymentMethodField'],
  family: 'fynnDropdown',
});

export const taxGroupDropdownDefinition: FormKitTypeDefinition = createInput(TaxGroupDropdown, {
  props: [
    'defaultPlaceholder',
    'preselectOption',
    'defaultField',
    'defaultValue',
    'defaultOption',
  ],
  family: 'fynnDropdown',
});

export const unitDropdownDefinition: FormKitTypeDefinition = createInput(UnitDropdown, {
  props: ['showClear', 'preselectOption'],
  family: 'fynnDropdown',
});

export const featureDropdownDefinition: FormKitTypeDefinition = createInput(FeatureDropdown, {
  props: ['showClear', 'featureTypeField', 'featureConfigField', 'featureUnitField'],
  family: 'fynnDropdown',
});

export const paymentMethodDropdownDefinition: FormKitTypeDefinition = createInput(PaymentMethodDropdown, {
  props: ['customerId', 'defaultPlaceholder', 'showClear'],
  family: 'fynnDropdown',
});

export const primeDropdownDefinition: FormKitTypeDefinition = createInput(PrimeVueDropdown, {
  props: [],
  family: 'fynnDropdown',
});

export const submitButtonDefinition: FormKitTypeDefinition = createInput(SubmitButton, {
  props: ['updateLabel', 'createLabel', 'updateIcon', 'createIcon', 'entityId'],
});

export const pricePlanButtonDefinition: FormKitTypeDefinition = createInput(PricePlanDropdown, {
  props: ['customerId', 'productId'],
});
export const primeInputMaskDefinition: FormKitTypeDefinition = createInput(PrimeInputMask, {
  props: [],
});
export const primeMultiSelectDefinition: FormKitTypeDefinition = createInput(PrimeMultiSelect, {
  props: [],
});

export const fynnRadioButtonDefinition: FormKitTypeDefinition = createInput(RadioButton, {
  props: [],
  family: 'fynnDropdown',
});

export const cardSelectButtonDefinition: FormKitTypeDefinition = createInput(CardSelectButton, {
  props: ['columns', 'options'],
  family: 'fynnDropdown',
});

export const billingGroupDropdownDefinition: FormKitTypeDefinition = createInput(BillingGroupDropdown, {
  props: ['customerId', 'productType', 'onCreate'],
  family: 'fynnDropdown',
});

export const productTreeDropdownDefinition: FormKitTypeDefinition = createInput(ProductTreeDropdown, {
  props: [],
  family: 'fynnDropdown',
});

export const categoryTreeDropdownDefinition: FormKitTypeDefinition = createInput(CategoryTreeDropdown, {
  props: [],
  family: 'fynnDropdown',
});

export const couponDropdownDefinition: FormKitTypeDefinition = createInput(CouponDropdown, {
  props: [],
  family: 'fynnDropdown',
});

export const intervalInputDefinition: FormKitTypeDefinition = createInput(IntervalInput, {
  props: [],
});

export const calendarDefinition: FormKitTypeDefinition = createInput(Calendar, {
  props: ['outputFormat', 'showTime', 'showIcon', 'minDate', 'maxDate'],
});

export const timeZoneDropdown: FormKitTypeDefinition = createInput(TimeZoneDropdown, {
  props: [],
  family: 'fynnDropdown',
});

export const pricePlanTier: FormKitTypeDefinition = createInput(PricePlanTierInput, {
  props: ['currencyCode'],
});

export const pricePlanList: FormKitTypeDefinition = createInput(PricePlanListInput, {
  props: ['isEditable', 'hiddenBillingIntervals', 'allowAddNewPrice', 'showCheckoutLinkButtons', 'checkoutLinkOptions'],
});

export const planProductSetList: FormKitTypeDefinition = createInput(PlanProductSetListInput, {
  props: ['pricePlanPoints'],
});

export const planChargeList: FormKitTypeDefinition = createInput(PlanChargeListInput, {
  props: ['pricePlanPoints'],
});

export const primeSubmitButtonDefinition: FormKitTypeDefinition = createInput(PrimeSubmitButton, {
  props: ['submitLabel', 'buttonIcon', 'outlined', 'severity', 'buttonClass', 'disableOnDirty'],
});

export const copyText: FormKitTypeDefinition = createInput(CopyText, {
});

export const googleAddressAutocompleteDefinition = createInput(GoogleAddressAutocomplete, {
  props: ['apiKey', 'streetNumberField', 'streetField', 'zipField', 'cityField', 'countryCodeField'],
});

export const base64ImageUploadDefinition = createInput(Base64ImageUpload, {
  props: ['previewRounded', 'buttonPosition']
});

export const editorDefinition = createInput(TipTapEditor, {
  props: ['activeButtons', 'minHeight'],
});

export const fieldMappingInputDefinition = createInput(FieldMappingInput, {
  props: ['allKeys', 'availableOptions', 'keyHeader', 'valueHeader'],
});

export const inputDateDefinition = createInput(DateInput, {
  props: ['class', 'max', 'min', 'step', 'dateType'],
  family: 'fynnDropdown',
})

export const billingIntervalDropdownDefinition = createInput(BillingIntervalDropdown, {
  props: [],
});

export const colorPickerDefinition = createInput(ColorPicker, {
  props: [],
});

export const offerTemplateDrodpown = createInput(OfferTemplateDropdown, {
  props: ['templateType', 'showClear'],
  family: 'fynnDropdown',
});

export const crmPipelineDropdown = createInput(CrmPipelineDropdown, {
  props: [],
  family: 'fynnDropdown',
});

export const crmPipelineStageDropdown = createInput(CrmPipelineStageDropdown, {
  props: ['pipelineId'],
  family: 'fynnDropdown',
});

export const crmContactDropdown = createInput(CrmContactDropdown, {
  family: 'fynnDropdown',
  props: ['showClear'],
});

export const crmOwnerDropdown = createInput(CrmOwnerDropdown, {
  family: 'fynnDropdown',
  props: ['showClear'],
});

export const crmCompanyDropdown = createInput(CrmCompanyDropdown, {
  family: 'fynnDropdown',
  props: ['showClear'],
});

export const contactPersonDropdown = createInput(ContactPersonDropdown, {
  family: 'fynnDropdown',
  props: ['showClear'],
});

export const customerGroupDropdown = createInput(CustomerGroupDropdown, {
  family: 'fynnDropdown',
  props: ['showClear'],
});

export const partnerDropdown = createInput(PartnerDropdown, {
  family: 'fynnDropdown',
  props: ['showClear'],
});

export const translationGroup = createInput(TestingGroupInput, {
  family: 'fynnDropdown',
  type: 'group',
  props: ['legend'],
});

export const tenantUsernameInput = createInput(TenantUsernameInput, {
});

export const textInputSuffix = createInput(TextInputSuffix, {
});

export const captchaTurnstile = createInput(Turnstile, {
});

export const primePasswordDefinition: FormKitTypeDefinition = createInput(PrimePassword, {
  props: [
    'mediumRegex',
    'strongRegex',
    'promptLabel',
    'weakLabel',
    'mediumLabel',
    'strongLabel',
    'hideIcon',
    'showIcon',
    'pt',
    'ptOptions',
    'unstyled',
    'placeholder',
    'feedback',
    'toggleMask',
  ],
})

export const customFieldsDefinition: FormKitTypeDefinition = createInput(CustomFieldsGroup, {
  type: 'group',
  props: [
    'entityType',
  ]
});

export const chipsDefinition: FormKitTypeDefinition = createInput(Chips, {
  props: [
    'max',
    'allowDuplicate',
  ],
});

export const primeInputNumberDefinition: FormKitTypeDefinition = createInput(PrimeInputNumber, {
  props: [
    'useGrouping',
    'min',
    'max',
    'minFractionDigits',
    'maxFractionDigits',
    'locale',
    'mode',
    'currency',
    'prefix',
    'suffix',
    'showButtons',
    'buttonLayout',
    'step',
    'pt',
    'ptOptions',
    'unstyled',
    'placeholder',
  ],
});

export const primeInputSwitchDefinition: FormKitTypeDefinition = createInput(PrimeInputSwitch, {
  props: [
    'trueValue',
    'falseValue',
    'pt',
    'ptOptions',
    'unstyled',
    'labelLeft',
    'labelRight',
  ],
});


export const appInputs = {
  productFamilyDropdown: productFamilyDropdownDefinition,
  measurementDropdown: measurementDropdownDefinition,
  productDropdown: productDropdownDefinition,
  planFamilyDropdown: planFamilyDropdownDefinition,
  currencyDropdown: currencyDropdownDefinition,
  countryDropdown: countryDropdownDefinition,
  languageDropdown: languageDropdownDefinition,
  costCentreDropdown: costCentreDropdownDefinition,
  customerAddressDropdown: customerAddressDropdownDefinition,
  customerEmailAddressDropdown: customerEmailAddressDropdownDefinition,
  customerDropdown: customerDropdownDefinition,
  taxGroupDropdown: taxGroupDropdownDefinition,
  autoComplete: autoCompleteDefinition,
  unitDropdown: unitDropdownDefinition,
  paymentMethodDropdown: paymentMethodDropdownDefinition,
  primeDropdown: primeDropdownDefinition,
  submitButton: submitButtonDefinition,
  pricePlanDropdown: pricePlanButtonDefinition,
  primeInputMask: primeInputMaskDefinition,
  primeMultiSelect: primeMultiSelectDefinition,
  fynnRadioButton: fynnRadioButtonDefinition,
  billingGroupDropdown: billingGroupDropdownDefinition,
  productTreeDropdown: productTreeDropdownDefinition,
  productFamilyTreeDropdown: categoryTreeDropdownDefinition,
  couponDropdown: couponDropdownDefinition,
  interval: intervalInputDefinition,
  calendar: calendarDefinition,
  timeZoneDropdown,
  pricePlanTier,
  pricePlanList,
  planProductSetList,
  planChargeList,
  primeSubmitButton: primeSubmitButtonDefinition,
  copyText: copyText,
  googleAddressAutocomplete: googleAddressAutocompleteDefinition,
  base64ImageUpload: base64ImageUploadDefinition,
  editor: editorDefinition,
  fieldMapping: fieldMappingInputDefinition,
  inputDate: inputDateDefinition,
  billingIntervalDropdown: billingIntervalDropdownDefinition,
  colorPicker: colorPickerDefinition,
  offerTemplateDropdown: offerTemplateDrodpown,
  crmPipelineDropdown,
  crmPipelineStageDropdown,
  crmContactDropdown,
  crmOwnerDropdown,
  crmCompanyDropdown,
  contactPersonDropdown,
  productAutoComplete: productAutoCompleteDefinition,
  customerGroupDropdown,
  partnerDropdown,
  translationGroup,
  tenantUsername: tenantUsernameInput,
  captchaTurnstile,
  primePassword: primePasswordDefinition,
  customFields: customFieldsDefinition,
  chips: chipsDefinition,
  primeInputNumber: primeInputNumberDefinition,
  cardSelectButton: cardSelectButtonDefinition,
  primeInputSwitch: primeInputSwitchDefinition,
  featureDropdown: featureDropdownDefinition,
};
