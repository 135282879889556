/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Product_SubscriptionDetail } from './Product_SubscriptionDetail';
export type PlanCharge_SubscriptionDetail = {
    /**
     * The unique identifier of the plan product.
     */
    readonly id?: string;
    /**
     * The product this addon belongs to.
     */
    readonly product?: Product_SubscriptionDetail;
    /**
     * The event on which the charge should be applied. Defaults to ON_SUBSCRIPTION_START.
     *
     * Possible values:
     * * `on_subscription_start`: The charge is applied when the subscription starts.
     * * `on_subscription_end`: The charge is applied when the subscription ends.
     * * `on_subscription_trial_start`: The charge is applied when the subscription trial starts.
     * * `on_subscription_trial_end`: The charge is applied when the subscription trial ends.
     * * `on_checkout_payment`: The charge is applied when the checkout payment is made.
     */
    onEvent?: PlanCharge_SubscriptionDetail.onEvent;
    /**
     * Whether the charge should only be applied once on the event. Defaults to false.
     */
    onceOnEvent?: boolean;
};
export namespace PlanCharge_SubscriptionDetail {
    /**
     * The event on which the charge should be applied. Defaults to ON_SUBSCRIPTION_START.
     *
     * Possible values:
     * * `on_subscription_start`: The charge is applied when the subscription starts.
     * * `on_subscription_end`: The charge is applied when the subscription ends.
     * * `on_subscription_trial_start`: The charge is applied when the subscription trial starts.
     * * `on_subscription_trial_end`: The charge is applied when the subscription trial ends.
     * * `on_checkout_payment`: The charge is applied when the checkout payment is made.
     */
    export enum onEvent {
        ON_SUBSCRIPTION_START = 'on_subscription_start',
        ON_SUBSCRIPTION_END = 'on_subscription_end',
        ON_SUBSCRIPTION_TRIAL_START = 'on_subscription_trial_start',
        ON_SUBSCRIPTION_TRIAL_END = 'on_subscription_trial_end',
        ON_CHECKOUT_PAYMENT = 'on_checkout_payment',
    }
}

