/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ContractPeriodTermInput_Default_contract_period_term_create } from './ContractPeriodTermInput_Default_contract_period_term_create';
import type { Quantity_Default_contract_period_term_create } from './Quantity_Default_contract_period_term_create';
export type CheckoutLinkItemRequest_Default_contract_period_term_create = {
    /**
     * The type of the checkout link item.
     *
     * Possible values:
     * * `product`: A product will be added to the cart, with the given quantity.
     * * `plan`: A plan will be added to the cart, and can be configured by the customer.
     */
    type?: CheckoutLinkItemRequest_Default_contract_period_term_create.type;
    /**
     * This is required when the type is "product".
     */
    product?: string | null;
    /**
     * This is required when the type is "product".
     */
    quantity?: Quantity_Default_contract_period_term_create | null;
    /**
     * This is required when the type is "plan".
     */
    plan?: string | null;
    /**
     * This is required when the type is "product", "plan".
     */
    price?: string | null;
    /**
     * The contract period terms of the product item. If none provided, the billing period will be used as contract period, the cancellation period is set to 1 day.
     */
    periods?: Array<ContractPeriodTermInput_Default_contract_period_term_create>;
};
export namespace CheckoutLinkItemRequest_Default_contract_period_term_create {
    /**
     * The type of the checkout link item.
     *
     * Possible values:
     * * `product`: A product will be added to the cart, with the given quantity.
     * * `plan`: A plan will be added to the cart, and can be configured by the customer.
     */
    export enum type {
        PRODUCT = 'product',
        PLAN = 'plan',
        OFFER = 'offer',
    }
}

