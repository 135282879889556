/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type Interval_SubscriptionItemUpdate = {
    /**
     * The interval as string, e.g. 1, 2, 3
     */
    interval?: string;
    /**
     *
     *
     * Possible values:
     * * `S`: Second
     * * `I`: Minute
     * * `H`: Hour
     * * `D`: Day
     * * `W`: Week
     * * `M`: Month
     * * `Y`: Year
     */
    unit?: Interval_SubscriptionItemUpdate.unit;
};
export namespace Interval_SubscriptionItemUpdate {
    /**
     *
     *
     * Possible values:
     * * `S`: Second
     * * `I`: Minute
     * * `H`: Hour
     * * `D`: Day
     * * `W`: Week
     * * `M`: Month
     * * `Y`: Year
     */
    export enum unit {
        S = 'S',
        I = 'I',
        H = 'H',
        D = 'D',
        W = 'W',
        M = 'M',
        Y = 'Y',
    }
}

