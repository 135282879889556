/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CustomerAddress_PartnerList } from './CustomerAddress_PartnerList';
import type { CustomerEmail_PartnerList } from './CustomerEmail_PartnerList';
import type { PaymentMethod_PartnerList } from './PaymentMethod_PartnerList';
export type Customer_PartnerList = {
    readonly id?: string;
    customerNumber?: string;
    /**
     * IANA time zone
     */
    readonly timeZone?: string;
    /**
     * Can be empty if the customer is a company.
     */
    firstName?: string | null;
    /**
     * Can be empty if the customer is a company.
     */
    lastName?: string | null;
    /**
     * Can be empty. In this case the default currency of the tenant is used.
     */
    currencyCode?: string;
    countryCode: string;
    companyName?: string | null;
    vatId?: string | null;
    /**
     * Indicates if the VAT ID is valid. This is only available for EU VAT IDs. We validate the VAT IDs each night and directly after change.
     */
    readonly isVatIdValid?: boolean;
    /**
     * The ID of the customer in DATEV
     */
    datevId?: string | null;
    /**
     * The name of the commercial register. This is needed tofullfill the collection processes on companies.
     */
    commercialRegisterName?: string | null;
    /**
     * The ID of the customer in the commercial register, e.g. HRB 123456. This is needed tofullfill the collection processes on companies.
     */
    commercialRegisterNumber?: string | null;
    status?: Customer_PartnerList.status;
    /**
     * This field is needed, to fullfill a collection process on a personal customer.
     */
    gender?: Customer_PartnerList.gender | null;
    phone?: string | null;
    /**
     * The language of the customer. This is used for the communication with the customer or on generating documents like invoices, reminders, etc.
     */
    language?: Customer_PartnerList.language;
    /**
     * The current customer rank, based on their revenue. Lower is better. This will calculate each night.
     */
    readonly totalTurnOverRank?: number | null;
    readonly createdAt?: string;
    /**
     * Custom fields for the entity. The keys are the field names and the values are the field values. They need to be configured under "/custom-fields" in the API documentation.
     */
    customFields?: Array<string> | null;
    readonly businessCustomer?: boolean;
    /**
     * The default address of the customer. This is the address that is used for billing if no other address is specified.
     */
    readonly defaultAddress?: CustomerAddress_PartnerList | null;
    /**
     * The invoice address of the customer. This is the address that is used for billing if defined. If not defined, the default address is used.
     */
    readonly defaultInvoiceAddress?: CustomerAddress_PartnerList | null;
    /**
     * The default email address of the customer. This is the email address that is used for billing if no other email address is specified.
     */
    readonly defaultEmailAddress?: CustomerEmail_PartnerList | null;
    /**
     * The invoice email address of the customer. This is the email address that is used for billing if defined. If not defined, the default email address is used.
     */
    readonly defaultInvoiceEmailAddress?: CustomerEmail_PartnerList | null;
    /**
     * The default payment method of the customer. This is the payment method that is used for billing.
     */
    readonly defaultPaymentMethod?: PaymentMethod_PartnerList | null;
};
export namespace Customer_PartnerList {
    export enum status {
        STATUS_ACTIVE = 'STATUS_ACTIVE',
        STATUS_ARCHIVED = 'STATUS_ARCHIVED',
        STATUS_SUSPENDED = 'STATUS_SUSPENDED',
    }
    /**
     * This field is needed, to fullfill a collection process on a personal customer.
     */
    export enum gender {
        MALE = 'male',
        FEMALE = 'female',
        DIVERSE = 'diverse',
    }
    /**
     * The language of the customer. This is used for the communication with the customer or on generating documents like invoices, reminders, etc.
     */
    export enum language {
        DE = 'de',
        EN = 'en',
    }
}

