/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type KlarnaConsent = {
    readonly id?: string;
    readonly sessionId?: string;
    readonly flowId?: string;
    readonly validUntil?: string;
    readonly accountIban?: string | null;
    readonly consentToken?: string | null;
    readonly consentId?: string | null;
    readonly lastFetchedAt?: string | null;
    readonly createdAt?: string;
    readonly status?: KlarnaConsent.status;
    readonly linked?: boolean;
    readonly tenantId?: string;
};
export namespace KlarnaConsent {
    export enum status {
        LINKED = 'linked',
        EXPIRED = 'expired',
        FAILED = 'failed',
        PENDING = 'pending',
    }
}

