/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DocumentTemplateDto_OverdueRuleRead } from './DocumentTemplateDto_OverdueRuleRead';
import type { EmailTemplate_OverdueRuleRead } from './EmailTemplate_OverdueRuleRead';
export type OverdueRule_OverdueRuleRead = {
    readonly id?: string;
    /**
     * Level of the overdue rule. The level is used to sort the overdue rules. The overdue rule with the lowest level is used first.
     */
    level: number;
    /**
     * Days after the invoice or last reminder / dunning is overdue.
     */
    daysOverdue: number;
    /**
     * Number of days until this reminder / dunning should be paid.
     */
    dueInDays: number;
    /**
     * Type of the overdue rule.
     *
     * Possible values:
     * * `reminder`: Send a reminder to the customer.
     * * `dunning`: Send a dunning to the customer.
     */
    type: OverdueRule_OverdueRuleRead.type;
    /**
     * The email template that is used to send the reminder / dunning.
     */
    emailTemplate: EmailTemplate_OverdueRuleRead;
    /**
     * The document template that is used to generate the reminder / dunning. This is required for a dunning and optional for a reminder.
     */
    documentTemplate: DocumentTemplateDto_OverdueRuleRead;
    /**
     * Amount in cents that is charged in the currency of the base invoice, for the dunning. This is required for type "dunning".
     */
    amountInCents?: number | null;
    /**
     * Attach the original invoice additionally to the reminder / dunning.
     */
    attachOriginalInvoice?: boolean;
    /**
     * Send a letter by mail to the customer.
     */
    sendLetter?: boolean;
    /**
     * Enable or disable the overdue rule.
     */
    isEnabled?: boolean;
    /**
     * Date and time when the overdue rule was created.
     */
    readonly createdAt?: string;
    /**
     * Date and time when the overdue rule was last updated.
     */
    readonly updatedAt?: string;
};
export namespace OverdueRule_OverdueRuleRead {
    /**
     * Type of the overdue rule.
     *
     * Possible values:
     * * `reminder`: Send a reminder to the customer.
     * * `dunning`: Send a dunning to the customer.
     */
    export enum type {
        REMINDER = 'reminder',
        DUNNING = 'dunning',
    }
}

