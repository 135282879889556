export enum AppPermissions {
  InvoiceCreate = 'invoice:write',

  WebhookRead = 'webhook:read',
  WebhookWrite = 'webhook:write',

  CustomFieldRead = 'custom-field:read',
  CustomFieldWrite = 'custom-field:write',

  AccountRead = 'account:read',
  ApiTokenRead = 'api-token:read',
  ApiTokenWrite = 'api-token:write',

  CurrencyRead = 'currency:read',
  CurrencyWrite = 'currency:write',
  UserRead = 'user:read',
  UserWrite = 'user:write',

  UserInvitationRead = 'user-invitation:read',
  UserInvitationWrite = 'user-invitation:write',
  CustomerWrite = 'customer:write',
  CustomerAuthenticate = 'customer:authenticate',
  CustomerRead = 'customer:read',
  InvoiceWrite = 'invoice:write',
  InvoiceRead = 'invoice:read',
  InvoiceCancel = 'invoice:cancel',
  InvoiceFinalize = 'invoice:finalize',
  TaxGroupRead = 'tax-group:read',
  TaxGroupWrite = 'tax-group:write',
  CheckoutSettingsRead = 'checkout-settings:read',
  CheckoutSettingsWrite = 'checkout-settings:write',
  DocumentTemplateRead = 'document-template:read',
  DocumentTemplateWrite = 'document-template:write',
  DunningDocumentRead = 'dunning-document:read',
  MediaRead = 'media:read',
  MediaWrite = 'media:write',
  NumberSettingRead = 'number-setting:read',
  NumberSettingWrite = 'number-setting:write',
  PaymentGatewayRead = 'payment-gateway:read',
  PaymentGatewayConnect = 'payment-gateway:connect',
  PaymentMethodRead = 'payment-method:read',

  PaymentMethodWrite = 'payment-method:write',
  PaymentMethodAdd = 'payment-method:add',
  PaymentMethodMigrate = 'payment-method:migrate',
  SepaXmlRead = 'sepa-xml:read',
  SepaXmlWrite = 'sepa-xml:write',

  SettingsRead = 'settings:read',
  SettingsWrite = 'settings:write',
  CustomerSettingsRead = 'customer-settings:read',
  CustomerSettingsWrite = 'customer-settings:write',

  CouponRead = 'coupon:read',
  CouponWrite = 'coupon:write',
  MeasurementRead = 'measurement:read',
  MeasurementWrite = 'measurement:write',
  PlanRead = 'plan:read',
  PlanWrite = 'plan:write',
  PlanCreate = 'plan:create',
  ProductRead = 'product:read',
  ProductWrite = 'product:write',
  BillingGroupRead = 'billing-group:read',
  BillingGroupWrite = 'billing-group:write',
  ProductFamilyRead = 'product-family:read',
  ProductFamilyWrite = 'product-family:write',
  TenantManager = 'tenant:manager',
  TenantAccountRead = 'tenant:account:read',

  RoleUser = 'ROLE_USER', // do not use the roles, use the permissions instead!
  RoleAccount = 'role:account', //  do not use the roles, use the permissions instead!
  RoleAdministrator = 'role:administrator', //  do not use the roles, use the permissions instead!
  // RoleReadOnly = 'role:read-only',// - do not use the roles, use the permissions instead!
  UnitRead = 'unit:read',
  UnitWrite = 'unit:write',
  EmailTemplateRead = 'email-template:read',
  EmailTemplateWrite = 'email-template:write',
  EmailRead = 'email:read',
  OpenBankingFetchTransactions = 'open-banking:fetch-transactions',
  OpenBankingAccountRead = 'open-banking-account:read',
  OpenBankingAccountWrite = 'open-banking-account:write',
  BankAccountTransactionRead = 'bank-account-transaction:read',
  BankAccountTransactionWrite = 'bank-account-transaction:write',
  NotificationSettingRead = 'notification-setting:read',

  SubscriptionRead = 'subscription:read',
  SubscriptionWrite = 'subscription:write',

  TransactionWrite = 'transaction:write',
  TransactionRead = 'transaction:read',

  DatevRead = 'datev:read',

  FeatureFynnSepa = 'feature:fynn-sepa',
  FeatureGoCardlessSepa = 'feature:gocardless-sepa',
  OfferWrite = 'offer:write',
  OfferRead = 'offer:read',

  PricePlanWrite = 'price-plan:write',
  PricePlanRead = 'price-plan:read',

  CostCentreRead = 'cost-centre:read',
  CostCentreWrite = 'cost-centre:write',
  CartRead = 'cart:read',
  CartWrite = 'cart:write',

  CheckoutLinkWrite = 'checkout-link:write',
  CheckoutLinkRead = 'checkout-link:read',

  DunningRuleRead = 'dunning-rule:read',
  DunningRuleWrite = 'dunning-rule:write',

  PriceUpdateBulkWrite = 'price-update:bulk:write',
  PriceUpdateBulkRead = 'price-update:bulk:read',

  CustomerGroupWrite = 'customer-group:write',
  CustomerGroupRead = 'customer-group:read',

  PartnerWrite = 'partner:write',
  PartnerRead = 'partner:read',

  ContactPersonRead = 'contact-person:read',
  FeatureRead = 'feature:read',
  FeatureWrite = 'feature:write',
}
