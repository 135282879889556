/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SepaMandate } from './SepaMandate';
export type SepaXmlPayment = {
    readonly id?: string;
    /**
     *
     *
     * Possible values:
     * * `credit`: Credit
     * * `debit`: Debit
     */
    type?: SepaXmlPayment.type;
    createdAt?: string;
    dueDate?: string | null;
    remittanceInformation?: string | null;
    endToEndId?: string | null;
    amount?: number;
    readonly sepaMandate?: SepaMandate;
    readonly sepaXmlStatement?: string | null;
    /**
     * The invoice of the payment. In case of a cart payment or manual payment intent, the invoice is null.
     */
    invoice?: string | null;
    /**
     * The transaction of the payment.
     */
    transaction?: string;
    readonly autoCaptureAt?: string | null;
    notifications?: Array<string>;
    readonly customer?: string;
    readonly executionDate?: string;
    sepaMandateForSystemMigration?: SepaMandate;
    readonly tenantId?: string;
};
export namespace SepaXmlPayment {
    /**
     *
     *
     * Possible values:
     * * `credit`: Credit
     * * `debit`: Debit
     */
    export enum type {
        CREDIT = 'credit',
        DEBIT = 'debit',
    }
}

