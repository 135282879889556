/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Coupon_SubscriptionDetail } from './Coupon_SubscriptionDetail';
import type { Discount_SubscriptionDetail } from './Discount_SubscriptionDetail';
export type SubscriptionItemAppliedDiscount_SubscriptionDetail = {
    readonly id?: string;
    readonly coupon?: Coupon_SubscriptionDetail | null;
    /**
     * The status of this applied discount.
     *
     * Possible values:
     * * `active`: The discount is active and will be applied to the subscription item.
     * * `completed`: The discount is not active anymore, but it was applied to the subscription item.
     * * `removed`: The discount was removed from the subscription item.
     */
    readonly status?: SubscriptionItemAppliedDiscount_SubscriptionDetail.status;
    /**
     * The discount that is applied to the subscription item.
     */
    readonly discount?: Discount_SubscriptionDetail;
    /**
     * The number of intervals this discount was applied to the subscription item.
     */
    readonly appliedIntervals?: number | null;
};
export namespace SubscriptionItemAppliedDiscount_SubscriptionDetail {
    /**
     * The status of this applied discount.
     *
     * Possible values:
     * * `active`: The discount is active and will be applied to the subscription item.
     * * `completed`: The discount is not active anymore, but it was applied to the subscription item.
     * * `removed`: The discount was removed from the subscription item.
     */
    export enum status {
        ACTIVE = 'active',
        COMPLETED = 'completed',
        REMOVED = 'removed',
    }
}

