/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Update the status of a entitlement feature.
 */
export type Feature_UpdateFeatureStatusInput = {
    /**
     * The new status of the feature.
     */
    status: Feature_UpdateFeatureStatusInput.status;
    /**
     * **Only for status change to "active".** Whether the entitlements of this feature should be applied to existing subscriptions using this product or price.
     */
    applyToExistingSubscriptions?: boolean | null;
};
export namespace Feature_UpdateFeatureStatusInput {
    /**
     * The new status of the feature.
     */
    export enum status {
        DRAFT = 'draft',
        ACTIVE = 'active',
        ARCHIVED = 'archived',
    }
}

