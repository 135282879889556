/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type TaskItem_AsyncTaskList = {
    readonly id?: string;
    type?: TaskItem_AsyncTaskList.type;
    data?: Array<string>;
    readonly result?: Array<string>;
    readonly status?: TaskItem_AsyncTaskList.status;
    /**
     * The date and time when the resource was created.
     */
    readonly createdAt?: string;
    /**
     * The date and time when the resource was last updated.
     */
    readonly updatedAt?: string;
};
export namespace TaskItem_AsyncTaskList {
    export enum type {
        EXPORT_DATEV_DOCUMENT = 'export_datev_document',
        EXPORT_DATEV_EXTF = 'export_datev_extf',
        CHECK_DATEV_EXTF_JOB_STATUS = 'check_datev_extf_job_status',
    }
    export enum status {
        PENDING = 'pending',
        RUNNING = 'running',
        FINISHED = 'finished',
        FAILED = 'failed',
        CANCELLED = 'cancelled',
    }
}

