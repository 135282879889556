<script setup lang="ts">
import { nextTick, PropType } from 'vue';
import { FormKitContext } from '@formkit/core';
import { useDialog } from 'primevue/usedialog';
import { useI18n } from 'vue-i18n';
import ApiDropdown from '~/formkit/inputs/ApiDropdown.vue';
import AppDropdownCreateButton from '~/components/AppDropdownCreateButton.vue';
import { AppPermissions } from '~/types/Permissions';
import CreateCustomerForm from '~/components/Customer/CreateCustomerForm.vue';
import FeatureCreateForm from '~/components/Entitlement/FeatureCreateForm.vue';

const props = defineProps({
  context: Object as PropType<FormKitContext>,
});

const dialog = useDialog();

const defaultPlaceholder = computed(() => props.context?.defaultPlaceholder ?? 'Feature auswählen');

const apiEndpoint = computed(() => {
  return '/api/v1/ui/dropdown/entitlement/features';
});

const featureTypeField = computed(() => props.context.featureTypeField);
const featureConfigField = computed(() => props.context.featureConfigField);
const featureUnitField = computed(() => props.context.featureUnitField);

const onRawInput = async (value: any) => {
  if (value) {
    // wait 100ms, so the invoiceAddress and paymentMethodNode can appear in DOM, and we can find them in formkit node
    await nextTick(async () => await new Promise((resolve) => setTimeout(resolve, 100)));

    const node = props.context?.node as FormKitContext;

    node?.parent?.find(featureConfigField.value)?.input(value.config);
    node?.parent?.find(featureUnitField.value)?.input(value.unit);
    node?.parent?.find(featureTypeField.value)?.input(value.type);
  }
};

const { t } = useI18n();

const headerComponentProps = {
  title: 'Feature erstellen',
  onClick: ({ refresh, setInputValue }) => {
    dialog.open(FeatureCreateForm, {
      props: {
        header: 'Feature erstellen',
        modal: true,
      },
      onClose: async (data: any) => {
        if (data.data?.id) {
          await refresh();
          onRawInput(data.data);
          setInputValue(data.data.id);
        }
      },
    });
  },
};
</script>

<template>
  <div>
    <ApiDropdown
      :type="'single'"
      :context="context"
      :api-endpoint="apiEndpoint"
      :api-transform-function="(data) => data"
      :default-placeholder="defaultPlaceholder"
      :preload-options="true"
      :header-component="AppDropdownCreateButton"
      :header-component-props="headerComponentProps"
      :header-component-permissions="[AppPermissions.FeatureWrite]"
      @on-raw-input="onRawInput"
      :show-filter="true"
    >
      <template #option="slotProps">
        <div>
          <p class="mb-0">{{ slotProps.option.label }}</p>
          <code class="text-sm text-gray-800">{{ slotProps.option.id }}</code>
          <p v-if="slotProps.option.description" class="text-gray-500 text-sm mt-1">{{ slotProps.option.description }}</p>
        </div>
      </template>
    </ApiDropdown>
  </div>
</template>
