/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type DocumentTemplateDto_OverdueRuleRead = {
    id?: string;
    /**
     * Your brand color in hex format. Used for some text elements.
     */
    brandColor?: string;
    /**
     * Your header background color in hex format.
     */
    headerBackgroundColor?: string;
    /**
     * Your logo position in the header. Currently supported: left, center, right
     */
    logoPosition?: string;
    /**
     * The document type. Currently supported: invoice, subscription_cancellation, dunning
     */
    type: DocumentTemplateDto_OverdueRuleRead.type;
    name: string;
    /**
     * ISO 639-1 language code. The template is selected based on the customer language. If no template for language found, we will fallback to german (de).
     */
    language: DocumentTemplateDto_OverdueRuleRead.language;
    title: string;
    /**
     * The logo url can be a base64 encoded image or a url to a image file. If you use a base64 encoded image, the image must be a jpeg, jpg, png or gif file.
     * @deprecated
     */
    logoUrl?: string;
    introduction: string;
    closing: string;
    /**
     * Displayed in the header right side.
     */
    information?: Array<{
        key?: string;
        value?: string;
    }>;
    /**
     * Footer columns, from left to right.
     * @deprecated
     */
    footer?: Array<{
        line1?: string;
        line2?: string;
        line3?: string;
        line4?: string;
        line5?: string;
    }>;
};
export namespace DocumentTemplateDto_OverdueRuleRead {
    /**
     * The document type. Currently supported: invoice, subscription_cancellation, dunning
     */
    export enum type {
        INVOICE = 'invoice',
        SUBSCRIPTION_CANCELLATION = 'subscription_cancellation',
        DUNNING = 'dunning',
    }
    /**
     * ISO 639-1 language code. The template is selected based on the customer language. If no template for language found, we will fallback to german (de).
     */
    export enum language {
        DE = 'de',
        EN = 'en',
    }
}

