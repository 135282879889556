/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type Customer_CustomerInput_PartnerUpdate = {
    /**
     * Can be empty. In this case the default currency of the tenant is used.
     */
    currencyCode: string | null;
    /**
     * The customer group of the customer.
     */
    customerGroup?: string | null;
    /**
     * The contact person of the customer.
     */
    contactPerson?: string | null;
    countryCode: string | null;
    /**
     * The timezone of the customer. This is used for the communication with the customer or on generating documents like invoices, reminders, etc.
     */
    timeZone: string | null;
    /**
     * Can be empty if the customer is a company.
     */
    firstName: string | null;
    /**
     * Can be empty if the customer is a company.
     */
    lastName: string | null;
    /**
     * The language of the customer. This is used for the communication with the customer or on generating documents like invoices, reminders, etc.
     */
    language: Customer_CustomerInput_PartnerUpdate.language;
    /**
     * The ID of the customer in DATEV. If not provided, we will generate a datev id, when the "accounting.useDebitorAccounts" setting is set to true.
     */
    datevId: string | null;
    /**
     * This field is needed, to fullfill a collection process on a personal customer.
     */
    gender: Customer_CustomerInput_PartnerUpdate.gender | null;
    vatId: string | null;
    companyName: string | null;
    /**
     * The name of the commercial register. This is needed tofullfill the collection processes on companies.
     */
    commercialRegisterName: string | null;
    /**
     * The ID of the customer in the commercial register, e.g. HRB 123456. This is needed tofullfill the collection processes on companies.
     */
    commercialRegisterNumber: string | null;
    phone: string | null;
    /**
     * Can be empty. In this case the customer number is generated automatically.
     */
    customerNumber: string | null;
    /**
     * The tax exemption status of the customer.
     *
     * Possible values:
     * * `exempt`: The customer will never be charged with taxes.
     * * `auto`: The customer will be charged with taxes based on their country and vat id status. If the customer is eligble for reverse-charge the customer will not be charged with taxes.
     */
    taxExempt?: Customer_CustomerInput_PartnerUpdate.taxExempt;
    /**
     * Custom fields for the entity. The keys are the field names and the values are the field values. They need to be configured under "/custom-fields" in the API documentation.
     */
    customFields?: Array<string> | null;
};
export namespace Customer_CustomerInput_PartnerUpdate {
    /**
     * The language of the customer. This is used for the communication with the customer or on generating documents like invoices, reminders, etc.
     */
    export enum language {
        DE = 'de',
        EN = 'en',
    }
    /**
     * This field is needed, to fullfill a collection process on a personal customer.
     */
    export enum gender {
        MALE = 'male',
        FEMALE = 'female',
        DIVERSE = 'diverse',
    }
    /**
     * The tax exemption status of the customer.
     *
     * Possible values:
     * * `exempt`: The customer will never be charged with taxes.
     * * `auto`: The customer will be charged with taxes based on their country and vat id status. If the customer is eligble for reverse-charge the customer will not be charged with taxes.
     */
    export enum taxExempt {
        AUTO = 'auto',
        EXEMPT = 'exempt',
    }
}

