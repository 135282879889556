/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type SelectionSettingsResource_PlanCreate = {
    /**
     * Whether the customer can choose one of the products, multiple or need to choose all of them.
     *
     * Possible values:
     * * `single_select`: The customer can choose one of the products.
     * * `multi_select`: The customer can choose multiple products.
     * * `all`: The customer needs to choose all products.
     */
    type?: SelectionSettingsResource_PlanCreate.type;
    /**
     * Allows to edit the product selection after purchase.
     */
    editableAfterPurchase?: boolean;
    /**
     * Allows to edit the product selection during checkout.
     */
    editableDuringCheckout?: boolean;
    /**
     * The minimum number of selections. If null, the minimum is 0.
     * This is only applicable if the type is `multi_select`.
     */
    minSelections?: number | null;
    /**
     * The maximum number of selections. If null, there is no maximum.
     * This is only applicable if the type is `multi_select`.
     */
    maxSelections?: number | null;
};
export namespace SelectionSettingsResource_PlanCreate {
    /**
     * Whether the customer can choose one of the products, multiple or need to choose all of them.
     *
     * Possible values:
     * * `single_select`: The customer can choose one of the products.
     * * `multi_select`: The customer can choose multiple products.
     * * `all`: The customer needs to choose all products.
     */
    export enum type {
        SINGLE_SELECT = 'single_select',
        MULTI_SELECT = 'multi_select',
        ALL = 'all',
    }
}

