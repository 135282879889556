/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CreateSepaMandateResource = {
    /**
     * The mandate reference will be generated automatically, if none is provided. You can set it when migrating from another payment provider.
     */
    mandateReference?: string | null;
    /**
     * The customer's IBAN
     */
    iban: string | null;
    /**
     * The customer's BIC
     */
    bic: string | null;
    /**
     * The bank account holder's name
     */
    accountHolder: string | null;
    /**
     * The SEPA mandate signing date. If none is provided, the current date will be used.
     */
    signingDate?: string | null;
    /**
     * The SEPA mandate type. If none is provided, the default type "CORE" will be used.
     *
     * Possible values:
     * * `CORE`: The core scheme is used for payments between consumers and companies
     * * `B2B`: The B2B scheme is used for payments between companies. No refund is possible
     * * `COR1`: The COR1 scheme is used for payments between consumers and companies. The payment is processed faster than with the core scheme
     */
    type?: CreateSepaMandateResource.type | null;
    /**
     * The SEPA mandate sequence. If none is provided, the default sequence "FRST" will be used.
     *
     * Possible values:
     * * `FRST`: The sepa mandate is used for the first time for a recurring payment
     * * `RCUR`: The sepa mandate was already used the first time and is now used for recurring payments
     * * `FNAL`: The sepa mandate is used for the last time for a recurring payment
     * * `OOFF`: The sepa mandate is only allowed to be used for one payment
     */
    sequence?: CreateSepaMandateResource.sequence | null;
};
export namespace CreateSepaMandateResource {
    /**
     * The SEPA mandate type. If none is provided, the default type "CORE" will be used.
     *
     * Possible values:
     * * `CORE`: The core scheme is used for payments between consumers and companies
     * * `B2B`: The B2B scheme is used for payments between companies. No refund is possible
     * * `COR1`: The COR1 scheme is used for payments between consumers and companies. The payment is processed faster than with the core scheme
     */
    export enum type {
        CORE = 'CORE',
        B2B = 'B2B',
        COR1 = 'COR1',
    }
    /**
     * The SEPA mandate sequence. If none is provided, the default sequence "FRST" will be used.
     *
     * Possible values:
     * * `FRST`: The sepa mandate is used for the first time for a recurring payment
     * * `RCUR`: The sepa mandate was already used the first time and is now used for recurring payments
     * * `FNAL`: The sepa mandate is used for the last time for a recurring payment
     * * `OOFF`: The sepa mandate is only allowed to be used for one payment
     */
    export enum sequence {
        FRST = 'FRST',
        RCUR = 'RCUR',
        FNAL = 'FNAL',
        OOFF = 'OOFF',
    }
}

