/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CartItemQuantityResource_CartPublic = {
    aggregationType?: CartItemQuantityResource_CartPublic.aggregationType;
    unit?: string;
    description?: string | null;
    quantityEditable?: boolean;
};
export namespace CartItemQuantityResource_CartPublic {
    export enum aggregationType {
        COUNT = 'count',
        COUNT_UNIQUE = 'count_unique',
        MAX = 'max',
        SUM = 'sum',
        AVERAGE = 'average',
        LAST_VALUE = 'last_value',
    }
}

