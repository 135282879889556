/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type OpenBankingAccount_OpenBankingAccountRead = {
    readonly id?: string;
    alias?: string | null;
    bankName?: string;
    iban?: string;
    /**
     *
     *
     * Possible values:
     * * `linked`: The account is linked and can be used for fetching transactions
     * * `disabled`: The account is disabled and cannot be used for fetching transactions
     * * `expired`: The account access is expired (usally after 90 days) and cannot be used for fetching transactions
     * * `failed`: The account linking is failed. Try to link the account again
     */
    status?: OpenBankingAccount_OpenBankingAccountRead.status;
    /**
     *
     *
     * Possible values:
     * * `klarna`: The account is linked with Klarna
     */
    provider?: OpenBankingAccount_OpenBankingAccountRead.provider;
    readonly createdAt?: string;
    /**
     * The date when the account access expires and a new consent is required
     */
    validUntil?: string;
    /**
     * The last time the system accessed the account to obtain data
     */
    readonly lastAccessedAt?: string | null;
    /**
     * The last time the user was informed about the account expiration
     */
    readonly lastInformedAt?: string | null;
};
export namespace OpenBankingAccount_OpenBankingAccountRead {
    /**
     *
     *
     * Possible values:
     * * `linked`: The account is linked and can be used for fetching transactions
     * * `disabled`: The account is disabled and cannot be used for fetching transactions
     * * `expired`: The account access is expired (usally after 90 days) and cannot be used for fetching transactions
     * * `failed`: The account linking is failed. Try to link the account again
     */
    export enum status {
        LINKED = 'linked',
        DISABLED = 'disabled',
        EXPIRED = 'expired',
        FAILED = 'failed',
    }
    /**
     *
     *
     * Possible values:
     * * `klarna`: The account is linked with Klarna
     */
    export enum provider {
        KLARNA = 'klarna',
    }
}

