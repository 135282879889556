/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type Webhook = {
    readonly id?: string;
    events?: Array<string>;
    version?: string;
    enabled?: boolean;
    url?: string;
    readonly webhookResults?: Array<string>;
    readonly status?: Webhook.status;
    readonly lastSentAt?: string | null;
    activities?: Array<string>;
    readonly tenantId?: string;
};
export namespace Webhook {
    export enum status {
        SUCCESS = 'success',
        FAILED = 'failed',
        PENDING = 'pending',
    }
}

