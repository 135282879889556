/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type Cart_CartConfirmationResult_CartConfirmationResult = {
    /**
     * The action to perform after the cart confirmation.
     */
    action?: Cart_CartConfirmationResult_CartConfirmationResult.action;
    /**
     * The URL to redirect to after the cart confirmation.
     */
    redirectUrl?: string | null;
    /**
     * The status of the cart after the cart confirmation.
     */
    status?: Cart_CartConfirmationResult_CartConfirmationResult.status;
    /**
     * The message to show to the user after the cart confirmation.
     */
    confirmationMessage?: string | null;
};
export namespace Cart_CartConfirmationResult_CartConfirmationResult {
    /**
     * The action to perform after the cart confirmation.
     */
    export enum action {
        REDIRECT = 'redirect',
        NONE = 'none',
        CONFIRMATION_MESSAGE = 'confirmation_message',
    }
    /**
     * The status of the cart after the cart confirmation.
     */
    export enum status {
        OPEN = 'open',
        CONFIRMING = 'confirming',
        REDIRECT_TO_PAYMENT = 'redirect_to_payment',
        EXPIRED = 'expired',
        COMPLETED = 'completed',
        PAID = 'paid',
    }
}

