/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BankAccount_PaymentMethodRead } from './BankAccount_PaymentMethodRead';
export type SepaMandate_PaymentMethodRead = {
    readonly id?: string;
    /**
     * The status of the mandate
     *
     * Possible values:
     * * `active`: The mandate is active and can be used for payments
     * * `revoked`: The customer has revoked the mandate or a payment was rejected by the bank. The mandate can not be used for payments anymore
     */
    status?: SepaMandate_PaymentMethodRead.status;
    readonly creditorIdentifier?: string;
    /**
     * The reference of the mandate
     */
    mandateReference?: string;
    /**
     * The bank account of the mandate
     */
    bankAccount?: BankAccount_PaymentMethodRead;
    /**
     * The sequence type of the mandate
     *
     * Possible values:
     * * `FRST`: The sepa mandate is used for the first time for a recurring payment
     * * `RCUR`: The sepa mandate was already used the first time and is now used for recurring payments
     * * `FNAL`: The sepa mandate is used for the last time for a recurring payment
     * * `OOFF`: The sepa mandate is only allowed to be used for one payment
     */
    sequenceType?: SepaMandate_PaymentMethodRead.sequenceType;
    /**
     * The type of the mandate
     *
     * Possible values:
     * * `CORE`: The core scheme is used for payments between consumers and companies
     * * `B2B`: The B2B scheme is used for payments between companies. No refund is possible
     * * `COR1`: The COR1 scheme is used for payments between consumers and companies. The payment is processed faster than with the core scheme
     */
    sepaType?: SepaMandate_PaymentMethodRead.sepaType;
};
export namespace SepaMandate_PaymentMethodRead {
    /**
     * The status of the mandate
     *
     * Possible values:
     * * `active`: The mandate is active and can be used for payments
     * * `revoked`: The customer has revoked the mandate or a payment was rejected by the bank. The mandate can not be used for payments anymore
     */
    export enum status {
        ACTIVE = 'active',
        REVOKED = 'revoked',
    }
    /**
     * The sequence type of the mandate
     *
     * Possible values:
     * * `FRST`: The sepa mandate is used for the first time for a recurring payment
     * * `RCUR`: The sepa mandate was already used the first time and is now used for recurring payments
     * * `FNAL`: The sepa mandate is used for the last time for a recurring payment
     * * `OOFF`: The sepa mandate is only allowed to be used for one payment
     */
    export enum sequenceType {
        FRST = 'FRST',
        RCUR = 'RCUR',
        FNAL = 'FNAL',
        OOFF = 'OOFF',
    }
    /**
     * The type of the mandate
     *
     * Possible values:
     * * `CORE`: The core scheme is used for payments between consumers and companies
     * * `B2B`: The B2B scheme is used for payments between companies. No refund is possible
     * * `COR1`: The COR1 scheme is used for payments between consumers and companies. The payment is processed faster than with the core scheme
     */
    export enum sepaType {
        CORE = 'CORE',
        B2B = 'B2B',
        COR1 = 'COR1',
    }
}

