/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Coupon_SubscriptionItemRead } from './Coupon_SubscriptionItemRead';
import type { Discount_SubscriptionItemRead } from './Discount_SubscriptionItemRead';
export type SubscriptionItemAppliedDiscount_SubscriptionItemRead = {
    readonly id?: string;
    readonly coupon?: Coupon_SubscriptionItemRead | null;
    /**
     * The status of this applied discount.
     *
     * Possible values:
     * * `active`: The discount is active and will be applied to the subscription item.
     * * `completed`: The discount is not active anymore, but it was applied to the subscription item.
     * * `removed`: The discount was removed from the subscription item.
     */
    readonly status?: SubscriptionItemAppliedDiscount_SubscriptionItemRead.status;
    /**
     * The discount that is applied to the subscription item.
     */
    readonly discount?: Discount_SubscriptionItemRead;
    /**
     * The number of intervals this discount was applied to the subscription item.
     */
    readonly appliedIntervals?: number | null;
};
export namespace SubscriptionItemAppliedDiscount_SubscriptionItemRead {
    /**
     * The status of this applied discount.
     *
     * Possible values:
     * * `active`: The discount is active and will be applied to the subscription item.
     * * `completed`: The discount is not active anymore, but it was applied to the subscription item.
     * * `removed`: The discount was removed from the subscription item.
     */
    export enum status {
        ACTIVE = 'active',
        COMPLETED = 'completed',
        REMOVED = 'removed',
    }
}

