/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type TaxGroup_InvoiceRead = {
    readonly id?: string;
    /**
     * The name of the tax group which will be displayed only in the UI
     */
    internalDescription: string;
    /**
     * Defines if reverse charge will be applicated or not
     */
    reverseChargeType: TaxGroup_InvoiceRead.reverseChargeType;
    type: TaxGroup_InvoiceRead.type;
};
export namespace TaxGroup_InvoiceRead {
    /**
     * Defines if reverse charge will be applicated or not
     */
    export enum reverseChargeType {
        REVERSE_CHARGE_DEACTIVATED = 'REVERSE_CHARGE_DEACTIVATED',
        REVERSE_CHARGE = 'REVERSE_CHARGE',
        REVERSE_CHARGE_INTRA_EU_SUPPLY = 'REVERSE_CHARGE_INTRA_EU_SUPPLY',
    }
    export enum type {
        STANDARD = 'standard',
        REDUCED = 'reduced',
    }
}

