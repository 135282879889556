/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type UserInvitation_InviteUserRequest = {
    /**
     * The email address of the user to invite
     */
    email: string;
    /**
     * The role of the user to invite
     */
    role: UserInvitation_InviteUserRequest.role;
};
export namespace UserInvitation_InviteUserRequest {
    /**
     * The role of the user to invite
     */
    export enum role {
        ADMINISTRATOR = 'administrator',
        ACCOUNT = 'account',
        READ_ONLY = 'read-only',
    }
}

