/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type Discount_SubscriptionDetail = {
    /**
     * The name of this coupon, which will be displayed to the user in the checkout process, customer front, etc.
     */
    name: string;
    /**
     * The type of this coupon.
     *
     * Possible values:
     * * `percentage`: A percentage will be taken off the total plan amount.
     * * `fixed_amount`: A fixed amount will be taken off the total plan amount. It will never go below 0.
     */
    type: Discount_SubscriptionDetail.type;
    /**
     * The percentage this coupon should apply. **Required for type `percentage`.**
     */
    percentage?: number | null;
    /**
     * The fixed amount this coupon should apply. **Required for type `fixed_amount`.**
     */
    fixedAmount?: {
        /**
         * The amount as big integer, e.g. 1000 = 10.00 (precision = 2), 10000 = 10.000 (precision = 3)
         */
        readonly amount?: number;
        /**
         * The precision of the amount, which is the number of digits after the decimal separator, e.g. 2 = 10.00, 3 = 10.000
         */
        readonly precision?: number;
        /**
         * The currency code, e.g. EUR, USD, CHF
         */
        currency: string;
        /**
         * The formatted amount, e.g. 10.00 €, 10.00 $, 10.00 CHF
         */
        readonly i18n?: string;
        /**
         * Amount which can be used as input value, e.g. 10.00, 10.000.
         */
        inputValue: string;
    } | null;
    /**
     * The frequency at which a coupon should be applied.
     *
     * Possible values:
     * * `once`: The coupon will be applied once for the first billing period.
     * * `limited`: The coupon will be applied for a limited number of billing periods.
     * * `lifetime`: The coupon will be applied forever.
     */
    frequency: Discount_SubscriptionDetail.frequency;
    /**
     * The number of billing periods the coupon will be applied for. **Required for frequency `limited`.**
     */
    frequencyInterval?: number | null;
};
export namespace Discount_SubscriptionDetail {
    /**
     * The type of this coupon.
     *
     * Possible values:
     * * `percentage`: A percentage will be taken off the total plan amount.
     * * `fixed_amount`: A fixed amount will be taken off the total plan amount. It will never go below 0.
     */
    export enum type {
        PERCENTAGE = 'percentage',
        FIXED_AMOUNT = 'fixed_amount',
    }
    /**
     * The frequency at which a coupon should be applied.
     *
     * Possible values:
     * * `once`: The coupon will be applied once for the first billing period.
     * * `limited`: The coupon will be applied for a limited number of billing periods.
     * * `lifetime`: The coupon will be applied forever.
     */
    export enum frequency {
        ONCE = 'once',
        LIMITED = 'limited',
        LIFETIME = 'lifetime',
    }
}

