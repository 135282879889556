import { default as datevJmdYRNm9S7Meta } from "/usr/src/app/pages/accounting/datev.vue?macro=true";
import { default as exportLzFECpLZzJMeta } from "/usr/src/app/pages/accounting/export.vue?macro=true";
import { default as postingsNEsDJ9I529Meta } from "/usr/src/app/pages/accounting/postings.vue?macro=true";
import { default as _91token_93kCgieiHiYLMeta } from "/usr/src/app/pages/auth/accept-invitation/[token].vue?macro=true";
import { default as avvqMiyG2KnY7Meta } from "/usr/src/app/pages/auth/avv.vue?macro=true";
import { default as _91token_93IC5THNCci9Meta } from "/usr/src/app/pages/auth/password-recovery/[token].vue?macro=true";
import { default as sentbP92wERp3MMeta } from "/usr/src/app/pages/auth/password-recovery/sent.vue?macro=true";
import { default as registerGc3BUW1hFbMeta } from "/usr/src/app/pages/auth/register.vue?macro=true";
import { default as _91provider_93bG0MG74QgXMeta } from "/usr/src/app/pages/auth/social-login/[provider].vue?macro=true";
import { default as indexfIhhuOZysTMeta } from "/usr/src/app/pages/catalogue/entitlements/features/index.vue?macro=true";
import { default as indexBwCFsKuiUkMeta } from "/usr/src/app/pages/catalogue/plans/[id]/index.vue?macro=true";
import { default as indexyWQKUygGUsMeta } from "/usr/src/app/pages/catalogue/plans/index.vue?macro=true";
import { default as price_45assistant0QTLjieFrHMeta } from "/usr/src/app/pages/catalogue/price-assistant.vue?macro=true";
import { default as price_45updatesDNLzkm8KTWMeta } from "/usr/src/app/pages/catalogue/price-updates.vue?macro=true";
import { default as indexugliDbiw6SMeta } from "/usr/src/app/pages/catalogue/product-families/index.vue?macro=true";
import { default as index2wUfxAsDgMZMeta } from "/usr/src/app/pages/catalogue/product-families/index2.vue?macro=true";
import { default as indexPDNaXDpV82Meta } from "/usr/src/app/pages/catalogue/products/[id]/index.vue?macro=true";
import { default as indexp28ehomx22Meta } from "/usr/src/app/pages/catalogue/products/index.vue?macro=true";
import { default as indexqI7hcsxX5CMeta } from "/usr/src/app/pages/catalogue/units/index.vue?macro=true";
import { default as indexTj7sLpcXm4Meta } from "/usr/src/app/pages/catalogue/vouchers/index.vue?macro=true";
import { default as indexxka2jJUacqMeta } from "/usr/src/app/pages/customers/[id]/index.vue?macro=true";
import { default as invoicesjXz78tMIfJMeta } from "/usr/src/app/pages/customers/[id]/invoices.vue?macro=true";
import { default as payment_45methods1LoKTRBWkjMeta } from "/usr/src/app/pages/customers/[id]/payment-methods.vue?macro=true";
import { default as paymentsfrF5JJU0iwMeta } from "/usr/src/app/pages/customers/[id]/payments.vue?macro=true";
import { default as settingsSKFQQd7bSkMeta } from "/usr/src/app/pages/customers/[id]/settings.vue?macro=true";
import { default as subscriptionswZ1l76Kl7YMeta } from "/usr/src/app/pages/customers/[id]/subscriptions.vue?macro=true";
import { default as indexYiks8j7i4YMeta } from "/usr/src/app/pages/customers/index.vue?macro=true";
import { default as dogfBi6CMGdg9Meta } from "/usr/src/app/pages/dog.vue?macro=true";
import { default as indexgaGWyb3zYMMeta } from "/usr/src/app/pages/dunning/documents/index.vue?macro=true";
import { default as indexkjbJcSlhsKMeta } from "/usr/src/app/pages/finance/bank-transactions/[id]/index.vue?macro=true";
import { default as indexms4NoPZRpTMeta } from "/usr/src/app/pages/finance/bank-transactions/index.vue?macro=true";
import { default as indexjlEDRNCkuQMeta } from "/usr/src/app/pages/finance/sepa-exports/index.vue?macro=true";
import { default as index3q4xcbXDCiMeta } from "/usr/src/app/pages/finance/transactions/index.vue?macro=true";
import { default as first_45stepsnz3aUEhZN5Meta } from "/usr/src/app/pages/first-steps.vue?macro=true";
import { default as indexgw2ikJsyhcMeta } from "/usr/src/app/pages/index.vue?macro=true";
import { default as indexZ3wocIXSxjMeta } from "/usr/src/app/pages/internal/admin/index.vue?macro=true";
import { default as indexli06zGu5g7Meta } from "/usr/src/app/pages/internal/admin/users/index.vue?macro=true";
import { default as indexs4GtTkEac3Meta } from "/usr/src/app/pages/invoices/[id]/index.vue?macro=true";
import { default as cancelskUK16Yc1YfMeta } from "/usr/src/app/pages/invoices/cancels.vue?macro=true";
import { default as creditsutHbWVGW3hMeta } from "/usr/src/app/pages/invoices/credits.vue?macro=true";
import { default as indextuXjtfNIiCMeta } from "/usr/src/app/pages/invoices/index.vue?macro=true";
import { default as loginRGcvZC480vMeta } from "/usr/src/app/pages/login.vue?macro=true";
import { default as indexWefW2vAsp3Meta } from "/usr/src/app/pages/offers/[id]/index.vue?macro=true";
import { default as indexlujxqOsAdhMeta } from "/usr/src/app/pages/offers/index.vue?macro=true";
import { default as completedyEDRqnE340Meta } from "/usr/src/app/pages/onboarding/completed.vue?macro=true";
import { default as indextVDJvs0W9cMeta } from "/usr/src/app/pages/onboarding/index.vue?macro=true";
import { default as _91source_93fjYzEfBUjNMeta } from "/usr/src/app/pages/onboarding/migration-wizard/[source].vue?macro=true";
import { default as verify_45emailME5wRPHht1Meta } from "/usr/src/app/pages/onboarding/verify-email.vue?macro=true";
import { default as indexO7Wfk4fcvLMeta } from "/usr/src/app/pages/orders/index.vue?macro=true";
import { default as customersA01ghqJYM5Meta } from "/usr/src/app/pages/partners/[id]/customers.vue?macro=true";
import { default as indexi9RChn4EOoMeta } from "/usr/src/app/pages/partners/[id]/index.vue?macro=true";
import { default as invoicessXWEMkbNaVMeta } from "/usr/src/app/pages/partners/[id]/invoices.vue?macro=true";
import { default as payment_45methodsGxhjTyDI0tMeta } from "/usr/src/app/pages/partners/[id]/payment-methods.vue?macro=true";
import { default as paymentsFUWOlF7Rq1Meta } from "/usr/src/app/pages/partners/[id]/payments.vue?macro=true";
import { default as settingsCV13yF9BH2Meta } from "/usr/src/app/pages/partners/[id]/settings.vue?macro=true";
import { default as subscriptionsKf8q8HT9JGMeta } from "/usr/src/app/pages/partners/[id]/subscriptions.vue?macro=true";
import { default as indexTnxymMpf0QMeta } from "/usr/src/app/pages/partners/index.vue?macro=true";
import { default as cost_45centresDKs8WfFIWJMeta } from "/usr/src/app/pages/settings/accounting/cost-centres.vue?macro=true";
import { default as index9IACTxMFyZMeta } from "/usr/src/app/pages/settings/crm/index.vue?macro=true";
import { default as index4DGrEf5kNhMeta } from "/usr/src/app/pages/settings/developer/index.vue?macro=true";
import { default as hosted_45servicesqn0TMzKbljMeta } from "/usr/src/app/pages/settings/hosted-services.vue?macro=true";
import { default as indexPhr4sKDF2rMeta } from "/usr/src/app/pages/settings/index.vue?macro=true";
import { default as invoicesW5dpqa0aliMeta } from "/usr/src/app/pages/settings/invoices.vue?macro=true";
import { default as migrations4mQ4fyq40jMeta } from "/usr/src/app/pages/settings/migrations.vue?macro=true";
import { default as notifications7NpzkGxybyMeta } from "/usr/src/app/pages/settings/notifications.vue?macro=true";
import { default as number_45rangesjLjCjSFeNwMeta } from "/usr/src/app/pages/settings/number-ranges.vue?macro=true";
import { default as payment_45failures7f8WPZI1MHMeta } from "/usr/src/app/pages/settings/payment-failures.vue?macro=true";
import { default as payment_45methodsOcZdwhaxvsMeta } from "/usr/src/app/pages/settings/payment-methods.vue?macro=true";
import { default as usersKkKKMNcZ0gMeta } from "/usr/src/app/pages/settings/users.vue?macro=true";
import { default as _91id_93uvd05xknzyMeta } from "/usr/src/app/pages/settings/webhooks/[id].vue?macro=true";
import { default as xentralONLEQqReBSMeta } from "/usr/src/app/pages/settings/xentral.vue?macro=true";
import { default as statistics5dEKZWvt3jMeta } from "/usr/src/app/pages/statistics.vue?macro=true";
import { default as indexkH1YKUfCSEMeta } from "/usr/src/app/pages/subscriptions/[id]/index.vue?macro=true";
import { default as invoicesZbglILYD3cMeta } from "/usr/src/app/pages/subscriptions/[id]/invoices.vue?macro=true";
import { default as paymentsebu5j2Ts4LMeta } from "/usr/src/app/pages/subscriptions/[id]/payments.vue?macro=true";
import { default as indexXeMshcJSANMeta } from "/usr/src/app/pages/subscriptions/index.vue?macro=true";
import { default as new_45phasev7WMPjHGDvMeta } from "/usr/src/app/pages/subscriptions/new-phase.vue?macro=true";
import { default as newICFA7GCKouMeta } from "/usr/src/app/pages/subscriptions/new.vue?macro=true";
import { default as testcOJe9f9G9zMeta } from "/usr/src/app/pages/test.vue?macro=true";
import { default as test3IsHoiNOZDgMeta } from "/usr/src/app/pages/test3.vue?macro=true";
export default [
  {
    name: "accounting-datev",
    path: "/accounting/datev",
    meta: datevJmdYRNm9S7Meta || {},
    component: () => import("/usr/src/app/pages/accounting/datev.vue").then(m => m.default || m)
  },
  {
    name: "accounting-export",
    path: "/accounting/export",
    component: () => import("/usr/src/app/pages/accounting/export.vue").then(m => m.default || m)
  },
  {
    name: "accounting-postings",
    path: "/accounting/postings",
    component: () => import("/usr/src/app/pages/accounting/postings.vue").then(m => m.default || m)
  },
  {
    name: "auth-accept-invitation-token",
    path: "/auth/accept-invitation/:token()",
    meta: _91token_93kCgieiHiYLMeta || {},
    component: () => import("/usr/src/app/pages/auth/accept-invitation/[token].vue").then(m => m.default || m)
  },
  {
    name: "auth-avv",
    path: "/auth/avv",
    meta: avvqMiyG2KnY7Meta || {},
    component: () => import("/usr/src/app/pages/auth/avv.vue").then(m => m.default || m)
  },
  {
    name: "auth-password-recovery-token",
    path: "/auth/password-recovery/:token()",
    meta: _91token_93IC5THNCci9Meta || {},
    component: () => import("/usr/src/app/pages/auth/password-recovery/[token].vue").then(m => m.default || m)
  },
  {
    name: "auth-password-recovery-sent",
    path: "/auth/password-recovery/sent",
    meta: sentbP92wERp3MMeta || {},
    component: () => import("/usr/src/app/pages/auth/password-recovery/sent.vue").then(m => m.default || m)
  },
  {
    name: "auth-register",
    path: "/auth/register",
    meta: registerGc3BUW1hFbMeta || {},
    component: () => import("/usr/src/app/pages/auth/register.vue").then(m => m.default || m)
  },
  {
    name: "auth-social-login-provider",
    path: "/auth/social-login/:provider()",
    meta: _91provider_93bG0MG74QgXMeta || {},
    component: () => import("/usr/src/app/pages/auth/social-login/[provider].vue").then(m => m.default || m)
  },
  {
    name: "catalogue-entitlements-features",
    path: "/catalogue/entitlements/features",
    meta: indexfIhhuOZysTMeta || {},
    component: () => import("/usr/src/app/pages/catalogue/entitlements/features/index.vue").then(m => m.default || m)
  },
  {
    name: "catalogue-plans-id",
    path: "/catalogue/plans/:id()",
    component: () => import("/usr/src/app/pages/catalogue/plans/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "catalogue-plans",
    path: "/catalogue/plans",
    component: () => import("/usr/src/app/pages/catalogue/plans/index.vue").then(m => m.default || m)
  },
  {
    name: "catalogue-price-assistant",
    path: "/catalogue/price-assistant",
    meta: price_45assistant0QTLjieFrHMeta || {},
    component: () => import("/usr/src/app/pages/catalogue/price-assistant.vue").then(m => m.default || m)
  },
  {
    name: "catalogue-price-updates",
    path: "/catalogue/price-updates",
    meta: price_45updatesDNLzkm8KTWMeta || {},
    component: () => import("/usr/src/app/pages/catalogue/price-updates.vue").then(m => m.default || m)
  },
  {
    name: "catalogue-product-families",
    path: "/catalogue/product-families",
    component: () => import("/usr/src/app/pages/catalogue/product-families/index.vue").then(m => m.default || m)
  },
  {
    name: "catalogue-product-families-index2",
    path: "/catalogue/product-families/index2",
    component: () => import("/usr/src/app/pages/catalogue/product-families/index2.vue").then(m => m.default || m)
  },
  {
    name: "catalogue-products-id",
    path: "/catalogue/products/:id()",
    component: () => import("/usr/src/app/pages/catalogue/products/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "catalogue-products",
    path: "/catalogue/products",
    component: () => import("/usr/src/app/pages/catalogue/products/index.vue").then(m => m.default || m)
  },
  {
    name: "catalogue-units",
    path: "/catalogue/units",
    component: () => import("/usr/src/app/pages/catalogue/units/index.vue").then(m => m.default || m)
  },
  {
    name: "catalogue-vouchers",
    path: "/catalogue/vouchers",
    component: () => import("/usr/src/app/pages/catalogue/vouchers/index.vue").then(m => m.default || m)
  },
  {
    name: "customers-id",
    path: "/customers/:id()",
    component: () => import("/usr/src/app/pages/customers/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "customers-id-invoices",
    path: "/customers/:id()/invoices",
    component: () => import("/usr/src/app/pages/customers/[id]/invoices.vue").then(m => m.default || m)
  },
  {
    name: "customers-id-payment-methods",
    path: "/customers/:id()/payment-methods",
    component: () => import("/usr/src/app/pages/customers/[id]/payment-methods.vue").then(m => m.default || m)
  },
  {
    name: "customers-id-payments",
    path: "/customers/:id()/payments",
    component: () => import("/usr/src/app/pages/customers/[id]/payments.vue").then(m => m.default || m)
  },
  {
    name: "customers-id-settings",
    path: "/customers/:id()/settings",
    component: () => import("/usr/src/app/pages/customers/[id]/settings.vue").then(m => m.default || m)
  },
  {
    name: "customers-id-subscriptions",
    path: "/customers/:id()/subscriptions",
    component: () => import("/usr/src/app/pages/customers/[id]/subscriptions.vue").then(m => m.default || m)
  },
  {
    name: "customers",
    path: "/customers",
    component: () => import("/usr/src/app/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: "dog",
    path: "/dog",
    meta: dogfBi6CMGdg9Meta || {},
    component: () => import("/usr/src/app/pages/dog.vue").then(m => m.default || m)
  },
  {
    name: "dunning-documents",
    path: "/dunning/documents",
    component: () => import("/usr/src/app/pages/dunning/documents/index.vue").then(m => m.default || m)
  },
  {
    name: "finance-bank-transactions-id",
    path: "/finance/bank-transactions/:id()",
    component: () => import("/usr/src/app/pages/finance/bank-transactions/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "finance-bank-transactions",
    path: "/finance/bank-transactions",
    component: () => import("/usr/src/app/pages/finance/bank-transactions/index.vue").then(m => m.default || m)
  },
  {
    name: "finance-sepa-exports",
    path: "/finance/sepa-exports",
    meta: indexjlEDRNCkuQMeta || {},
    component: () => import("/usr/src/app/pages/finance/sepa-exports/index.vue").then(m => m.default || m)
  },
  {
    name: "finance-transactions",
    path: "/finance/transactions",
    component: () => import("/usr/src/app/pages/finance/transactions/index.vue").then(m => m.default || m)
  },
  {
    name: "first-steps",
    path: "/first-steps",
    component: () => import("/usr/src/app/pages/first-steps.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/usr/src/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "internal-admin",
    path: "/internal/admin",
    component: () => import("/usr/src/app/pages/internal/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "internal-admin-users",
    path: "/internal/admin/users",
    component: () => import("/usr/src/app/pages/internal/admin/users/index.vue").then(m => m.default || m)
  },
  {
    name: "invoices-id",
    path: "/invoices/:id()",
    component: () => import("/usr/src/app/pages/invoices/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "invoices-cancels",
    path: "/invoices/cancels",
    component: () => import("/usr/src/app/pages/invoices/cancels.vue").then(m => m.default || m)
  },
  {
    name: "invoices-credits",
    path: "/invoices/credits",
    component: () => import("/usr/src/app/pages/invoices/credits.vue").then(m => m.default || m)
  },
  {
    name: "invoices",
    path: "/invoices",
    component: () => import("/usr/src/app/pages/invoices/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginRGcvZC480vMeta || {},
    component: () => import("/usr/src/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "offers-id",
    path: "/offers/:id()",
    component: () => import("/usr/src/app/pages/offers/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "offers",
    path: "/offers",
    component: () => import("/usr/src/app/pages/offers/index.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-completed",
    path: "/onboarding/completed",
    meta: completedyEDRqnE340Meta || {},
    component: () => import("/usr/src/app/pages/onboarding/completed.vue").then(m => m.default || m)
  },
  {
    name: "onboarding",
    path: "/onboarding",
    meta: indextVDJvs0W9cMeta || {},
    component: () => import("/usr/src/app/pages/onboarding/index.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-migration-wizard-source",
    path: "/onboarding/migration-wizard/:source()",
    component: () => import("/usr/src/app/pages/onboarding/migration-wizard/[source].vue").then(m => m.default || m)
  },
  {
    name: "onboarding-verify-email",
    path: "/onboarding/verify-email",
    meta: verify_45emailME5wRPHht1Meta || {},
    component: () => import("/usr/src/app/pages/onboarding/verify-email.vue").then(m => m.default || m)
  },
  {
    name: "orders",
    path: "/orders",
    component: () => import("/usr/src/app/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "partners-id-customers",
    path: "/partners/:id()/customers",
    component: () => import("/usr/src/app/pages/partners/[id]/customers.vue").then(m => m.default || m)
  },
  {
    name: "partners-id",
    path: "/partners/:id()",
    component: () => import("/usr/src/app/pages/partners/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "partners-id-invoices",
    path: "/partners/:id()/invoices",
    component: () => import("/usr/src/app/pages/partners/[id]/invoices.vue").then(m => m.default || m)
  },
  {
    name: "partners-id-payment-methods",
    path: "/partners/:id()/payment-methods",
    component: () => import("/usr/src/app/pages/partners/[id]/payment-methods.vue").then(m => m.default || m)
  },
  {
    name: "partners-id-payments",
    path: "/partners/:id()/payments",
    component: () => import("/usr/src/app/pages/partners/[id]/payments.vue").then(m => m.default || m)
  },
  {
    name: "partners-id-settings",
    path: "/partners/:id()/settings",
    component: () => import("/usr/src/app/pages/partners/[id]/settings.vue").then(m => m.default || m)
  },
  {
    name: "partners-id-subscriptions",
    path: "/partners/:id()/subscriptions",
    component: () => import("/usr/src/app/pages/partners/[id]/subscriptions.vue").then(m => m.default || m)
  },
  {
    name: "partners",
    path: "/partners",
    component: () => import("/usr/src/app/pages/partners/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-accounting-cost-centres",
    path: "/settings/accounting/cost-centres",
    meta: cost_45centresDKs8WfFIWJMeta || {},
    component: () => import("/usr/src/app/pages/settings/accounting/cost-centres.vue").then(m => m.default || m)
  },
  {
    name: "settings-crm",
    path: "/settings/crm",
    meta: index9IACTxMFyZMeta || {},
    component: () => import("/usr/src/app/pages/settings/crm/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-developer",
    path: "/settings/developer",
    meta: index4DGrEf5kNhMeta || {},
    component: () => import("/usr/src/app/pages/settings/developer/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-hosted-services",
    path: "/settings/hosted-services",
    meta: hosted_45servicesqn0TMzKbljMeta || {},
    component: () => import("/usr/src/app/pages/settings/hosted-services.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "/settings",
    meta: indexPhr4sKDF2rMeta || {},
    component: () => import("/usr/src/app/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-invoices",
    path: "/settings/invoices",
    meta: invoicesW5dpqa0aliMeta || {},
    component: () => import("/usr/src/app/pages/settings/invoices.vue").then(m => m.default || m)
  },
  {
    name: "settings-migrations",
    path: "/settings/migrations",
    meta: migrations4mQ4fyq40jMeta || {},
    component: () => import("/usr/src/app/pages/settings/migrations.vue").then(m => m.default || m)
  },
  {
    name: "settings-notifications",
    path: "/settings/notifications",
    meta: notifications7NpzkGxybyMeta || {},
    component: () => import("/usr/src/app/pages/settings/notifications.vue").then(m => m.default || m)
  },
  {
    name: "settings-number-ranges",
    path: "/settings/number-ranges",
    meta: number_45rangesjLjCjSFeNwMeta || {},
    component: () => import("/usr/src/app/pages/settings/number-ranges.vue").then(m => m.default || m)
  },
  {
    name: "settings-payment-failures",
    path: "/settings/payment-failures",
    meta: payment_45failures7f8WPZI1MHMeta || {},
    component: () => import("/usr/src/app/pages/settings/payment-failures.vue").then(m => m.default || m)
  },
  {
    name: "settings-payment-methods",
    path: "/settings/payment-methods",
    meta: payment_45methodsOcZdwhaxvsMeta || {},
    component: () => import("/usr/src/app/pages/settings/payment-methods.vue").then(m => m.default || m)
  },
  {
    name: "settings-users",
    path: "/settings/users",
    meta: usersKkKKMNcZ0gMeta || {},
    component: () => import("/usr/src/app/pages/settings/users.vue").then(m => m.default || m)
  },
  {
    name: "settings-webhooks-id",
    path: "/settings/webhooks/:id()",
    meta: _91id_93uvd05xknzyMeta || {},
    component: () => import("/usr/src/app/pages/settings/webhooks/[id].vue").then(m => m.default || m)
  },
  {
    name: "settings-xentral",
    path: "/settings/xentral",
    meta: xentralONLEQqReBSMeta || {},
    component: () => import("/usr/src/app/pages/settings/xentral.vue").then(m => m.default || m)
  },
  {
    name: "statistics",
    path: "/statistics",
    component: () => import("/usr/src/app/pages/statistics.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-id",
    path: "/subscriptions/:id()",
    component: () => import("/usr/src/app/pages/subscriptions/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-id-invoices",
    path: "/subscriptions/:id()/invoices",
    component: () => import("/usr/src/app/pages/subscriptions/[id]/invoices.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-id-payments",
    path: "/subscriptions/:id()/payments",
    component: () => import("/usr/src/app/pages/subscriptions/[id]/payments.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions",
    path: "/subscriptions",
    component: () => import("/usr/src/app/pages/subscriptions/index.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-new-phase",
    path: "/subscriptions/new-phase",
    component: () => import("/usr/src/app/pages/subscriptions/new-phase.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-new",
    path: "/subscriptions/new",
    component: () => import("/usr/src/app/pages/subscriptions/new.vue").then(m => m.default || m)
  },
  {
    name: "test",
    path: "/test",
    component: () => import("/usr/src/app/pages/test.vue").then(m => m.default || m)
  },
  {
    name: "test3",
    path: "/test3",
    component: () => import("/usr/src/app/pages/test3.vue").then(m => m.default || m)
  }
]