/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CustomerAddress_CreateCustomerAddressRequest_CustomerAddressWrite = {
    /**
     * If not provided, we will use the customer country code.
     */
    countryCode?: string | null;
    /**
     * Can be empty if the customer is a company.
     */
    firstName?: string | null;
    /**
     * Can be empty if the customer is a company.
     */
    lastName?: string | null;
    /**
     * If not provided, we will use the customer vat id.
     */
    vatId?: string | null;
    companyName?: string | null;
    /**
     * The street of the customer. If not provided, we will use empty string.
     */
    street?: string | null;
    /**
     * The house number of the customer. If not provided, we will use empty string.
     */
    houseNumber?: string | null;
    /**
     * The zip code of the customer. If not provided, we will use empty string.
     */
    zip?: string | null;
    /**
     * The city of the customer. If not provided, we will use empty string.
     */
    city?: string | null;
    /**
     * Additional address line of the customer. If not provided, we will use empty string.
     */
    addition?: string | null;
    salutation?: CustomerAddress_CreateCustomerAddressRequest_CustomerAddressWrite.salutation | null;
    /**
     * The cost centre of the customer. If not provided, we will use empty string.
     */
    costCentre?: string | null;
    type?: CustomerAddress_CreateCustomerAddressRequest_CustomerAddressWrite.type | null;
};
export namespace CustomerAddress_CreateCustomerAddressRequest_CustomerAddressWrite {
    export enum salutation {
        FIRMA = 'Firma',
        HERR = 'Herr',
        FRAU = 'Frau',
    }
    export enum type {
        TYPE_DEFAULT = 'TYPE_DEFAULT',
        TYPE_INVOICE = 'TYPE_INVOICE',
    }
}

