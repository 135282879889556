/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BillingGroup_OfferDetail } from './BillingGroup_OfferDetail';
import type { CancellationReason_OfferDetail } from './CancellationReason_OfferDetail';
import type { ContractDetails_OfferDetail } from './ContractDetails_OfferDetail';
import type { Customer_OfferDetail } from './Customer_OfferDetail';
import type { CustomerAddress_OfferDetail } from './CustomerAddress_OfferDetail';
import type { PartnerResource_OfferDetail } from './PartnerResource_OfferDetail';
import type { PaymentMethod_OfferDetail } from './PaymentMethod_OfferDetail';
import type { Plan_PlanResource_OfferDetail } from './Plan_PlanResource_OfferDetail';
import type { SubscriptionItem_OfferDetail } from './SubscriptionItem_OfferDetail';
import type { TermPeriod_OfferDetail } from './TermPeriod_OfferDetail';
export type Subscription_OfferDetail = {
    readonly id?: string;
    externalId?: string | null;
    /**
     * The payment method of the subscription. If null, the default payment method of the customer is used.
     */
    paymentMethod?: PaymentMethod_OfferDetail | null;
    /**
     * The invoice address of the subscription. If null, the default invoice address of the customer is used.
     */
    invoiceAddress?: CustomerAddress_OfferDetail | null;
    /**
     * The number of the subscription.
     */
    number?: string;
    customer?: Customer_OfferDetail;
    /**
     * The plan this subscription is based on.
     */
    readonly plan?: Plan_PlanResource_OfferDetail | null;
    /**
     * The name of the subscription. Defaults to the name of the plan. This will be used in the invoice for the grouping headers.
     */
    name?: string | null;
    /**
     * The selected items of the subscription. Can be charges or addons.
     */
    items?: Array<SubscriptionItem_OfferDetail>;
    /**
     * The billing group defines the billing cycle of the subscription.
     */
    billingGroup?: BillingGroup_OfferDetail | null;
    periods?: Array<TermPeriod_OfferDetail>;
    /**
     * The status of the subscription.
     *
     * Possible values:
     * * `draft`: The subscription is in draft mode and not active yet.
     * * `active`: The subscription is active and will be billed.
     * * `paused`: The subscription is paused and will not be billed.
     * * `cancelled`: The subscription is cancelled and will not be billed anymore.
     * * `terminated`: The subscription is terminated and will not be billed anymore.
     * * `offer`: The subscription is an offer and waiting for acceptance.
     */
    readonly status?: Subscription_OfferDetail.status;
    /**
     * The date the subscription was activated.
     */
    readonly activatedAt?: string | null;
    /**
     * The po number of the subscription.
     */
    poNumber?: string | null;
    /**
     * The contract details of the subscription.
     */
    contractDetails?: ContractDetails_OfferDetail;
    /**
     * The date the trial period ends.
     */
    readonly trialEndsOn?: string | null;
    /**
     * Custom fields for the entity. The keys are the field names and the values are the field values. They need to be configured under "/custom-fields" in the API documentation.
     */
    customFields?: Array<string> | null;
    /**
     * The date and time when the resource was created.
     */
    readonly createdAt?: string;
    /**
     * The date and time when the resource was last updated.
     */
    readonly updatedAt?: string;
    /**
     * The payment method is null, we use the default payment method of the customer.
     */
    readonly defaultPaymentMethod?: boolean;
    /**
     * The invoice address is null, we use the default invoice address of the customer.
     */
    readonly defaultInvoiceAddress?: boolean;
    /**
     * The next billing date of the subscription.
     */
    readonly nextBillingDate?: string | null;
    /**
     * The last billing date of the subscription.
     */
    readonly lastBillingAt?: string | null;
    /**
     * The date the subscription will be cancelled.
     */
    readonly cancellationDate?: string | null;
    /**
     * The date when the subscription was cancelled.
     */
    readonly cancelledAt?: string | null;
    /**
     * The reason why the subscription was cancelled.
     */
    readonly cancellationReason?: CancellationReason_OfferDetail | null;
    /**
     * Indicates if the subscription is pending for cancellation. If true, the subscription will be cancelled in the future and the cancellation could be revoked.
     */
    readonly isCancellationPending?: boolean;
    readonly nextPossibleCancellationDate?: Array<string> | null;
    /**
     * The partner of the customer.
     */
    readonly partner?: PartnerResource_OfferDetail | null;
};
export namespace Subscription_OfferDetail {
    /**
     * The status of the subscription.
     *
     * Possible values:
     * * `draft`: The subscription is in draft mode and not active yet.
     * * `active`: The subscription is active and will be billed.
     * * `paused`: The subscription is paused and will not be billed.
     * * `cancelled`: The subscription is cancelled and will not be billed anymore.
     * * `terminated`: The subscription is terminated and will not be billed anymore.
     * * `offer`: The subscription is an offer and waiting for acceptance.
     */
    export enum status {
        DRAFT = 'draft',
        ACTIVE = 'active',
        PAUSED = 'paused',
        OFFER = 'offer',
        CANCELLED = 'cancelled',
        TERMINATED = 'terminated',
    }
}

