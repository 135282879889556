/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaymentMethod_SetupPaymentMethodResult } from './PaymentMethod_SetupPaymentMethodResult';
export type PaymentMethod_PaymentMethodResultOutput_SetupPaymentMethodResult = {
    /**
     *
     *
     * Possible values:
     * * `action_required`: The payment method requires an action to be taken by the customer
     * * `success`: The payment method was successfully created
     * * `failed`: The payment method could not be created
     */
    status?: PaymentMethod_PaymentMethodResultOutput_SetupPaymentMethodResult.status;
    /**
     * The action required if the payment method requires an action to be taken by the customer
     *
     * * redirect_to_gateway: The customer needs to be redirected to the payment gateway
     * * client_site_action: The customer needs to take an action on the client site
     */
    actionRequired?: {
        action?: PaymentMethod_PaymentMethodResultOutput_SetupPaymentMethodResult.action;
        /**
         * The URL to redirect the customer to, if the action is `redirect_to_gateway`
         */
        redirectUrl?: string | null;
        /**
         * The details of the action required, if the action is `client_site_action`
         */
        details?: {
            /**
             * The client secret to use for stripe.js, if the payment gateway is `stripe` and the type is `card`
             */
            clientSecret?: string | null;
            /**
             * The publishable key to use for stripe.js, if the payment gateway is `stripe` and the type is `card`
             */
            publishableKey?: string | null;
            /**
             * The account id to use for stripe.js, if the payment gateway is `stripe` and the type is `card`
             */
            accountId?: string | null;
        } | null;
    } | null;
    /**
     * The fail reason if status is `failed`
     */
    failReason?: string | null;
    /**
     * The created payment method, if status is `success`
     */
    paymentMethod?: PaymentMethod_SetupPaymentMethodResult | null;
};
export namespace PaymentMethod_PaymentMethodResultOutput_SetupPaymentMethodResult {
    /**
     *
     *
     * Possible values:
     * * `action_required`: The payment method requires an action to be taken by the customer
     * * `success`: The payment method was successfully created
     * * `failed`: The payment method could not be created
     */
    export enum status {
        ACTION_REQUIRED = 'action_required',
        SUCCESS = 'success',
        FAILED = 'failed',
    }
    export enum action {
        REDIRECT_TO_GATEWAY = 'redirect_to_gateway',
        CLIENT_SITE_ACTION = 'client_site_action',
    }
}

