/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PricePlan_PlanUpdate } from './PricePlan_PlanUpdate';
import type { Product_PlanUpdate } from './Product_PlanUpdate';
export type PlanChargeResource_PlanUpdate = {
    id?: string | null;
    /**
     * The event on which the charge is applied.
     *
     * Possible values:
     * * `on_subscription_start`: The charge is applied when the subscription starts.
     * * `on_subscription_end`: The charge is applied when the subscription ends.
     * * `on_subscription_trial_start`: The charge is applied when the subscription trial starts.
     * * `on_subscription_trial_end`: The charge is applied when the subscription trial ends.
     * * `on_checkout_payment`: The charge is applied when the checkout payment is made.
     */
    onEvent?: PlanChargeResource_PlanUpdate.onEvent;
    /**
     * Whether the charge should only be applied once on the event. Defaults to false.
     */
    onceOnEvent?: boolean;
    /**
     * Whether the charge should be alligned to the billing interval of the plan. Defaults to true.
     */
    allignBillingInterval?: boolean;
    /**
     * The price plans of the charge. If the charge is alligned to the billing interval of the plan, the price plans must have the same billing interval as the plan. Otherwise you need to provide only a single charge. All other will be ignored.
     */
    pricePlans?: Array<PricePlan_PlanUpdate>;
    /**
     * The product representing the charge.
     */
    product?: Product_PlanUpdate;
};
export namespace PlanChargeResource_PlanUpdate {
    /**
     * The event on which the charge is applied.
     *
     * Possible values:
     * * `on_subscription_start`: The charge is applied when the subscription starts.
     * * `on_subscription_end`: The charge is applied when the subscription ends.
     * * `on_subscription_trial_start`: The charge is applied when the subscription trial starts.
     * * `on_subscription_trial_end`: The charge is applied when the subscription trial ends.
     * * `on_checkout_payment`: The charge is applied when the checkout payment is made.
     */
    export enum onEvent {
        ON_SUBSCRIPTION_START = 'on_subscription_start',
        ON_SUBSCRIPTION_END = 'on_subscription_end',
        ON_SUBSCRIPTION_TRIAL_START = 'on_subscription_trial_start',
        ON_SUBSCRIPTION_TRIAL_END = 'on_subscription_trial_end',
        ON_CHECKOUT_PAYMENT = 'on_checkout_payment',
    }
}

