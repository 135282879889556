/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProductSelection } from './ProductSelection';
import type { SubscriptionPhaseTermInput } from './SubscriptionPhaseTermInput';
export type SubscriptionPhaseInput = {
    id?: string | null;
    from?: string;
    to?: string | null;
    prorationBehavior?: SubscriptionPhaseInput.prorationBehavior | null;
    term?: SubscriptionPhaseTermInput | null;
    products?: Array<ProductSelection>;
};
export namespace SubscriptionPhaseInput {
    export enum prorationBehavior {
        NONE = 'none',
        NEXT_INVOICE = 'next_invoice',
        PHASE_BEGIN = 'phase_begin',
        IMMEDIATE = 'immediate',
    }
}

