/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PaymentGatewaySetting_PaymentGatewayRead = {
    readonly id?: string;
    /**
     *
     *
     * Possible values:
     * * `paypal`: PayPal (supports "paypal")
     * * `stripe`: Stripe (supports "card")
     * * `testing`: Testing (supports all)
     * * `go_cardless`: GoCardless (supports "sepa_debit")
     * * `bank_transfer`: Bank Transfer (supports "bank_transfer")
     * * `sepa_debit`: SEPA Credit and Debit with xml file (supports "sepa_debit")
     * * `wallet`: Credit Balance from Wallet (supports "wallet")
     */
    readonly name?: PaymentGatewaySetting_PaymentGatewayRead.name;
    /**
     * Whether the payment gateway is in sandbox mode.
     */
    readonly isSandbox?: boolean;
    /**
     * The status of the payment gateway in the remote payment gateway. This is not always required, and is only used for certain payment gateways.
     *
     * Possible values:
     * * `active`: The payment gateway is successful configured and ready to use.
     * * `onboarding`: The payment gateway is in the onboarding process. You need to complete the onboarding process to use the payment gateway.
     * * `details_submitted`: The required details in the onboarding process are submitted. The payment gateway is waiting for the approval, by the payment provider.
     * * `declined`: The payment gateway is declined by the payment provider. You need to reconnect the payment gateway.
     */
    readonly remoteStatus?: PaymentGatewaySetting_PaymentGatewayRead.remoteStatus | null;
    /**
     * Whether the payment gateway is enabled. If a payment gateway is enabled, it can be configured for use in the checkout, etc. See checkout settings for more details.
     */
    readonly isEnabled?: boolean;
    /**
     * The account assignment, which is used for correct accounting. If none is provided, no accounting will be done for this payment gateway.
     */
    readonly accountAssignmentId?: string | null;
    /**
     * Whether the payment gateway is connected by partner integration or by own credentials. Credential based is legacy and will be removed in the future.
     */
    readonly isPartnerConnected?: boolean;
    /**
     * PayPal specific data
     */
    readonly paypalData?: Array<string> | null;
};
export namespace PaymentGatewaySetting_PaymentGatewayRead {
    /**
     *
     *
     * Possible values:
     * * `paypal`: PayPal (supports "paypal")
     * * `stripe`: Stripe (supports "card")
     * * `testing`: Testing (supports all)
     * * `go_cardless`: GoCardless (supports "sepa_debit")
     * * `bank_transfer`: Bank Transfer (supports "bank_transfer")
     * * `sepa_debit`: SEPA Credit and Debit with xml file (supports "sepa_debit")
     * * `wallet`: Credit Balance from Wallet (supports "wallet")
     */
    export enum name {
        PAYPAL = 'paypal',
        STRIPE = 'stripe',
        TESTING = 'testing',
        GO_CARDLESS = 'go_cardless',
        BANK_TRANSFER = 'bank_transfer',
        SEPA_DEBIT = 'sepa_debit',
        WALLET = 'wallet',
    }
    /**
     * The status of the payment gateway in the remote payment gateway. This is not always required, and is only used for certain payment gateways.
     *
     * Possible values:
     * * `active`: The payment gateway is successful configured and ready to use.
     * * `onboarding`: The payment gateway is in the onboarding process. You need to complete the onboarding process to use the payment gateway.
     * * `details_submitted`: The required details in the onboarding process are submitted. The payment gateway is waiting for the approval, by the payment provider.
     * * `declined`: The payment gateway is declined by the payment provider. You need to reconnect the payment gateway.
     */
    export enum remoteStatus {
        ACTIVE = 'active',
        ONBOARDING = 'onboarding',
        DETAILS_SUBMITTED = 'details_submitted',
        DECLINED = 'declined',
    }
}

