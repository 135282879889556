/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SepaMandate_InvoiceDetail } from './SepaMandate_InvoiceDetail';
export type PaymentMethod_InvoiceDetail = {
    readonly id?: string;
    gatewayName?: PaymentMethod_InvoiceDetail.gatewayName;
    type?: PaymentMethod_InvoiceDetail.type | null;
    referenceId?: string | null;
    default?: boolean;
    status?: PaymentMethod_InvoiceDetail.status;
    source?: PaymentMethod_InvoiceDetail.source;
    enabled?: boolean;
    name?: string | null;
    data?: Array<string>;
    readonly creationDate?: string;
    expirationDate?: string | null;
    /**
     * The sepa mandate of the payment method, if the gatewayName is "payment_sepa"
     */
    readonly sepaMandate?: SepaMandate_InvoiceDetail | null;
    /**
     * The card data of the payment method, if the type is "card"
     */
    readonly card?: {
        /**
         * The brand of the card
         */
        brand?: string;
        /**
         * The last 4 digits of the card
         */
        last4?: string;
        /**
         * The expiration month of the card
         */
        expMonth?: number;
        /**
         * The expiration year of the card
         */
        expYear?: number;
        /**
         * The card holder name
         */
        cardHolder?: string;
    } | null;
    /**
     * The sepa mandate data of the payment method, if the type is "sepa_debit"
     */
    readonly sepaDebit?: {
        /**
         * The iban of the sepa mandate
         */
        iban?: string;
        /**
         * The bic of the sepa mandate
         */
        bic?: string;
        /**
         * The mandate reference of the sepa mandate
         */
        mandateReference?: string;
        /**
         * The signing date of the sepa mandate
         */
        signingDate?: string;
    } | null;
    /**
     * The paypal data of the payment method, if the type is "paypal"
     */
    readonly paypal?: {
        /**
         * The email of the paypal account
         */
        email?: string;
        /**
         * The billing agreement id of the paypal account
         */
        billingAgreementId?: string;
    } | null;
};
export namespace PaymentMethod_InvoiceDetail {
    export enum gatewayName {
        WALLET = 'wallet',
        SEPA_DEBIT = 'sepa_debit',
        STRIPE = 'stripe',
        FAKE_PROVIDER = 'fake_provider',
        PAYPAL = 'paypal',
        BANK_TRANSFER = 'bank_transfer',
        GO_CARDLESS = 'go_cardless',
        TESTING = 'testing',
    }
    export enum type {
        SEPA_DEBIT = 'sepa_debit',
        CARD = 'card',
        PAYPAL = 'paypal',
        BANK_TRANSFER = 'bank_transfer',
        WALLET = 'wallet',
        FAKE_PROVIDER = 'fake_provider',
    }
    export enum status {
        ACTIVE = 'active',
        ACTION_REQUIRED = 'action_required',
        EXPIRED = 'expired',
        REVOKED = 'revoked',
        GATEWAY_UNCONFIGURED = 'gateway_unconfigured',
    }
    export enum source {
        CHECKOUT = 'checkout',
        CUSTOMERFRONT = 'customerfront',
        API = 'api',
        ADD_LINK = 'add_link',
    }
}

