/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type BillingGroup_CreateBillingGroup = {
    /**
     * The customer of the billing group.
     */
    customer: string;
    /**
     * The name of the billing group.
     */
    name: string;
    /**
     * The type of the billing group.
     */
    type: BillingGroup_CreateBillingGroup.type;
    /**
     * The custom day of the billing group, only used when type is "custom".
     */
    customDay: number | null;
    /**
     * The custom month of the billing group, only used when type is "custom".
     */
    customMonth: number | null;
};
export namespace BillingGroup_CreateBillingGroup {
    /**
     * The type of the billing group.
     */
    export enum type {
        START_OF_MONTH = 'start_of_month',
        END_OF_MONTH = 'end_of_month',
        START_OF_YEAR = 'start_of_year',
        END_OF_YEAR = 'end_of_year',
        CUSTOM = 'custom',
    }
}

