/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { User_UserMeResponse_AccountDetailsRole } from './User_UserMeResponse_AccountDetailsRole';
export type Account_AccountDetailsRole = {
    readonly id?: string;
    readonly user?: User_UserMeResponse_AccountDetailsRole;
    readonly role?: Account_AccountDetailsRole.role;
    createdAt?: string;
    readonly deactivated?: boolean;
};
export namespace Account_AccountDetailsRole {
    export enum role {
        ADMINISTRATOR = 'administrator',
        ACCOUNT = 'account',
        READ_ONLY = 'read-only',
    }
}

