/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Measurement_PlanUpdate } from './Measurement_PlanUpdate';
import type { PlanChargeResource_PlanUpdate } from './PlanChargeResource_PlanUpdate';
import type { PricePlan_PlanUpdate } from './PricePlan_PlanUpdate';
import type { ProductSetResource_PlanUpdate } from './ProductSetResource_PlanUpdate';
import type { TaxGroup_PlanUpdate } from './TaxGroup_PlanUpdate';
export type Plan_PlanResource_PlanUpdate = {
    id?: string | null;
    family: string;
    /**
     * The tax group of the plan. This does not applicate to the addons and charges.
     * If a product is provided, this value is ignored.
     */
    taxGroup?: TaxGroup_PlanUpdate | null;
    /**
     * The measurement of the plan. This does not applicate to the addons and charges.
     * If a product is provided, this value is ignored.
     */
    measurement?: Measurement_PlanUpdate | null;
    /**
     * The code of the plan. This is used to identify the plan e.g. in the checkout url.
     */
    code: string;
    /**
     * The name of the plan. If a product is provided, this value is not required.
     */
    name?: string;
    /**
     * The description of the plan shown in the checkout. If a product is provided, this value is not required.
     */
    description?: string | null;
    /**
     * Define the allowed actions after subscription by customer, for upgrade / downgrade.
     */
    changeBehaviour: Plan_PlanResource_PlanUpdate.changeBehaviour;
    /**
     * Define the behaviour when a plan change will be applied. If null the default behaviour will be used.
     */
    changeApplyBehaviour: Plan_PlanResource_PlanUpdate.changeApplyBehaviour;
    /**
     * Allow to checkout this plan. If this is set to false, the plan will not be available in the checkout and can only be assigned to a subscription by the API or in the admin panel. The plan.code is a part of the checkout url.
     */
    allowCheckout?: boolean;
    /**
     * The prices of the item. You can define multiple prices here for different billing intervals.
     */
    pricePlans?: Array<PricePlan_PlanUpdate>;
    /**
     * The product sets of the plan.
     */
    productSets?: Array<ProductSetResource_PlanUpdate>;
    /**
     * The charges of the plan.
     */
    charges?: Array<PlanChargeResource_PlanUpdate>;
};
export namespace Plan_PlanResource_PlanUpdate {
    /**
     * Define the allowed actions after subscription by customer, for upgrade / downgrade.
     */
    export enum changeBehaviour {
        UPGRADEABLE = 'upgradeable',
        DOWNGRADEABLE = 'downgradeable',
        UPGRADEABLE_AND_DOWNGRADEABLE = 'upgradeable_and_downgradeable',
        NOT_CHANGEABLE = 'not_changeable',
    }
    /**
     * Define the behaviour when a plan change will be applied. If null the default behaviour will be used.
     */
    export enum changeApplyBehaviour {
        IMMEDIATELY = 'immediately',
        END_OF_PERIOD = 'end_of_period',
    }
}

