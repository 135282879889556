/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type OfferRecipient_OfferRead = {
    readonly id?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    role?: OfferRecipient_OfferRead.role;
    readonly createdAt?: string;
    readonly signingStatus?: OfferRecipient_OfferRead.signingStatus;
    readonly signingLog?: Array<string>;
    readonly signedDocument?: string | null;
    /**
     * Link to the offer for the recipient
     */
    readonly link?: string;
};
export namespace OfferRecipient_OfferRead {
    export enum role {
        READ = 'read',
        SIGN = 'sign',
    }
    export enum signingStatus {
        PENDING = 'pending',
        STARTED = 'started',
        SIGNED = 'signed',
        NOT_STARTED = 'not_started',
        ARCHIVED = 'archived',
    }
}

