/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CartItemPriceResource_CartPublic } from './CartItemPriceResource_CartPublic';
import type { CartItemQuantityResource_CartPublic } from './CartItemQuantityResource_CartPublic';
import type { CartItemTermResource_CartPublic } from './CartItemTermResource_CartPublic';
export type CartItemResource_CartPublic = {
    id?: string;
    name?: string;
    description?: string | null;
    /**
     * The quantity as float, e.g. 1.5
     */
    quantity?: number;
    quantityDetails?: CartItemQuantityResource_CartPublic;
    price?: CartItemPriceResource_CartPublic;
    type?: CartItemResource_CartPublic.type;
    periods?: Array<CartItemTermResource_CartPublic>;
};
export namespace CartItemResource_CartPublic {
    export enum type {
        PRODUCT = 'product',
    }
}

