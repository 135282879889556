import { ofetch } from 'ofetch';
import { usePermissions } from '~/composables/usePermissions';

export const useAuthApi = () => {
  return {
    refresh: async (
      host: string,
      refreshToken: string
    ): Promise<{
      token: string;
      refreshToken: string;
    }> => {
      const data = await ofetch(host + '/auth/token/refresh', {
        method: 'POST',
        body: JSON.stringify({ refresh_token: refreshToken }),
        retry: 3,
        retryStatusCodes: [500, 502, 503, 504],
        retryDelay: 500,
      }).catch((error) => {
        if (import.meta.browser) {
          if (error.response?._data?.message === 'Invalid JWT Refresh Token') {
            window.location.href = '/login?refreshTokenExpired=true';
            // navigateTo('/login?refreshTokenExpired=true');
            return;
          }
        }


        if (error.response?._data?.message === 'Invalid JWT Refresh Token') {
          throw new Error('Expired refresh token');
          return;
        }

        throw error;
      });

      if (!data?.token || !data?.refresh_token) {
        throw new Error('Invalid refresh token');
      }

      return {
        token: data.token,
        refreshToken: data.refresh_token,
      };
    },
    login: async (
      username: string,
      password: string
    ): Promise<{
      token: string;
      refreshToken: string;
    }> => {
      const data = await $fetch('/api/v1/auth/login', {
        method: 'POST',
        body: JSON.stringify({ email: username, password }),
      });

      if (!data?.token || !data?.refresh_token) {
        throw new Error('Invalid credentials');
      }

      return {
        token: data.token,
        refreshToken: data.refresh_token,
      };
    },
    getUser: async (signal: any): Promise<{
      user?: {
        id: string;
        firstName: string;
        lastName: string;
        email: string;
      };
      account?: {
        id: string;
      };
      tenant?: {
        id: string;
        name: string;
        username: string;
      };
      defaults?: {
        taxGroupId?: string;
        unitId?: string;
      };
      roles?: Array<string>;
      permissions?: Array<string>;
      gateways?: Array<string>;
      availableGateways?: Array<string>;
      features?: {
        [key: 'crm'|string]: boolean;
      };
      onboardingRequired: boolean;
      getStartedFinished: boolean;
      isConfirmed: boolean;
      customFields?: {
        [key: 'customer' | 'product' | 'subscription' | string]: {
          [key: string]: {
            label: string;
            type: 'text' | 'number' | 'datetime' | 'select' | 'boolean';
            required: boolean;
            choices?: Array<string>;
          };
        };
      };
    } | null> => {
      if (process.server) return null;

      const data = await $fetch('/api/v1/ui/config', {
        signal,
      });
      const { refreshPermissions } = usePermissions();
      await refreshPermissions(data.permissions);

      if (!data?.user) {
        return null;
      }

      // @ts-ignore
      return data;
    },
  };
};
