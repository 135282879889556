/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApplicatedExchangeRate_InvoiceDetail } from './ApplicatedExchangeRate_InvoiceDetail';
import type { Customer_InvoiceDetail } from './Customer_InvoiceDetail';
import type { Invoice_InvoiceDetail } from './Invoice_InvoiceDetail';
import type { InvoiceAddress_InvoiceDetail } from './InvoiceAddress_InvoiceDetail';
import type { InvoicePositionItem_InvoiceDetail } from './InvoicePositionItem_InvoiceDetail';
import type { Media_InvoiceDetail } from './Media_InvoiceDetail';
import type { PaymentMethod_InvoiceDetail } from './PaymentMethod_InvoiceDetail';
export type InvoicePositionItem_Invoice_InvoiceDetail = {
    /**
     * The unique identifier of the invoice.
     */
    readonly id?: string;
    /**
     * The customer of the invoice.
     */
    customer?: Customer_InvoiceDetail;
    /**
     * The payment method which should be used to fullfill the payment of the invoice.
     */
    paymentMethod?: PaymentMethod_InvoiceDetail | null;
    /**
     * The referenced invoice of the invoice. This could be in case of a reminder, refund etc.
     */
    referencedInvoice?: Invoice_InvoiceDetail | null;
    /**
     * The type of the invoice.
     */
    type?: InvoicePositionItem_Invoice_InvoiceDetail.type;
    /**
     * The currency code of the invoice. If multiple currency codes will be used in the positions, they will be converted to that base currency.
     */
    currencyCode?: string;
    /**
     * The document id of the invoice. This will be generated, when the invoice will be finalized.
     */
    readonly number?: string | null;
    readonly status?: InvoicePositionItem_Invoice_InvoiceDetail.status;
    readonly creationDate?: string;
    readonly finalizationDate?: string | null;
    dueDate?: string | null;
    readonly lastReminderDate?: string | null;
    readonly lastSentAt?: string | null;
    readonly dunningLevel?: number;
    readonly dunningStatus?: InvoicePositionItem_Invoice_InvoiceDetail.dunningStatus;
    readonly payDate?: string | null;
    /**
     * The invoice address of the invoice.
     */
    readonly invoiceAddress?: InvoiceAddress_InvoiceDetail | null;
    /**
     * The notice of the invoice.
     * @deprecated
     */
    internalNote?: string | null;
    readonly title?: string | null;
    readonly introduction?: string | null;
    readonly closing?: string | null;
    /**
     * The net amount of the invoice.
     */
    readonly netAmount?: {
        /**
         * The amount as big integer, e.g. 1000 = 10.00 (precision = 2), 10000 = 10.000 (precision = 3)
         */
        readonly amount?: number;
        /**
         * The precision of the amount, which is the number of digits after the decimal separator, e.g. 2 = 10.00, 3 = 10.000
         */
        readonly precision?: number;
        /**
         * The currency code, e.g. EUR, USD, CHF
         */
        currency: string;
        /**
         * The formatted amount, e.g. 10.00 €, 10.00 $, 10.00 CHF
         */
        readonly i18n?: string;
        /**
         * Amount which can be used as input value, e.g. 10.00, 10.000.
         */
        inputValue: string;
    };
    readonly grossAmount?: {
        /**
         * The amount as big integer, e.g. 1000 = 10.00 (precision = 2), 10000 = 10.000 (precision = 3)
         */
        readonly amount?: number;
        /**
         * The precision of the amount, which is the number of digits after the decimal separator, e.g. 2 = 10.00, 3 = 10.000
         */
        readonly precision?: number;
        /**
         * The currency code, e.g. EUR, USD, CHF
         */
        currency: string;
        /**
         * The formatted amount, e.g. 10.00 €, 10.00 $, 10.00 CHF
         */
        readonly i18n?: string;
        /**
         * Amount which can be used as input value, e.g. 10.00, 10.000.
         */
        inputValue: string;
    };
    readonly taxAmount?: {
        /**
         * The amount as big integer, e.g. 1000 = 10.00 (precision = 2), 10000 = 10.000 (precision = 3)
         */
        readonly amount?: number;
        /**
         * The precision of the amount, which is the number of digits after the decimal separator, e.g. 2 = 10.00, 3 = 10.000
         */
        readonly precision?: number;
        /**
         * The currency code, e.g. EUR, USD, CHF
         */
        currency: string;
        /**
         * The formatted amount, e.g. 10.00 €, 10.00 $, 10.00 CHF
         */
        readonly i18n?: string;
        /**
         * Amount which can be used as input value, e.g. 10.00, 10.000.
         */
        inputValue: string;
    };
    /**
     * The total net discount amount of the invoice.
     */
    readonly discountAmount?: {
        /**
         * The amount as big integer, e.g. 1000 = 10.00 (precision = 2), 10000 = 10.000 (precision = 3)
         */
        readonly amount?: number;
        /**
         * The precision of the amount, which is the number of digits after the decimal separator, e.g. 2 = 10.00, 3 = 10.000
         */
        readonly precision?: number;
        /**
         * The currency code, e.g. EUR, USD, CHF
         */
        currency: string;
        /**
         * The formatted amount, e.g. 10.00 €, 10.00 $, 10.00 CHF
         */
        readonly i18n?: string;
        /**
         * Amount which can be used as input value, e.g. 10.00, 10.000.
         */
        inputValue: string;
    } | null;
    readonly file?: Media_InvoiceDetail | null;
    /**
     * The xml representation of the invoice in EN16931 or x-Rechnung format.
     */
    readonly xmlFile?: Media_InvoiceDetail | null;
    /**
     * The positions of the invoice.
     */
    readonly positions?: Array<InvoicePositionItem_InvoiceDetail>;
    /**
     * The applicated exchange rates of the invoice, fetched from the european central bank.
     */
    readonly applicatedExchangeRates?: Array<ApplicatedExchangeRate_InvoiceDetail>;
    /**
     * The einvoice generated xml file format.
     */
    eInvoiceType?: string | null;
    /**
     * The leitwegid used for einvoice. This is only set, when the eInvoiceType is not null.
     */
    leitwegId?: string | null;
    /**
     * Custom fields for the entity. The keys are the field names and the values are the field values. They need to be configured under "/custom-fields" in the API documentation.
     */
    customFields?: Array<string> | null;
    /**
     * The earliest service date of all positions.
     */
    readonly serviceDateFrom?: string | null;
    /**
     * The latest service date of all positions.
     */
    readonly serviceDateTo?: string | null;
    readonly unpaidAmount?: {
        /**
         * The amount as big integer, e.g. 1000 = 10.00 (precision = 2), 10000 = 10.000 (precision = 3)
         */
        readonly amount?: number;
        /**
         * The precision of the amount, which is the number of digits after the decimal separator, e.g. 2 = 10.00, 3 = 10.000
         */
        readonly precision?: number;
        /**
         * The currency code, e.g. EUR, USD, CHF
         */
        currency: string;
        /**
         * The formatted amount, e.g. 10.00 €, 10.00 $, 10.00 CHF
         */
        readonly i18n?: string;
        /**
         * Amount which can be used as input value, e.g. 10.00, 10.000.
         */
        inputValue: string;
    };
    /**
     * Public url to view the detailed usage breakdown of this invoice.
     */
    readonly usageBreakdownUrl?: string | null;
    /**
     * The referenced cancellation document.
     */
    readonly cancellationDocument?: {
        number: string;
        id: string;
    } | null;
    /**
     * Dunning is disabled for this invoice.
     */
    readonly dunningDisabled?: boolean;
    /**
     * The total amount of fees for each payment provider, which charges fees.
     */
    readonly paymentProviderFeeCents?: Record<string, number>;
    /**
     * Where the invoice was created from.
     *
     * Possible values:
     * * `cart`: The invoice was created from a cart.
     * * `subscription`: The invoice was created from a subscription billing run.
     * * `manual`: The invoice was created by a user.
     */
    readonly sourceType?: InvoicePositionItem_Invoice_InvoiceDetail.sourceType;
    /**
     * The referenced credit notes.
     */
    readonly creditNotes?: Array<Invoice_InvoiceDetail>;
};
export namespace InvoicePositionItem_Invoice_InvoiceDetail {
    /**
     * The type of the invoice.
     */
    export enum type {
        TYPE_INVOICE = 'TYPE_INVOICE',
        TYPE_CREDIT = 'TYPE_CREDIT',
        TYPE_REFUND = 'TYPE_REFUND',
        TYPE_REMINDER = 'TYPE_REMINDER',
        TYPE_CANCEL = 'TYPE_CANCEL',
        TYPE_DUNNING = 'TYPE_DUNNING',
    }
    export enum status {
        STATUS_DRAFT = 'STATUS_DRAFT',
        STATUS_PAID = 'STATUS_PAID',
        STATUS_CANCELLED = 'STATUS_CANCELLED',
        STATUS_CLOSED = 'STATUS_CLOSED',
        STATUS_REFUNDED = 'STATUS_REFUNDED',
        STATUS_REMINDED = 'STATUS_REMINDED',
        STATUS_UNPAID = 'STATUS_UNPAID',
        STATUS_NEW = 'STATUS_NEW',
        STATUS_FINALIZING = 'STATUS_FINALIZING',
    }
    export enum dunningStatus {
        REMINDER = 'reminder',
        DUNNING = 'dunning',
        NONE = 'none',
    }
    /**
     * Where the invoice was created from.
     *
     * Possible values:
     * * `cart`: The invoice was created from a cart.
     * * `subscription`: The invoice was created from a subscription billing run.
     * * `manual`: The invoice was created by a user.
     */
    export enum sourceType {
        MANUAL = 'manual',
        SUBSCRIPTION = 'subscription',
        CART = 'cart',
    }
}

