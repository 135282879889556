/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PlanFamily_CheckoutLinkRead } from './PlanFamily_CheckoutLinkRead';
export type Plan_PlanResource_CheckoutLinkRead = {
    id?: string | null;
    family: PlanFamily_CheckoutLinkRead;
    /**
     * The code of the plan. This is used to identify the plan e.g. in the checkout url.
     */
    code: string;
    /**
     * The name of the plan. If a product is provided, this value is not required.
     */
    name?: string;
    /**
     * The description of the plan shown in the checkout. If a product is provided, this value is not required.
     */
    description?: string | null;
    /**
     * Define the allowed actions after subscription by customer, for upgrade / downgrade.
     */
    changeBehaviour: Plan_PlanResource_CheckoutLinkRead.changeBehaviour;
    /**
     * Define the behaviour when a plan change will be applied. If null the default behaviour will be used.
     */
    changeApplyBehaviour: Plan_PlanResource_CheckoutLinkRead.changeApplyBehaviour;
};
export namespace Plan_PlanResource_CheckoutLinkRead {
    /**
     * Define the allowed actions after subscription by customer, for upgrade / downgrade.
     */
    export enum changeBehaviour {
        UPGRADEABLE = 'upgradeable',
        DOWNGRADEABLE = 'downgradeable',
        UPGRADEABLE_AND_DOWNGRADEABLE = 'upgradeable_and_downgradeable',
        NOT_CHANGEABLE = 'not_changeable',
    }
    /**
     * Define the behaviour when a plan change will be applied. If null the default behaviour will be used.
     */
    export enum changeApplyBehaviour {
        IMMEDIATELY = 'immediately',
        END_OF_PERIOD = 'end_of_period',
    }
}

