/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NotificationType_NotificationDetail } from './NotificationType_NotificationDetail';
export type SentNotification_NotificationDetail = {
    readonly id?: string;
    type?: NotificationType_NotificationDetail;
    channel?: SentNotification_NotificationDetail.channel;
    payload?: Array<string>;
    recipients?: Array<string>;
    status?: SentNotification_NotificationDetail.status;
    createdAt?: string;
    deliverAt?: string | null;
    sentAt?: string | null;
};
export namespace SentNotification_NotificationDetail {
    export enum channel {
        EMAIL = 'email',
        SMS = 'sms',
        SLACK = 'slack',
        LETTER = 'letter',
    }
    export enum status {
        SENT = 'sent',
        FAILED = 'failed',
        PENDING = 'pending',
    }
}

