/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Media_OfferDetail } from './Media_OfferDetail';
export type OfferRecipient_OfferDetail = {
    readonly id?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    role?: OfferRecipient_OfferDetail.role;
    readonly createdAt?: string;
    readonly signingStatus?: OfferRecipient_OfferDetail.signingStatus;
    readonly signingLog?: Array<string>;
    readonly signedDocument?: Media_OfferDetail | null;
    /**
     * Link to the offer for the recipient
     */
    readonly link?: string;
};
export namespace OfferRecipient_OfferDetail {
    export enum role {
        READ = 'read',
        SIGN = 'sign',
    }
    export enum signingStatus {
        PENDING = 'pending',
        STARTED = 'started',
        SIGNED = 'signed',
        NOT_STARTED = 'not_started',
        ARCHIVED = 'archived',
    }
}

