import { ErrorMessages, FormKitNode } from '@formkit/core';
import { useI18n } from 'vue-i18n';
import useModal from '~/composables/useModal';
import useMessage from '~/composables/useMessage';

export default function useForm() {
  const modal = useModal();
  const { t: translate } = useI18n();
  const message = useMessage();

  const waitUntilSubmitted = async (node: FormKitNode, showLoadingMessage = false): Promise<void> => {
    const isSubmitted = ref(false);

    if (node.props?.onSubmit) {
      const onSubmitHandler = node.props.onSubmit;
      node.props.onSubmit = (payload: any) => {
        // when the original onSubmit handler is called, we set the isSubmitted ref to true when it is a promise, then on finally
        // we set it to false again
        const result = onSubmitHandler(payload);
        if (result instanceof Promise) {
          result.finally(() => {
            isSubmitted.value = true;
          });
        }

        return result;
      };
    } else {
      node.props.onSubmit = async () => {
        isSubmitted.value = true;
      };
    }

    let loading = null;
    if (showLoadingMessage) {
      loading = modal.loading('Änderungen werden gespeichert...');
    }

    node.submit();

    await new Promise((resolve) => {
      watch(isSubmitted, (newValue) => {
        if (newValue) {
          resolve(undefined);
        }
      });
    });

    if (loading) {
      loading.close();
    }
  };

  const resetForm = (node: FormKitNode, toValue: any = undefined) => {
    node.reset(toValue);
  };

  const getTranslatedMessage = (violation: { propertyPath: string; message: string; code: string | null }) => {
    const translationKey = `api.error.${violation.code || violation.propertyPath || 'default'}`;
    const translation = translate(translationKey);
    let message = violation.message;
    if (translation !== translationKey) {
      message = translation;
    }

    return message;
  };

  const applyApiErrorViolations = (node: FormKitNode, violations: { propertyPath: string; message: string; code: string | null }[], propertyPathMap?: { [key: string]: string }) => {
    const errors: { [key: string]: string } = {};
    const globalErrors: ErrorMessages = [];

    violations.forEach((violation) => {
      const message = getTranslatedMessage(violation);

      if (violation.propertyPath === '' || violation.propertyPath === null) {
        globalErrors.push(message);
        return;
      }

      if (propertyPathMap && propertyPathMap[violation.propertyPath]) {
        errors[propertyPathMap[violation.propertyPath]] = message;
        return;
      }

      errors[violation.propertyPath] = message;
    });

    node.setErrors(globalErrors, errors);
  };

  const handleApiViolationError = (node: FormKitNode, error: any, withMessage = false, propertyPathMap?: { [key: string]: string } = undefined) => {
    if (error.response?._data?.type === 'https://tools.ietf.org/html/rfc2616#section-10') {
      const violations = error.response?._data?.violations || error.response.data;
      if (typeof violations !== 'object') {
        console.error('Unhandled API error', error);
        return false;
      }

      applyApiErrorViolations(node, violations, propertyPathMap);

      if (withMessage) message.warning('Es sind Fehler aufgetreten. Bitte überprüfe deine Eingaben.');

      return true;
    } else if (error.response?._data?.type === 'https://fynn.dev/problems/feature-not-available') {
      message.warning('Diese Funktion ist nicht freigeschaltet. Bitte wende dich an den Kundensupport.');

      return true;
    } else {
      console.error('Unhandled API error', error);

      return false;
    }
  };

  const randomFormId = () => {
    return 'form-' + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  };

  const restoreCachedFormData = (key: string, node: FormKitNode) => {
    if (import.meta.server) {
      console.error('localStorage is not available');
      return;
    }

    const cachedFormData = localStorage.getItem(key);
    if (cachedFormData) {
      node.reset(JSON.parse(cachedFormData));
      localStorage.removeItem(key);
    } else {
      console.log('No cached form data found for key', key);
    }
  };

  const nodeHandler = (node: FormKitNode, key: string) => {
    node.on('mounted', () => {
      restoreCachedFormData(key, node);
    });

    node.on('destroying', () => {
      if (import.meta.server) {
        console.error('localStorage is not available');
        return;
      }

      localStorage.removeItem(key);
    });
  };

  const submitHandler = (
    props: {
      route: string;
      method?: 'POST' | 'PUT' | 'PATCH';
      headers?: undefined | { [key: string]: string };
      customErrorHandler?: (error: any) => Promise<void> | void;
      successHandler?: (response: { data: any; status: number }) => Promise<void> | void;
      cacheOnAuthError?: {
        enabled: boolean;
        key: string;
      };
      formDataTransformer?: (formData: any) => any;
      propertyPathMap?: { [key: string]: string }; // map the property path to a different key in the form
    },
    formData: any,
    node: FormKitNode
  ) => {
    let body = JSON.stringify(formData);
    if (props.formDataTransformer) {
      body = JSON.stringify(props.formDataTransformer(formData));
    }

    return $fetch(props.route, {
      method: props?.method || 'POST',
      headers: props?.headers || undefined,
      body,
      async onResponse({ response }: any) {
        if (response.status === 200 || response.status === 201 || response.status === 204) {
          if (props.successHandler) {
            await props.successHandler({
              data: response._data,
              status: response.status,
            });
          }
        }

        if (response.status === 401 && props.cacheOnAuthError && props.cacheOnAuthError.enabled) {
          // Cache the form data in the local storage so we can restore it after the user logs in

          localStorage.setItem(props.cacheOnAuthError.key, JSON.stringify(formData));
        }
      },
    }).catch(async (error) => {
      const apiErrorsHandled = handleApiViolationError(node, error, false, props.propertyPathMap);

      if (props.customErrorHandler) {
        await props.customErrorHandler(error);
      } else if (!apiErrorsHandled) throw error;
    });
  };

  return {
    waitUntilSubmitted,
    resetForm,
    applyApiErrorViolations,
    handleApiViolationError,
    randomFormId,
    submitHandler,
    nodeHandler,
  };
}
