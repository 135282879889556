/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Get all entitlement features.
 */
export type Feature_FeatureRead = {
    /**
     * The identifier of the feature, this can be a slug or a id. If not provided, it will be generated automatically. This must be unique.
     */
    id?: string;
    /**
     * The name of the feature. Examples: `number of users`, `disk usage`, `traffic`, `CRM integration`, `API access`.
     */
    name?: string;
    /**
     * The description of the feature, shown in the admin-UI.
     */
    description?: string | null;
    /**
     * The status of the feature.
     * Draft: The feature is not yet published. Those can be used to create entitlements but they won't be available until activation.
     * Active: The feature is available and can be used.
     * Archived: The feature is no longer available and can't be used for **new** entitlements. Existing entitlements will still work. Archived features can be activated again.
     */
    status?: Feature_FeatureRead.status;
    /**
     * The type of the feature.
     * switch: The feature can be enabled or disabled for a subscription. You can add additional config options in the options key.
     * custom: The levels of the feature can be fully customized. As an example, you can define a feature `SLA` with 3 levels: `basic`, `premium`, `enterprise`.
     * quantity: The levels of the feature are based on a quantity. As an example, you can define a feature `number of users` with levels `5`, `10`, `25`, `50`, `100`. You can also provide a `unlimited` level.
     * range: The value can be customized in a given range. As an example, you can define a feature `disk usage` with a range from `1GB` to `1000GB`, you can also provide a unlimited level to allow all values.
     */
    type?: Feature_FeatureRead.type;
    /**
     * The config of the feature. This is only required for the types `custom`, `quantity` and `range`.
     */
    config?: Array<string>;
    readonly unit?: string | null;
};
export namespace Feature_FeatureRead {
    /**
     * The status of the feature.
     * Draft: The feature is not yet published. Those can be used to create entitlements but they won't be available until activation.
     * Active: The feature is available and can be used.
     * Archived: The feature is no longer available and can't be used for **new** entitlements. Existing entitlements will still work. Archived features can be activated again.
     */
    export enum status {
        DRAFT = 'draft',
        ACTIVE = 'active',
        ARCHIVED = 'archived',
    }
    /**
     * The type of the feature.
     * switch: The feature can be enabled or disabled for a subscription. You can add additional config options in the options key.
     * custom: The levels of the feature can be fully customized. As an example, you can define a feature `SLA` with 3 levels: `basic`, `premium`, `enterprise`.
     * quantity: The levels of the feature are based on a quantity. As an example, you can define a feature `number of users` with levels `5`, `10`, `25`, `50`, `100`. You can also provide a `unlimited` level.
     * range: The value can be customized in a given range. As an example, you can define a feature `disk usage` with a range from `1GB` to `1000GB`, you can also provide a unlimited level to allow all values.
     */
    export enum type {
        SWITCH = 'switch',
        CUSTOM = 'custom',
        QUANTITY = 'quantity',
        RANGE = 'range',
    }
}

