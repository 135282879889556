/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreditInvoicePositionRequest } from './CreditInvoicePositionRequest';
export type Invoice_CreditInvoiceRequest = {
    /**
     * The positions to cancel.
     */
    positions?: Array<CreditInvoicePositionRequest>;
    /**
     * Comment for the activity of crediting the invoice.
     */
    comment?: string | null;
    /**
     * The reason for crediting the invoice.
     */
    reason?: Invoice_CreditInvoiceRequest.reason;
    /**
     * The payment method for the credit note. The payment method must be valid and usable.
     */
    paymentMethod?: string;
    /**
     * The custom introduction for the credit note. It will be placed over the generic template.
     */
    customIntroduction?: string | null;
    /**
     * The custom closing for the credit note. It will be placed over the generic template.
     */
    customClosing?: string | null;
};
export namespace Invoice_CreditInvoiceRequest {
    /**
     * The reason for crediting the invoice.
     */
    export enum reason {
        DUPLICATE = 'duplicate',
        INCORRECT = 'incorrect',
        PRODUCT_UNSATISFACTORY = 'product_unsatisfactory',
        ORDER_CHANGE = 'order_change',
        OTHER = 'other',
    }
}

