/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type SubscriptionItem_InvoiceDetail = {
    readonly id?: string;
    /**
     * The name of the item which will be used in the invoice. The name is translated based on the customer's locale.
     */
    readonly name?: string;
    /**
     * The description of the item which will be used in the invoice. The description is translated based on the customer's locale.
     */
    readonly description?: string | null;
    /**
     * Further information defines additional information for the item which will be displayed in the invoice.
     */
    readonly furtherInformation?: string | null;
    /**
     * The status of the item.
     *
     * Possible values:
     * * `active`: The item is active and will be billed.
     * * `canceled`: The item is canceled and will be billed until the end of the contract.
     * * `pending`: The item is pending and will be billed after the contract start.
     * * `terminated`: The item is terminated and will not be billed.
     */
    readonly status?: SubscriptionItem_InvoiceDetail.status;
    readonly subscriptionId?: string;
};
export namespace SubscriptionItem_InvoiceDetail {
    /**
     * The status of the item.
     *
     * Possible values:
     * * `active`: The item is active and will be billed.
     * * `canceled`: The item is canceled and will be billed until the end of the contract.
     * * `pending`: The item is pending and will be billed after the contract start.
     * * `terminated`: The item is terminated and will not be billed.
     */
    export enum status {
        ACTIVE = 'active',
        CANCELED = 'canceled',
        PENDING = 'pending',
        TERMINATED = 'terminated',
    }
}

