/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Plan_PlanResource_CheckoutLinkRead } from './Plan_PlanResource_CheckoutLinkRead';
import type { PricePlan_CheckoutLinkRead } from './PricePlan_CheckoutLinkRead';
import type { Product_CheckoutLinkRead } from './Product_CheckoutLinkRead';
import type { Quantity_CheckoutLinkRead } from './Quantity_CheckoutLinkRead';
import type { TermPeriod_CheckoutLinkRead } from './TermPeriod_CheckoutLinkRead';
export type CheckoutLinkItem_CheckoutLinkRead = {
    /**
     * The type of the checkout link item.
     *
     * Possible values:
     * * `product`: A product will be added to the cart, with the given quantity.
     * * `plan`: A plan will be added to the cart, and can be configured by the customer.
     */
    readonly type?: CheckoutLinkItem_CheckoutLinkRead.type;
    /**
     * This is required when the type is "product".
     */
    readonly product?: Product_CheckoutLinkRead | null;
    /**
     * This is required when the type is "product".
     */
    readonly quantity?: Quantity_CheckoutLinkRead | null;
    /**
     * This is required when the type is "plan".
     */
    readonly plan?: Plan_PlanResource_CheckoutLinkRead | null;
    /**
     * This is required when the type is "product", "plan".
     */
    readonly price?: PricePlan_CheckoutLinkRead | null;
    /**
     * The contract period terms of the product item. If none provided, the billing period will be used as contract period, the cancellation period is set to 1 day.
     */
    readonly periods?: Array<TermPeriod_CheckoutLinkRead>;
};
export namespace CheckoutLinkItem_CheckoutLinkRead {
    /**
     * The type of the checkout link item.
     *
     * Possible values:
     * * `product`: A product will be added to the cart, with the given quantity.
     * * `plan`: A plan will be added to the cart, and can be configured by the customer.
     */
    export enum type {
        PRODUCT = 'product',
        PLAN = 'plan',
        OFFER = 'offer',
    }
}

