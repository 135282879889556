/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Billing_SubscriptionItemRead } from './Billing_SubscriptionItemRead';
import type { MeasurementValue_SubscriptionItemRead } from './MeasurementValue_SubscriptionItemRead';
import type { Plan_PlanResource_SubscriptionItemRead } from './Plan_PlanResource_SubscriptionItemRead';
import type { PricePlan_SubscriptionItemRead } from './PricePlan_SubscriptionItemRead';
import type { Product_SubscriptionItemRead } from './Product_SubscriptionItemRead';
import type { SubscriptionItemAppliedDiscount_SubscriptionItemRead } from './SubscriptionItemAppliedDiscount_SubscriptionItemRead';
import type { TermPeriod_SubscriptionItemRead } from './TermPeriod_SubscriptionItemRead';
export type SubscriptionItem_SubscriptionItemRead = {
    readonly id?: string;
    /**
     * The parent item of the item. If this is null, the item is a root item.
     */
    readonly parent?: SubscriptionItem_SubscriptionItemRead | null;
    /**
     * The plan this item is based on.
     */
    readonly plan?: Plan_PlanResource_SubscriptionItemRead | null;
    /**
     * Usage values during the subscription item period.
     */
    readonly measurementValues?: Array<MeasurementValue_SubscriptionItemRead>;
    /**
     * The billing details of the item.
     */
    readonly billing?: Billing_SubscriptionItemRead;
    /**
     * The name of the item which will be used in the invoice. The name is translated based on the customer's locale.
     */
    readonly name?: string;
    /**
     * The description of the item which will be used in the invoice. The description is translated based on the customer's locale.
     */
    readonly description?: string | null;
    /**
     * Further information defines additional information for the item which will be displayed in the invoice.
     */
    readonly furtherInformation?: string | null;
    /**
     * The price plan of the item, which defines the price and billing interval.
     */
    readonly pricePlan?: PricePlan_SubscriptionItemRead | null;
    /**
     * The status of the item.
     *
     * Possible values:
     * * `active`: The item is active and will be billed.
     * * `canceled`: The item is canceled and will be billed until the end of the contract.
     * * `pending`: The item is pending and will be billed after the contract start.
     * * `terminated`: The item is terminated and will not be billed.
     */
    readonly status?: SubscriptionItem_SubscriptionItemRead.status;
    /**
     * The product of the item.
     */
    readonly product?: Product_SubscriptionItemRead | null;
    readonly appliedDiscounts?: Array<SubscriptionItemAppliedDiscount_SubscriptionItemRead>;
    periods?: Array<TermPeriod_SubscriptionItemRead>;
    /**
     * The date the subscription item was activated.
     */
    readonly activatedAt?: string | null;
    /**
     * The type of the item.
     *
     * Possible values:
     * * `option`: The item is an product set option.
     * * `charge`: The item is a charge.
     * * `plan-product`: The item is a plan product.
     * * `product`: The item is a product, and not created from a plan.
     */
    readonly type?: SubscriptionItem_SubscriptionItemRead.type;
    /**
     * The current measurement value of the item.
     */
    readonly currentMeasurementValue?: MeasurementValue_SubscriptionItemRead | null;
    /**
     * Is the period inherited from the subscription?
     */
    readonly inheritedPeriod?: boolean;
};
export namespace SubscriptionItem_SubscriptionItemRead {
    /**
     * The status of the item.
     *
     * Possible values:
     * * `active`: The item is active and will be billed.
     * * `canceled`: The item is canceled and will be billed until the end of the contract.
     * * `pending`: The item is pending and will be billed after the contract start.
     * * `terminated`: The item is terminated and will not be billed.
     */
    export enum status {
        ACTIVE = 'active',
        CANCELED = 'canceled',
        PENDING = 'pending',
        TERMINATED = 'terminated',
    }
    /**
     * The type of the item.
     *
     * Possible values:
     * * `option`: The item is an product set option.
     * * `charge`: The item is a charge.
     * * `plan-product`: The item is a plan product.
     * * `product`: The item is a product, and not created from a plan.
     */
    export enum type {
        ADDON = 'addon',
        CHARGE = 'charge',
        PLAN_PRODUCT = 'plan-product',
        PRODUCT = 'product',
    }
}

