/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BillingInterval_SubscriptionItemRead } from './BillingInterval_SubscriptionItemRead';
import type { FlatFeePrice } from './FlatFeePrice';
import type { Interval_SubscriptionItemRead } from './Interval_SubscriptionItemRead';
import type { PercentagePrice } from './PercentagePrice';
import type { PerUnitPrice } from './PerUnitPrice';
import type { PlanCharge_SubscriptionItemRead } from './PlanCharge_SubscriptionItemRead';
import type { PlanProductSetOption_SubscriptionItemRead } from './PlanProductSetOption_SubscriptionItemRead';
import type { Quantity_SubscriptionItemRead } from './Quantity_SubscriptionItemRead';
import type { TieredPrice } from './TieredPrice';
export type PricePlan_SubscriptionItemRead = {
    /**
     * The unique identifier of the price plan.
     */
    readonly id?: string;
    /**
     * The internal name of the price plan.
     */
    internalName?: string | null;
    /**
     * The status of the price plan.
     *
     * Possible values:
     * * `active`: The price is active and can be used.
     * * `archived`: The price is archived and cannot be used anymore. It will be not shown in any lists until explicitly requested.
     */
    readonly status?: PricePlan_SubscriptionItemRead.status;
    /**
     * The type of the charge. This defines how the price is calculated.
     *
     * Possible values:
     * * `flat_fee`: A flat fee is charged, e.g. 10€ per month independent of the number of units.
     * * `volume`: A volume based price is charged, e.g. 10€ for the first 10 units and 9€ for all units when the number of units is above 10. Useful for volume discounts.
     * * `percentage`: A percentage of the total price is charged, e.g. 10% of the total price multiplied by the number of units. Useful for provisions.
     * * `per_unit`: A price per unit is charged, e.g. 10€ per unit per billing interval. Useful for per seat based pricing.
     * * `tiered`: A tiered price is charged. E.g. 10€ per unit for the first 10 units, 9€ per unit for the next 10 units and 8€ per unit for all units above 20.
     * * `stair_step`: A stair step price is charged. E.g. 10€ per unit for the first 10 units, 9€ per unit for all units above 10.
     */
    type: PricePlan_SubscriptionItemRead.type;
    /**
     * If this is set to true, the price plan can be applied on a trial. If the product is in trial, and this is false, the price will be charged on subscription start, otherwise after trial.
     */
    applyTrial?: boolean;
    /**
     * If this is set to true, the price will be charged in advance. If this is false, the price will be charged at the end of the billing interval.
     */
    payInAdvance?: boolean | null;
    /**
     * If this is set to true, the price will be charged prorated when a partial billing interval is billed. This applies to measurements of type "recurring" or non-one-time billing intervals. For other measurements this parameter will be ignored.
     */
    proRata?: boolean;
    /**
     * The amount of free units. If null, no free units are available. Free units will be applied before passed to the price calculation and are available prorated.
     * If the customer does not uses the free units during a billing period, they are not carried over to the next billing period.
     * If the customer cancels the subscription before ending the billing period, the free units are only available prorated for the remaining billing period.
     *
     *
     * **Example**: You offer 2400 free units for 12 months and your price has a billing interval for 1 month. The customer cancels after 6 months. Only 1200 free units are available.
     *
     * **A price plan for a recurring product cannot have free units. Use a metered product instead.**
     *
     * **A price plan related to a product of type charge cannot have free units.**
     */
    freeUnits?: Quantity_SubscriptionItemRead | null;
    /**
     * The billing interval of the price plan. If null, this is a one-time charge.
     * Billing intervals of null cannot be prorated.
     */
    billingInterval: BillingInterval_SubscriptionItemRead;
    /**
     * Display the billed price per interval in customerfront or invoices. If null, the price will be shown per billing interval.
     * Currently only available for billing intervals of months, years. Currently only allowed to show price per month (1M)
     */
    showPricePerInterval: Interval_SubscriptionItemRead;
    /**
     * The price in the defined billing interval.
     */
    price: (FlatFeePrice | PercentagePrice | PerUnitPrice | TieredPrice);
    /**
     * If this price plan is a custom price plan. A price plan is custom if it is defined specific for a plan addon, plan charge, customer or sales channel.
     */
    readonly custom?: boolean;
    /**
     * The charge related to this price plan.
     */
    charge?: PlanCharge_SubscriptionItemRead;
    /**
     * The product set option related to this price plan.
     */
    productSetOption?: PlanProductSetOption_SubscriptionItemRead;
    /**
     * If this price plan is in use.
     */
    readonly inUse?: boolean;
};
export namespace PricePlan_SubscriptionItemRead {
    /**
     * The status of the price plan.
     *
     * Possible values:
     * * `active`: The price is active and can be used.
     * * `archived`: The price is archived and cannot be used anymore. It will be not shown in any lists until explicitly requested.
     */
    export enum status {
        ACTIVE = 'active',
        ARCHIVED = 'archived',
    }
    /**
     * The type of the charge. This defines how the price is calculated.
     *
     * Possible values:
     * * `flat_fee`: A flat fee is charged, e.g. 10€ per month independent of the number of units.
     * * `volume`: A volume based price is charged, e.g. 10€ for the first 10 units and 9€ for all units when the number of units is above 10. Useful for volume discounts.
     * * `percentage`: A percentage of the total price is charged, e.g. 10% of the total price multiplied by the number of units. Useful for provisions.
     * * `per_unit`: A price per unit is charged, e.g. 10€ per unit per billing interval. Useful for per seat based pricing.
     * * `tiered`: A tiered price is charged. E.g. 10€ per unit for the first 10 units, 9€ per unit for the next 10 units and 8€ per unit for all units above 20.
     * * `stair_step`: A stair step price is charged. E.g. 10€ per unit for the first 10 units, 9€ per unit for all units above 10.
     */
    export enum type {
        FLAT_FEE = 'flat_fee',
        PER_UNIT = 'per_unit',
        TIERED = 'tiered',
        VOLUME = 'volume',
        STAIR_STEP = 'stair_step',
        PERCENTAGE = 'percentage',
    }
}

