/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Feature_CreateFeatureAssignmentInput } from '../models/Feature_CreateFeatureAssignmentInput';
import type { Feature_CreateFeatureInput } from '../models/Feature_CreateFeatureInput';
import type { Feature_FeatureAssignmentOutput_FeatureAssignmentRead } from '../models/Feature_FeatureAssignmentOutput_FeatureAssignmentRead';
import type { Feature_FeatureDetail } from '../models/Feature_FeatureDetail';
import type { Feature_FeatureRead } from '../models/Feature_FeatureRead';
import type { Feature_UpdateFeatureInput } from '../models/Feature_UpdateFeatureInput';
import type { Feature_UpdateFeatureStatusInput } from '../models/Feature_UpdateFeatureStatusInput';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class FeatureService {
    /**
     * Retrieves the collection of Feature resources.
     * Retrieves the collection of Feature resources.
     * @returns any Feature collection
     * @throws ApiError
     */
    public static getFeatureAssignments({
        feature,
        object,
        objectId,
        page = 1,
        limit = 30,
        id,
        idArray,
        name,
        description,
        status,
        statusArray,
        type,
        typeArray,
        search,
    }: {
        /**
         * The ID of the feature
         */
        feature: string,
        /**
         * The object type
         */
        object: string,
        /**
         * The ID of the object
         */
        objectId: string,
        /**
         * The collection page number
         */
        page?: number,
        /**
         * The number of items per page
         */
        limit?: number,
        id?: string,
        idArray?: Array<string>,
        name?: string,
        description?: string,
        status?: string,
        statusArray?: Array<string>,
        type?: string,
        typeArray?: Array<string>,
        search?: string,
    }): CancelablePromise<{
        data?: Array<Feature_FeatureAssignmentOutput_FeatureAssignmentRead>;
        meta?: {
            pagination?: {
                totalItems?: number;
                itemsPerPage?: number;
                currentPage?: number;
                lastPage?: number;
                pageTotalItems?: number;
            };
        };
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/entitlement/feature-assignments',
            query: {
                'feature': feature,
                'object': object,
                'objectId': objectId,
                'page': page,
                'limit': limit,
                'id': id,
                'id[]': idArray,
                'name': name,
                'description': description,
                'status': status,
                'status[]': statusArray,
                'type': type,
                'type[]': typeArray,
                'search': search,
            },
        });
    }
    /**
     * Creates a Feature resource.
     * Creates a Feature resource.
     * @returns Feature_FeatureAssignmentOutput_FeatureAssignmentRead Feature resource created
     * @throws ApiError
     */
    public static assignFeature({
        requestBody,
    }: {
        /**
         * The new Feature resource
         */
        requestBody: Feature_CreateFeatureAssignmentInput,
    }): CancelablePromise<Feature_FeatureAssignmentOutput_FeatureAssignmentRead> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/entitlement/feature-assignments',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                422: `Unprocessable entity`,
            },
        });
    }
    /**
     * Removes the Feature resource.
     * Unassign a feature. Existing entitlements **will not** be removed from the subscriptions.
     * @returns void
     * @throws ApiError
     */
    public static unassignFeature({
        id,
    }: {
        /**
         * Feature identifier
         */
        id: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/entitlement/feature-assignments/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }
    /**
     * Retrieves the collection of Feature resources.
     * Retrieves the collection of Feature resources.
     * @returns any Feature collection
     * @throws ApiError
     */
    public static getFeatures({
        page = 1,
        limit = 30,
        id,
        idArray,
        name,
        description,
        status,
        statusArray,
        type,
        typeArray,
        search,
    }: {
        /**
         * The collection page number
         */
        page?: number,
        /**
         * The number of items per page
         */
        limit?: number,
        id?: string,
        idArray?: Array<string>,
        name?: string,
        description?: string,
        status?: string,
        statusArray?: Array<string>,
        type?: string,
        typeArray?: Array<string>,
        search?: string,
    }): CancelablePromise<{
        data?: Array<Feature_FeatureRead>;
        meta?: {
            pagination?: {
                totalItems?: number;
                itemsPerPage?: number;
                currentPage?: number;
                lastPage?: number;
                pageTotalItems?: number;
            };
        };
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/entitlement/features',
            query: {
                'page': page,
                'limit': limit,
                'id': id,
                'id[]': idArray,
                'name': name,
                'description': description,
                'status': status,
                'status[]': statusArray,
                'type': type,
                'type[]': typeArray,
                'search': search,
            },
        });
    }
    /**
     * Creates a Feature resource.
     * Creates a Feature resource.
     * @returns Feature_FeatureDetail Feature resource created
     * @throws ApiError
     */
    public static createFeature({
        requestBody,
    }: {
        /**
         * The new Feature resource
         */
        requestBody: Feature_CreateFeatureInput,
    }): CancelablePromise<Feature_FeatureDetail> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/entitlement/features',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                422: `Unprocessable entity`,
            },
        });
    }
    /**
     * Retrieves a Feature resource.
     * Retrieves a Feature resource.
     * @returns Feature_FeatureDetail Feature resource
     * @throws ApiError
     */
    public static getFeature({
        id,
    }: {
        /**
         * Feature identifier
         */
        id: string,
    }): CancelablePromise<Feature_FeatureDetail> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/entitlement/features/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }
    /**
     * Replaces the Feature resource.
     * Replaces the Feature resource.
     * @returns Feature_FeatureDetail Feature resource updated
     * @throws ApiError
     */
    public static updateFeature({
        id,
        requestBody,
    }: {
        /**
         * Feature identifier
         */
        id: string,
        /**
         * The updated Feature resource
         */
        requestBody: Feature_UpdateFeatureInput,
    }): CancelablePromise<Feature_FeatureDetail> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/entitlement/features/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                404: `Resource not found`,
                422: `Unprocessable entity`,
            },
        });
    }
    /**
     * Replaces the Feature resource.
     * Replaces the Feature resource.
     * @returns Feature_FeatureRead Feature resource updated
     * @throws ApiError
     */
    public static updateFeatureStatus({
        id,
        requestBody,
    }: {
        /**
         * Feature identifier
         */
        id: string,
        /**
         * The updated Feature resource
         */
        requestBody: Feature_UpdateFeatureStatusInput,
    }): CancelablePromise<Feature_FeatureRead> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/entitlement/features/{id}/status',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                404: `Resource not found`,
                422: `Unprocessable entity`,
            },
        });
    }
}
