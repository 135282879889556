/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Account_AsyncTaskList } from './Account_AsyncTaskList';
import type { TaskItem_AsyncTaskList } from './TaskItem_AsyncTaskList';
/**
 * Get asynchronous tasks. Requires the type query parameter to be set, otherwise the result will be empty.
 */
export type Task_AsyncTaskList = {
    id?: string;
    type?: string;
    data?: Array<string>;
    items?: Array<TaskItem_AsyncTaskList>;
    createdBy?: Account_AsyncTaskList | null;
    /**
     * The date and time when the resource was created.
     */
    readonly createdAt?: string;
    /**
     * The date and time when the resource was last updated.
     */
    readonly updatedAt?: string;
    readonly status?: Task_AsyncTaskList.status;
};
export namespace Task_AsyncTaskList {
    export enum status {
        PENDING = 'pending',
        RUNNING = 'running',
        FINISHED = 'finished',
        FAILED = 'failed',
        CANCELLED = 'cancelled',
    }
}

