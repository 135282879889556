/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CartItemRecurringResource_CartPublic = {
    interval?: CartItemRecurringResource_CartPublic.interval;
    intervalCount?: number;
    trialPeriodDays?: number | null;
    recurringAmount?: number;
};
export namespace CartItemRecurringResource_CartPublic {
    export enum interval {
        DAY = 'day',
        WEEK = 'week',
        MONTH = 'month',
        YEAR = 'year',
    }
}

