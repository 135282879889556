/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CartItemRecurringResource_CartPublic } from './CartItemRecurringResource_CartPublic';
import type { FlatFeePrice_CartPublic } from './FlatFeePrice_CartPublic';
import type { Quantity_CartPublic } from './Quantity_CartPublic';
export type CartItemPriceResource_CartPublic = {
    /**
     * ISO 4217 currency code
     */
    currencyCode?: string;
    /**
     * If null, the customer has not yet provided a invoice address.
     */
    taxRate?: number | null;
    /**
     * The total net amount for this cart item which is now due.
     */
    totalNetAmount?: number;
    type?: CartItemPriceResource_CartPublic.type;
    calculationType?: CartItemPriceResource_CartPublic.calculationType;
    payInAdvance?: boolean;
    price?: FlatFeePrice_CartPublic;
    recurring?: CartItemRecurringResource_CartPublic | null;
    /**
     * The quantity as float, e.g. 1.5
     */
    freeUnits?: Quantity_CartPublic | null;
};
export namespace CartItemPriceResource_CartPublic {
    export enum type {
        RECURRING = 'recurring',
        METERED = 'metered',
        INSTANT_METERED = 'instant_metered',
    }
    export enum calculationType {
        FLAT_FEE = 'flat_fee',
        PER_UNIT = 'per_unit',
        TIERED = 'tiered',
        VOLUME = 'volume',
        STAIR_STEP = 'stair_step',
        PERCENTAGE = 'percentage',
    }
}

