import {defineStore} from "pinia";

export const useDataTableStore = defineStore('dataTable', {
  state: () => ({
    hiddenColumns: {} as Record<string, string[]>,
    showFilters: {} as Record<string, boolean>,
  }),
  actions: {
    initHiddenColumns(tableKey: string, columns: string[]) {
      if (!this.hiddenColumns[tableKey]) {
        this.hiddenColumns[tableKey] = columns;
      }
    },
    setHiddenColumns(tableKey: string, columns: string[]) {
      this.hiddenColumns[tableKey] = columns;
    },
    clearHiddenColumns(tableKey: string) {
      this.hiddenColumns[tableKey] = [];
    },
    toggleShowFilters(tableKey: string) {
      this.showFilters[tableKey] = !this.showFilters[tableKey];
    }
  },
  getters: {
    getHiddenColumns: (state) => (tableKey: string) => {
      return state.hiddenColumns[tableKey] || [];
    },
    getShowFilters: (state) => (tableKey: string) => {
      return state.showFilters[tableKey] || false;
    }
  },
  persist: true,
});
