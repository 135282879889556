/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NotificationType_notification_setting_read } from './NotificationType_notification_setting_read';
export type NotificationSetting_notification_setting_read = {
    readonly id?: string;
    /**
     * Notification type, e.g. "user.invited"
     */
    type?: NotificationType_notification_setting_read;
    /**
     * Is notification enabled?
     */
    isEnabled?: boolean;
    /**
     * Notification channel
     *
     * Possible values:
     * * `email`: E-Mail
     * * `sms`: SMS
     * * `slack`: Slack
     * * `letter`: Letter
     */
    channel?: NotificationSetting_notification_setting_read.channel;
    /**
     * Channel specific configuration
     */
    config?: Array<string> | null;
};
export namespace NotificationSetting_notification_setting_read {
    /**
     * Notification channel
     *
     * Possible values:
     * * `email`: E-Mail
     * * `sms`: SMS
     * * `slack`: Slack
     * * `letter`: Letter
     */
    export enum channel {
        EMAIL = 'email',
        SMS = 'sms',
        SLACK = 'slack',
        LETTER = 'letter',
    }
}

