/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DocumentFooter_TenantRead } from './DocumentFooter_TenantRead';
export type DocumentSettings_TenantRead = {
    readonly id?: string;
    /**
     * The position of the logo in the pdf documents.
     *
     * Possible values:
     * * `left`: The logo will be placed on the left side of the document.
     * * `right`: The logo will be placed on the right side of the document.
     * * `center`: The logo will be placed in the center of the document.
     */
    logoPosition: DocumentSettings_TenantRead.logoPosition;
    footer?: DocumentFooter_TenantRead | null;
};
export namespace DocumentSettings_TenantRead {
    /**
     * The position of the logo in the pdf documents.
     *
     * Possible values:
     * * `left`: The logo will be placed on the left side of the document.
     * * `right`: The logo will be placed on the right side of the document.
     * * `center`: The logo will be placed in the center of the document.
     */
    export enum logoPosition {
        LEFT = 'left',
        RIGHT = 'right',
        CENTER = 'center',
    }
}

