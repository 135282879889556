/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type Customer_SubscriptionDetail = {
    readonly id?: string;
    customerNumber?: string;
    /**
     * IANA time zone
     */
    readonly timeZone?: string;
    /**
     * Can be empty if the customer is a company.
     */
    firstName?: string | null;
    /**
     * Can be empty if the customer is a company.
     */
    lastName?: string | null;
    /**
     * Can be empty. In this case the default currency of the tenant is used.
     */
    currencyCode?: string;
    companyName?: string | null;
    /**
     * The ID of the customer in DATEV
     */
    datevId?: string | null;
    status?: Customer_SubscriptionDetail.status;
};
export namespace Customer_SubscriptionDetail {
    export enum status {
        STATUS_ACTIVE = 'STATUS_ACTIVE',
        STATUS_ARCHIVED = 'STATUS_ARCHIVED',
        STATUS_SUSPENDED = 'STATUS_SUSPENDED',
    }
}

