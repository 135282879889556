/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ContactPerson_OfferRead } from './ContactPerson_OfferRead';
import type { Customer_OfferRead } from './Customer_OfferRead';
import type { OfferRecipient_OfferRead } from './OfferRecipient_OfferRead';
import type { Subscription_OfferRead } from './Subscription_OfferRead';
export type Offer_OfferRead = {
    readonly id?: string;
    subscription?: Subscription_OfferRead | null;
    readonly customer?: Customer_OfferRead | null;
    number?: string;
    name?: string | null;
    readonly status?: Offer_OfferRead.status;
    description?: string | null;
    sections?: Array<string>;
    readonly locale?: string;
    readonly recipients?: Array<OfferRecipient_OfferRead>;
    readonly auditLogDocument?: string | null;
    readonly signedDocument?: string | null;
    readonly signedAt?: string | null;
    autoActivateSubscription?: boolean;
    readonly crmDealId?: string | null;
    /**
     * The date and time when the resource was created.
     */
    readonly createdAt?: string;
    /**
     * The date and time when the resource was last updated.
     */
    readonly updatedAt?: string;
    /**
     * The contact person of the offer
     */
    readonly contactPerson?: ContactPerson_OfferRead | null;
};
export namespace Offer_OfferRead {
    export enum status {
        OPEN = 'open',
        SIGNED = 'signed',
        SIGNING = 'signing',
        ARCHIVED = 'archived',
    }
}

