/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CostCentre = {
    readonly id?: string;
    /**
     * The name of the cost centre, which is displayed in the UI
     */
    name: string;
    /**
     * The code of the cost centre, which is used for exports
     */
    readonly code?: string;
    /**
     * The type of the cost centre, which is used for exports
     */
    type: CostCentre.type;
    /**
     * The status of the cost centre
     *
     * Possible values:
     * * `active`: The cost centre is active and can be used and referenced
     * * `archived`: The cost centre is archived and cannot be referenced anymore. It also disappears from the UI.
     */
    status: CostCentre.status;
    /**
     * The date and time when the cost centre was created
     */
    readonly createdAt?: string;
    /**
     * The date and time when the cost centre was last updated
     */
    readonly updatedAt?: string;
};
export namespace CostCentre {
    /**
     * The type of the cost centre, which is used for exports
     */
    export enum type {
        KOST1 = 'KOST1',
        KOST2 = 'KOST2',
    }
    /**
     * The status of the cost centre
     *
     * Possible values:
     * * `active`: The cost centre is active and can be used and referenced
     * * `archived`: The cost centre is archived and cannot be referenced anymore. It also disappears from the UI.
     */
    export enum status {
        ACTIVE = 'active',
        ARCHIVED = 'archived',
    }
}

