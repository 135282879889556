/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type OfferTemplate_OfferTemplateWrite = {
    name: string;
    sections?: Array<string>;
    type?: OfferTemplate_OfferTemplateWrite.type;
};
export namespace OfferTemplate_OfferTemplateWrite {
    export enum type {
        SECTION = 'section',
        PAGE = 'page',
    }
}

